import styled from 'styled-components';

export const AccordionLeft = styled.div`
  width: 25%;
  min-width: 300px;

  @media (max-width: 1000px) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`;

export const ButtonAccordion = styled.button`
  width: 100%;
  min-width: 320px;
  height: 60px;
  border-radius: 8px;
  border: 0;
  padding: 20px;
  background-color: ${props => (props.active ? '#1f2b2a' : '#cacecd')};
  color: ${props => (props.active ? 'white' : 'initial')};
  margin-bottom: 16px;
  font-size: 16px;
  text-align: left;
  font-weight: 600;
  @media (max-width: 1000px) {
    width: 100%;
  }
`;

export const AccordionRight = styled.div`
  width: 75%;
  max-width: 845px;
  margin-left: 40px;
  @media (max-width: 1000px) {
    width: 100%;
    max-width: 100%;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`;

export const AccordionContainer = styled.div`
  width: 100%;
  display: ${props => (props.active ? 'block' : 'none')};
  @media (max-width: 1000px) {
    width: 100%;
  }
`;
