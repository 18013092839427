import React from 'react';
import creditCardIcon from '~/assets/img/GuaranteedLimitLP/credit-card.svg';
import trendingUpIcon from '~/assets/img/GuaranteedLimitLP/trending-up.svg';
import pixIcon from '~/assets/img/GuaranteedLimitLP/pix.svg';
import midsInsuranceIcon from '~/assets/img/GuaranteedLimitLP/mids-insurance.svg';
import deviceMobileIcon from '~/assets/img/GuaranteedLimitLP/device-mobile.svg';
import barcodeIcon from '~/assets/img/GuaranteedLimitLP/barcode.svg';
import cashIcon from '~/assets/img/GuaranteedLimitLP/cash.svg';

import {
  Container,
  Content,
  Wrapper,
  AccountBenefits,
  Info,
  Title,
  Subtitle,
  Text,
  BenefitAccountCard,
  Icon,
  BenefitsAccountContainer,
  BenefitAccountCardRow,
  LastRow,
} from './styles';

export const DigitalAccount = ({ isMobile }) => (
  <Container>
    <Content>
      <Wrapper>
        <AccountBenefits>
          <Info>
            <Title>Conta digital que facilita </Title>
            <Subtitle>
              Com o Limite Garantido, você ainda aproveita todas as
              funcionalidades da Conta Digital Midway gratuita na tela do seu
              celular!
            </Subtitle>
          </Info>
        </AccountBenefits>

        <BenefitsAccountContainer>
          <BenefitAccountCard>
            <Icon src={creditCardIcon} alt="Icone de cartão" />

            <Text>Acompanhe tudo sobre o seu Cartão RCHLO</Text>
          </BenefitAccountCard>

          {isMobile && (
            <>
              <BenefitAccountCard>
                <Icon src={trendingUpIcon} alt="Icone de rendimento" />

                <Text>Rende mais que a poupança</Text>
              </BenefitAccountCard>
              <BenefitAccountCardRow>
                <BenefitAccountCard>
                  <Icon src={pixIcon} alt="Icone de PIX" />

                  <Text>PIX</Text>
                </BenefitAccountCard>
              </BenefitAccountCardRow>
              <BenefitAccountCard>
                <Icon src={cashIcon} alt="Icone de seguros - escudo" />

                <Text>Empréstimos</Text>
              </BenefitAccountCard>
            </>
          )}
          {!isMobile && (
            <>
              <BenefitAccountCardRow>
                <BenefitAccountCard>
                  <Icon src={trendingUpIcon} alt="Icone de rendimento" />

                  <Text>Rende mais que a poupança</Text>
                </BenefitAccountCard>

                <BenefitAccountCard>
                  <Icon src={pixIcon} alt="Icone de PIX" />

                  <Text>PIX</Text>
                </BenefitAccountCard>
              </BenefitAccountCardRow>
              <BenefitAccountCard>
                <Icon src={cashIcon} alt="Icone de seguros - escudo" />

                <Text>Empréstimos</Text>
              </BenefitAccountCard>
            </>
          )}

          <BenefitAccountCard>
            <Icon src={barcodeIcon} alt="Icone de empréstimo" />

            <Text>Pague boletos e contas</Text>
          </BenefitAccountCard>

          <BenefitAccountCard>
            <Icon src={midsInsuranceIcon} alt="Icone de boleto" />

            <Text>Seguros e assistências</Text>
          </BenefitAccountCard>

          <LastRow>
            <BenefitAccountCard>
              <Icon src={deviceMobileIcon} alt="Icone de recarga móvel" />

              <Text>Recargas</Text>
            </BenefitAccountCard>
          </LastRow>
        </BenefitsAccountContainer>
      </Wrapper>
    </Content>
  </Container>
);
