import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { CardTransparent } from '@midway/web-ui-component';
import Carousel from '@brainhubeu/react-carousel';
import * as S from './styles';

const CardCarousel = ({ cards = [], isMobile = false }) => {
  const [indexActive, setIndexActive] = useState(0);
  const [drag, setDrag] = useState(false);
  const handleWindowSizeChange = () => {
    switch (true) {
      case window.innerWidth >= 993 && window.innerWidth <= 1200:
        setDrag(false);
        break;
      case window.innerWidth < 992:
        setDrag(true);
        break;
      default:
        setDrag(false);
        break;
    }
  };
  useEffect(() => {
    handleWindowSizeChange();
    window.addEventListener('resize', handleWindowSizeChange);
    return () => window.removeEventListener('resize', handleWindowSizeChange);
  }, []);

  return (
    <S.Container>
      <S.Navigation className="d-flex align-items-center justify-content-between">
        <S.NavigationContainer
          onClick={() => {
            setIndexActive(indexActive > 0 ? indexActive - 1 : indexActive);
          }}
          data-gtm-event-category="midway:home"
          data-gtm-event-action="clique:comentarios"
          data-gtm-event-label="botao:anterior"
        >
          <S.PrevIcon />
        </S.NavigationContainer>

        <Carousel
          slidesPerPage={isMobile ? 1 : 3}
          arrows={false}
          draggable={drag}
          value={window.innerWidth <= 992 ? null : indexActive}
        >
          {cards.map((elm, _) => {
            return (
              <CardTransparent
                key={elm.id}
                title={elm.title}
                size={elm.size}
                icon={elm.icon}
                description={elm.text}
              />
            );
          })}
        </Carousel>

        <S.NavigationContainer
          onClick={() => {
            setIndexActive(
              indexActive < cards.length - 3 ? indexActive + 1 : indexActive
            );
          }}
          data-gtm-event-category="midway:home"
          data-gtm-event-action="clique:comentarios"
          data-gtm-event-label="botao:proximo"
        >
          <S.NextIcon />
        </S.NavigationContainer>
      </S.Navigation>
    </S.Container>
  );
};

CardCarousel.propTypes = {
  cards: PropTypes.any,
  isMobile: PropTypes.bool,
};

CardCarousel.defaultProps = {
  cards: [],
  isMobile: false,
};

export default CardCarousel;
