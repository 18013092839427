import { useState } from 'react';
import { Icon } from '@midway/web-ui-component';
import * as S from './styles';

const AccordionServicePortal = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => setIsOpen(!isOpen);

  return (
    <S.AccordionWrapper>
      <S.Groups onClick={toggleAccordion}>
        <S.AccordionTitle>{title}</S.AccordionTitle>
        <Icon
          icon={['fa', 'chevron-down']}
          size="1x"
          rotate180={isOpen}
          color="#000"
        />
      </S.Groups>
      {isOpen && (
        <S.AccordionContent>
          <S.AccordionChildren>{children}</S.AccordionChildren>
        </S.AccordionContent>
      )}
    </S.AccordionWrapper>
  );
};

export default AccordionServicePortal;
