import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${props => (props.bgColor ? props.bgColor : '#FFF')};
`;

export const Divider = styled.div`
  margin-top: -100px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  a {
    &:hover {
      color: #0071eb;
    }
  }
`;
