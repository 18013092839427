/* eslint-disable react/prop-types */
import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@midway/web-ui-component';
import { lowerCase } from 'lodash';
import BallonDiscount from '~/pages/NewNegotiationCampaign/components/BallonDiscount';
import format from '~/services/format';
import { percent } from '~/utils/negotiationHelpers';
import { useNegotiation } from '~/context/negotiationV2';
import useGetCollectionPaymentMethod from '~/hooks/useGetCollectionPaymentMethod';
import { gtagSelectContent } from '~/utils/TaggingGA4';
import { Title, Content } from '../ProposalFactory/styles';

const GTAG_CONTENT_PAGE = 'visualizacao-de-proposta';
const GTAG_FLOW = 'miajuda';

const GenericCardProposal = ({
  title,
  buttonText,
  eligibilityData,
  typeEligibility,
}) => {
  const {
    negotiationData,
    system,
    setHistoryPages,
    setTypeNegotiationRegistered,
    type,
  } = useNegotiation();

  const [getPaymentMethod, getActions, loadingPaymentMethod] =
    useGetCollectionPaymentMethod();
  const { toogles } = useSelector(state => state.featuretoggle);

  const valueWithDiscount =
    eligibilityData.totalValue - eligibilityData?.discount?.maxTotal;

  const contentType =
    typeEligibility === 'debit'
      ? 'selecionar-a-vista'
      : 'selecionar-a-vista-total';

  const submitHandler = async () => {
    gtagSelectContent(GTAG_CONTENT_PAGE, GTAG_FLOW, contentType, {
      desconto: eligibilityData?.discount?.maxTotal || 0,
      valor: valueWithDiscount,
      tipo_proposta: typeEligibility,
      tipo_cartao: type,
      sistema: lowerCase(system),
      proposta: negotiationData?.gaParamProposta,
      contrato: negotiationData?.selectedContract?.contract,
    });
    if (negotiationData.registered)
      return setHistoryPages(prevHistory => [...prevHistory, 'registered']);

    setTypeNegotiationRegistered(typeEligibility);

    const token =
      eligibilityData?.debitToken || eligibilityData?.acquittanceToken;

    if (toogles.isEnabledPaymentMethodMiAjuda) {
      getPaymentMethod({ system: type, token, typeEligibility });
    } else
      getActions({
        typeProposal: typeEligibility,
        token,
        discount: eligibilityData?.discount,
        installment: undefined,
      }).request();
  };

  return (
    <>
      <Content>
        <Title>{title}</Title>
      </Content>
      <div>
        {eligibilityData?.discount?.maxTotal > 0 && (
          <BallonDiscount
            text={`${percent(
              eligibilityData?.discount?.maxTotal,
              eligibilityData?.totalValue
            )}%`}
          />
        )}
        <span className="bold">{format.currency(valueWithDiscount)}</span>
        <span>
          {system === 'TOPAZ' && !toogles.isEnabledPaymentMethodTopaz
            ? 'no débito em conta'
            : system !== 'SICC' && 'no boleto'}
        </span>
      </div>
      <Button
        loading={loadingPaymentMethod}
        title={
          toogles.isEnabledPaymentMethodMiAjuda ? 'Selecionar' : buttonText
        }
        type="submit"
        onClick={() => submitHandler()}
      />
    </>
  );
};

export default GenericCardProposal;
