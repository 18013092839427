import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 1176px;
  display: flex;
  margin-right: 16px;

  @media screen and (max-width: 1000px) {
    margin-bottom: 16px;
    margin-right: 0;
  }
`;

export const Card = styled.div`
  width: 100%;
  max-width: 381px;
  min-width: 328px;
  height: 227px;
  padding: 24px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

  @media (max-width: 1000px) {
    width: 100%;
    max-width: 100%;
  }
`;

export const GroupContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  flex-direction: row;
`;

export const Title = styled.p`
  font-size: 18px;
  font-family: Montserrat;
  font-weight: bold;
`;

export const Line = styled.div`
  max-width: 90%;
  border: 1px solid #221f20;
  margin-bottom: 10px;
  margin-top: 10px;
`;

export const TextWithIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: lef;
  margin-top: 10px;

  & > img {
    width: 20px;
    margin-right: 5px;
  }

  & > p {
    font-size: 16px;
    margin: 0;
  }
`;

export const AdditionalIcon = styled.img`
  width: 100%;
  max-width: 20px;
  margin-right: 5px;
`;

export const Description = styled.a`
  font-size: 16px;
  text-decoration: none;
  color: #221f20;
  font-weight: 500;
  cursor: pointer;
  margin: 0;
`;
