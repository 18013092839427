import { useCallback } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const useRecaptcha = action => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(async () => {
    return executeRecaptcha(action);
  }, [action, executeRecaptcha]);

  return [handleReCaptchaVerify];
};

export default useRecaptcha;
