import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
`;
export const ContainerGlobal = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
export const BackgroundContainer = styled.div`
  width: 100%;
  background-color: #221f20;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const BannerContainer = styled.div`
  width: 100%;
  max-width: 1442px;
`;

export const BannerDesktop = styled.img`
  width: 100%;
  object-fit: cover;
  @media (max-width: 800px) {
    display: none;
  }
`;
export const CentralizeContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 1000px) {
  }
`;
export const AdjustamentTitle = styled.div`
  display: flex;
  align-items: start;
  justify-content: left;
  flex-direction: column;
  padding-bottom: 50px;
  margin-top: 50px;
  width: 90%;
  max-width: 1440px;

  @media (max-width: 1000px) {
    padding-bottom: 27px;
    margin-top: 37px;
  }
`;

export const LendingTitle = styled.h1`
  font-family: Montserrat;
  font-size: 24px !important;
  font-weight: 600;
  line-height: 29px;
  text-align: left;
  padding-bottom: 16px;

  @media (max-width: 1000px) {
    font-size: 20px !important;
    font-weight: 600;
    line-height: 24px;
  }
`;
export const LendingSubTitle = styled.h6`
  font-family: Montserrat;
  font-size: 20px !important;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  padding-bottom: 24px;

  @media (max-width: 1000px) {
    font-size: 16px !important;
    line-height: 19px;
  }
`;

export const LendingTitleDescription = styled.p`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  color: #1f2b2a;
`;

export const CardsGrouping = styled.div`
  display: flex;
  align-items: start;
  justify-content: left;
  flex-direction: column;
  padding-bottom: 50px;
  margin-top: 50px;
  width: 90%;
  max-width: 1440px;

  @media (max-width: 1000px) {
    padding-bottom: 27px;
    margin-top: 37px;
  }
`;

export const GroupingTop = styled.div`
  width: 100%;
`;

export const ContentTop = styled.div`
  padding-bottom: 32px;
`;

export const CardsTitle = styled.h1`
  font-family: Montserrat;
  font-size: 24px !important;
  font-weight: 500;
  line-height: 29px;
  text-align: left;
  padding-bottom: 16px;

  @media (max-width: 1000px) {
    font-size: 20px !important;
    line-height: 24px;
  }
`;

export const CardsDescription = styled.p`
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  @media (max-width: 1000px) {
    font-size: 16px;
    line-height: 19px;
  }
`;

export const BottomContent = styled.div`
  width: 100%;
  display: flex;
  align-items: start;
  justify-content: left;
  flex-direction: row;
  @media (max-width: 1200px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const CardTopLeft = styled.div`
  width: 100%;
  height: 100%;
  max-width: 580px;
  max-height: 220px;
  padding: 32px;
  border-radius: 24px;
  background-color: #f7f7f7;
  display: flex;
  align-items: start;
  justify-content: left;
  flex-direction: column;
  margin-right: 16px;
  @media (max-width: 1200px) {
    justify-content: center;
    align-items: center;
    margin-right: 0px;
    margin-bottom: 16px;
    max-height: 296px;
    max-width: 100%;
  }
`;
export const TitleCardTop = styled.p`
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  padding-bottom: 16px;
`;
export const TitleSubCardTop = styled.p`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  text-align: left;
  padding-bottom: 32px;
`;
export const CardTopRight = styled.div`
  width: 100%;
  max-width: 580px;
  max-height: 220px;
  padding: 32px;
  border-radius: 24px;
  background-color: #f7f7f7;
  display: flex;
  align-items: start;
  justify-content: left;
  flex-direction: column;
  @media (max-width: 1200px) {
    justify-content: center;
    align-items: center;
    margin-right: 0px;
    max-height: 400px;
    max-width: 100%;
  }
`;
export const Agroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-bottom: 18px;
`;
export const ImageCardLeft = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 10px;
`;
export const TextImageCardLeft = styled.p`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
`;

export const RectangleCard = styled.div`
  width: 161px;
  padding: 8px;
  border-radius: 32px;
  background-color: ${props => props.backgroundColor || '#fff'};
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media (max-width: 1000px) {
    margin-bottom: 16px;
    width: 264px;
  }
`;
export const AgroupCardRight = styled.div`
  display: flex;
  align-items: start;
  justify-content: left;
  flex-direction: row;
  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;
export const TextPercentageCard = styled.p`
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: ${props => props.color || '#221f20'};
  padding-bottom: 4px;
`;
export const DescriptionPercentageCard = styled.p`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  text-align: left;
  color: ${props => props.color || '#221f20'};
`;
export const GroupingBottom = styled.div`
  margin-top: 77px;
  margin-bottom: 60px;
  width: 100%;
`;
export const BackgroundInformationPanel = styled.div`
  width: 100%;
  background-color: #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const InformationPanel = styled.div`
  max-width: 2000px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1440px;
  @media (max-width: 1000px) {
    flex-direction: column;
    margin-bottom: 0px;
  }
`;

export const LeftPanel = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;

  @media (max-width: 1000px) {
    width: 100%;
  }
`;

export const LeftImagePanelDesktop = styled.img`
  width: 100%;
  max-width: 549px;
  object-fit: cover;

  @media (max-width: 1000px) {
    display: none;
  }
`;

export const LeftImagePanelMobile = styled.img`
  display: none;

  @media (max-width: 1000px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const RightPanel = styled.div`
  width: 60%;
  max-width: 779px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 59px;

  @media (max-width: 1000px) {
    width: 90%;
    margin-left: 0px;
  }
`;

export const CentralizeRightPanel = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 90%;
  margin-top: 48px;

  @media (max-width: 1000px) {
    align-items: flex-start;
    justify-content: center;
  }
`;

export const TextPanel = styled.p`
  font-family: Montserrat;
  padding-bottom: 32px;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;

  @media (max-width: 1000px) {
    font-size: 18px;
    line-height: 22px;
  }
`;
export const RectangleCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Rectangle = styled.div`
  border: 1px solid #221f20;
  border-radius: 16px;
  width: 100%;
  max-width: 779px;
  padding: 12px 24px 12px 24px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 64px;
  margin-bottom: 80px;
  @media (max-width: 1000px) {
    max-width: 360px;
    margin-top: 22px;
    margin-bottom: 64px;
  }
`;

export const TextMidway = styled.p`
  font-family: Montserrat;
  width: 280px;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  text-align: center;
`;

export const GroupLeft = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: left;
  flex-direction: column;
  width: 100%;
  max-width: 779px;
  @media (max-width: 1000px) {
    max-width: 100%;
  }
`;

export const TextBold = styled.span`
  font-size: ${props => props.fontSize || '16px'};
  font-weight: bold;
  display: contents;
`;
