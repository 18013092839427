export const Banner = [
  {
    name: 'BannerCartoes',
    type: 'image',
    imgMobile:
      'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/portaAtendimentoMobile.png',
    img: 'https://ciano-midias-prod.riachuelo.com.br/midway/arquivos/portalAtendimento.png',
    title:
      'Encontre informações sobre os nossos produtos, serviços e as dúvidas mais frequentes.',
    subtitle: '',
    icon: '',
    enabled: true,
    redirect: true,
    redirectUrl: 'https://uat.midway.com.br/atendimento',
  },
];
