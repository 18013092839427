import React from 'react';
import { Breadcrumb } from '@midway/web-ui-component';
import history from '~/services/history';
import BaseFinancialProducts from '~/components/_layout/BaseFinancialProducts';
import * as S from './styles';

export default function AssistanceResidence24h() {
  return (
    <S.Container
      id="ready-page-for-screen-reader"
      aria-label="Página de Assistência Residência carregada"
    >
      <Breadcrumb
        routeBack="/produtos-financeiros"
        textRouteBack="Produtos Financeiros"
        actualPageText="Assistência 24h Residência"
        history={history}
      />
      <BaseFinancialProducts
        pageName="Assistência 24h Residência"
        session1={
          <>
            <h1>Assistência 24h Residência </h1>
            <b>
              Com a correria do dia a dia, não paramos pra pensar que em algum
              momento precisaremos de ajuda, contratando a Assistência 24h
              Residência, você mantém sua conquista protegida e conta com:
            </b>
            <br />
            <b>
              chaveiro, eletricista, encanador, troca de resistência do
              chuveiro, assistência linha branca (geladeira, máquina de lavar,
              secadora de roupa), assistência Linha Marrom (TV, aparelho de
              DVD), checkUp Lar e outros 16 serviços a sua disposição.
            </b>
          </>
        }
        featured1={`Proteção e amparo por\napenas R$ 16,90 mensais`}
        session21={
          <>
            <div className="divider">
              <p>Para utilizar os serviços:</p>
              <span>
                Fale com a CDF
                <br />
                0800 704 5025
              </span>
            </div>
          </>
        }
        session4={
          <>
            <p>
              A Assistência é prestada pela CDF Assistências Ltda inscrita no
              CNPJ: 52.570.231/0001-34.
            </p>
            <p>
              Restrições se aplicam. Mais informações consulte as condições
              gerais, o regulamento e as características essenciais no site:{' '}
              <a
                href="https://www.midway.com.br"
                rel="noopener noreferrer"
                target="_blank"
              >
                https://www.midway.com.br
              </a>
              . Vigência inicia-se a partir do dia seguinte a realização do
              pagamento.
            </p>
          </>
        }
        colorCircle="#f17eb1"
        links={[
          {
            link: '/contrato/CG_ASSISTÊNCIA-24HS-RESIDÊNCIA-SUPER',
            text: 'Condições Gerais da Assistência 24 HS Residência  Super (Comercializado a partir de 04 de novembro de 2019)',
          },
          {
            link: '/contrato/CG_ASSISTÊNCIA-24HS-RESIDÊNCIA-MEGA',
            text: 'Condições Gerais da Assistência 24 HS Residência  Mega (Comercializado a partir de 04 de novembro de 2019)',
          },
        ]}
      />
    </S.Container>
  );
}
