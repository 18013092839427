const items = [
  {
    title: `Seguro Bolsa, Pix e \nCartão Protegido`,
    description: 'Proteja suas transações Pix por menos de R$0,50 por dia.',
    key: '1',
    link: '/seguros',
  },
  {
    title: `Seguro \nConta Paga`,
    description: 'Tranquilidade até em caso de desemprego ou incapacidade.',
    key: '2',
    link: '/seguros',
  },
  {
    title: `Seguro \nAcidentes Pessoais`,
    description: 'Estamos com você até nos imprevistos.',
    link: '/seguros',
    key: '3',
  },
  {
    title: `Mais \nSaúde`,
    description:
      'Acesso a especialistas com consultas on-lines ou presenciais.',
    link: '/saude',
    key: '4',
  },
  {
    title: `Assistência \nOdontológica`,
    description: 'Muito mais saúde para o seu sorriso.',
    link: '/plano-odontologico',
    key: '5',
  },
];

export default items;
