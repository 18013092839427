import React from 'react';
import { Breadcrumb } from '@midway/web-ui-component';
import history from '~/services/history';
import BaseFinancialProducts from '~/components/_layout/BaseFinancialProducts';
import * as S from './styles';

export default function AssistanceMoto24h() {
  return (
    <S.Container
      id="ready-page-for-screen-reader"
      aria-label="Página de Assistência Moto carregada"
    >
      <Breadcrumb
        routeBack="/produtos-financeiros"
        textRouteBack="Produtos Financeiros"
        actualPageText="Assistência 24h Moto"
        history={history}
      />
      <BaseFinancialProducts
        pageName="Assistência 24h Moto"
        session1={
          <>
            <h1>Assistência 24h Moto </h1>
            <b>
              Contratando a Assistência 24h Moto você fica livre de preocupação
              em casos de:
            </b>
            <br />
            <b>
              socorro elétrico e/ou mecânico, guincho, chaveiro, despachante,
              retorno ao domicílio, remoção inter-hospitalar e outros 9
              serviços.
            </b>
          </>
        }
        featured1={`Proteção e amparo por\napenas R$ 18,90 mensais`}
        featured2={`Moto é ágil, eficiente e\neconômica. Nossa assistência\ntambém.`}
        session21={
          <>
            <div className="divider">
              <p>Assistência 24 horas por dia/ 7 dias por semana:</p>
              <span>
                Não importa quando e onde, se a sua moto falhar, pode contar com
                a gente pra te amparar.
              </span>
              <p>Para utilizar os serviços:</p>
              <span>
                Fale com a CDF
                <br />
                0800 704 5025
              </span>
            </div>
          </>
        }
        session4={
          <>
            <p>
              A Assistência é prestada pela CDF Assistências Ltda inscrita no
              CNPJ: 52.570.231/0001-34.
            </p>
            <p>
              Restrições se aplicam. Mais informações consulte as condições
              gerais, o regulamento e as características essenciais no site:{' '}
              <a
                href="https://www.midway.com.br"
                rel="noopener noreferrer"
                target="_blank"
              >
                https://www.midway.com.br
              </a>
              . Vigência inicia-se a partir do dia seguinte a realização do
              pagamento.
            </p>
          </>
        }
        colorCircle="#f17eb1"
        links={[
          {
            link: '/contrato/CG-ASSISTÊNCIA-24HS-MOTO-SUPER',
            text: 'Condições Gerais da Assistência 24 HS Moto  Super (Comercializado a partir de 01 de julho de 2019)',
          },
          {
            link: '/contrato/CG-ASSISTÊNCIA-24HS-MOTO-MEGA',
            text: 'Condições Gerais da Assistência 24 HS Moto  Mega (Comercializado a partir de 01 de julho de 2019)',
          },
        ]}
      />
    </S.Container>
  );
}
