import styled from 'styled-components';

export const AccordionWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  cursor: pointer;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  transition: background-color 0.6s ease;
  border: 1px solid #dae5e3;
  border-radius: 10px;
  margin-bottom: 40px;
  box-shadow: 0px 8px 12px -4px #1f2b2a29;
  &:active,
  &:focus {
    border-color: #667;
  }
`;
export const AccordionTitle = styled.p`
  font-size: 18px !important;
  text-align: left;
  font-family: Montserrat;
  font-weight: 700;
  line-height: 22px;
`;
export const Groups = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
`;

export const AccordionContent = styled.div`
  padding-top: 18px;
  border-top: none !important;
  background: var(--grey-100, #ffffff);
  overflow: hidden;
  transition: 0.6s ease;
`;

export const AccordionChildren = styled.p`
  font-size: 18px !important;
  font-weight: 400;
  line-height: 150%;
`;
