import React, { useEffect, useRef, useState } from 'react';

import DownloadButtonApp from '~/components/DownloadButtonApp';
import Modal from '~/components/Modal';
import { trackingSelectContent } from '~/analytics';
import useCMS from '~/hooks/useCMS';

import BannerDesktop from '~/assets/img/card-credit/banner-desktop.png';
import BannerMobile from '~/assets/img/card-credit/banner-mobile.png';
import CardImage from '~/assets/img/card-credit/cardcredit.png';
import Iphone from '~/assets/img/card-credit/iphone.png';
import QrCodeImage from '~/assets/img/card-credit/qrcode.svg';

import CardIconModal from '~/assets/img/card-credit/card.svg';
import GiftIconModal from '~/assets/img/card-credit/gift.svg';
import CoinIconModal from '~/assets/img/card-credit/coin.svg';
import ConfettiIconModal from '~/assets/img/card-credit/confetti.svg';
import FileCheckIconModal from '~/assets/img/card-credit/file-check.svg';
import PercentageIconModal from '~/assets/img/card-credit/percentage.svg';
import ShoppingIconModal from '~/assets/img/card-credit/shopping-cart.svg';
import BuildingIconModal from '~/assets/img/card-credit/building-store.svg';

import PlayStore from '~/assets/img/card-credit/play-store.svg';
import AppleStore from '~/assets/img/card-credit/apple-store.svg';
import * as S from './styles';

import Table from './components/Table';
import { tableData } from './constants';

const Anchor = ({ href, children }) => {
  return (
    <>
      {children && ' '}
      <S.StyleAnchor href={href} target="_blank">
        {' '}
        {children}{' '}
      </S.StyleAnchor>
    </>
  );
};

const BlockModalOptions = ({ icon, description, observation }) => {
  return (
    <S.ContainerBlockModal>
      <S.CardModal>
        <S.IconModal src={icon} />
        <S.CentralizeTextModal>
          <S.DescriptionModal>{description}</S.DescriptionModal>
          {observation && (
            <S.ObservationModal>{observation}</S.ObservationModal>
          )}
        </S.CentralizeTextModal>
      </S.CardModal>
    </S.ContainerBlockModal>
  );
};

const CardCredit = () => {
  const [getCardsInfo, { data, loading, error }] = useCMS();

  const ScrollDown = useRef(null);
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
    trackingSelectContent('peca-agora', 'cartao-de-credito');
  };

  const closeModal = () => {
    setModalOpen(false);
    trackingSelectContent('fechar:peca-agora', 'cartao-de-credito');
  };

  const handleClick = () => {
    ScrollDown.current.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    getCardsInfo('cartao-master');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isModalOpen && (
        <Modal onClose={closeModal}>
          <S.ModalStyle>
            <S.RightModal>
              <S.AdjustamentRightModal>
                <S.TitleModal>Peça já o seu cartão Riachuelo </S.TitleModal>
                <S.TextModal>E aproveite todas as vantagens</S.TextModal>
                <S.CentralizeCardsModal>
                  <S.ModalOptions>
                    <BlockModalOptions
                      icon={GiftIconModal}
                      description="20% OFF* na sua próxima compra nas lojas Riachuelo"
                      observation="*Desconto limitado a 20% do valor total da nova compra"
                    />
                    <BlockModalOptions
                      icon={CoinIconModal}
                      description="Condições especiais de parcelamento no site e nas lojas físicas"
                    />
                    <BlockModalOptions
                      icon={PercentageIconModal}
                      description="Até 35% de desconto em farmácias parceiras"
                    />
                    <BlockModalOptions
                      icon={FileCheckIconModal}
                      description="Acesse e pague sua fatura nos apps Midway ou Riachuelo"
                    />
                    <BlockModalOptions
                      icon={CardIconModal}
                      description="Programa Mastercard Surpreenda*"
                      observation="*Apenas para clientes do cartão Mastercard."
                    />
                    <BlockModalOptions
                      icon={ConfettiIconModal}
                      description="Parcerias exclusivas"
                    />
                    <BlockModalOptions
                      icon={BuildingIconModal}
                      description="Entrega instantânea do cartão RCHLO na loja"
                    />
                    <BlockModalOptions
                      icon={ShoppingIconModal}
                      description="Compra on-line com retirada em loja"
                    />
                  </S.ModalOptions>
                </S.CentralizeCardsModal>
                <S.TextfinalModal>
                  Baixe o aplicativo da Riachuelo, peça o seu cartão e já comece
                  a usar!
                </S.TextfinalModal>
              </S.AdjustamentRightModal>
              <S.QRCodeImage src={QrCodeImage} />
              <S.ModalButton
                target="_blank"
                href="https://www.midway.com.br/app/"
              >
                Baixar App Riachuelo
              </S.ModalButton>
            </S.RightModal>
          </S.ModalStyle>
        </Modal>
      )}
      <S.Container>
        <S.ContainerGlobal>
          <S.BannerContainer>
            <S.BannerDesktop src={BannerDesktop} onClick={handleClick} />
            <S.BannerMobile src={BannerMobile} onClick={handleClick} />
          </S.BannerContainer>
          {!loading && !error && (
            <S.BlockCard>
              <S.CentrilizeBlock>
                <S.LeftSection>
                  <S.LeftImage src={CardImage} ref={ScrollDown} />
                </S.LeftSection>
                <S.RightSection>
                  <S.AdjustamentRight>
                    <S.RightTitle>Cartão Mastercard</S.RightTitle>
                    <S.AdjustamentRightText>
                      <S.RightText>
                        Limite disponível:{' '}
                        <S.TextBold fontSize="18px">{data.limit}</S.TextBold>
                      </S.RightText>
                      <S.RightText>
                        Anuidade:{' '}
                        <S.TextBold fontSize="18px">{data.annuity}</S.TextBold>
                      </S.RightText>
                      <S.RightText>
                        Utilização:{' '}
                        <S.TextBold fontSize="18px">
                          Limite para utilização dentro e fora da Riachuelo
                        </S.TextBold>
                      </S.RightText>
                    </S.AdjustamentRightText>
                    <S.RightButton onClick={openModal}>
                      Peça agora
                    </S.RightButton>
                    <S.RightText>
                      <div
                        onClick={() =>
                          trackingSelectContent(
                            'veja-contrato-do-cartao-rchlo',
                            'cartao-de-credito'
                          )
                        }
                      >
                        <Anchor href="https://www.midway.com.br/static/documents/tags/contratos/contrato-cartao-bandeira-30-10.pdf">
                          Veja o contrato do Cartão Riachuelo Mastercard
                        </Anchor>
                      </div>
                    </S.RightText>
                    <S.RightText>
                      <div
                        onClick={() =>
                          trackingSelectContent(
                            'consulte-taxas-e-tarifas',
                            'cartao-de-credito'
                          )
                        }
                      >
                        <Anchor href="https://www.midway.com.br/tarifas-visa-card">
                          Consulte taxas e tarifas
                        </Anchor>
                      </div>
                    </S.RightText>
                  </S.AdjustamentRight>
                </S.RightSection>
              </S.CentrilizeBlock>
            </S.BlockCard>
          )}
          <S.ContainerTable>
            <S.TitleTable>
              Cartão Mastercard com mais vantagens pra você
            </S.TitleTable>
            <Table data={tableData} />
          </S.ContainerTable>
          <S.CentralizePanel>
            <S.InformationPanel>
              <S.LeftPanel>
                <S.LeftImagePanel src={Iphone} />
              </S.LeftPanel>
              <S.RightPanel>
                <S.CentralizeRightPanel>
                  <S.RchloLogo>RCHLO</S.RchloLogo>
                  <S.TextPanel fontSize="23px">
                    Baixe o App Riachuelo
                  </S.TextPanel>
                  <S.TextPanel fontSize="18px">
                    Que tal fazer um cartão de maneira simples e prática?
                  </S.TextPanel>
                  <S.AdjustamentButton>
                    <DownloadButtonApp
                      iconSrc={PlayStore}
                      onClick={() =>
                        trackingSelectContent(
                          'download:google-play',
                          'cartao-de-credito'
                        )(
                          (window.location.href =
                            'https://play.google.com/store/apps/details?id=br.com.riachuelo.app')
                        )
                      }
                    >
                      Download via{' '}
                      <S.TextBold fontSize="18px">Google Play</S.TextBold>
                    </DownloadButtonApp>
                    <DownloadButtonApp
                      iconSrc={AppleStore}
                      onClick={() =>
                        trackingSelectContent(
                          'download:apple-store',
                          'cartao-de-credito'
                        )(
                          (window.location.href =
                            'https://apps.apple.com/br/app/riachuelo-comprar-roupas/id1488532824')
                        )
                      }
                    >
                      Download via{' '}
                      <S.TextBold fontSize="18px">Apple Store</S.TextBold>
                    </DownloadButtonApp>
                  </S.AdjustamentButton>
                </S.CentralizeRightPanel>
              </S.RightPanel>
            </S.InformationPanel>
          </S.CentralizePanel>
        </S.ContainerGlobal>
      </S.Container>
    </>
  );
};

export default CardCredit;
