import React, { useEffect, useState } from 'react';
import { Image, Button } from '@midway/web-ui-component';
import { Table as TableBoostrap } from 'react-bootstrap';
import { cardsHealth, tableHealthEssentialPlan } from '~/constants';
import Banner from '~/assets/img/Health/banner.jpg';
import BannerMobile from '~/assets/img/Health/banner_mobile.jpg';
import { Container as ContainerTableStyle } from '~/components/Table/styles';
import history from '~/services/history';
import BannerSection from '~/components/BannerSection';
import InovarMidway from '~/assets/img/conheca-midway/inovar-midway-new.png';
import AccessibleLink from '~/components/AccessibleLink';
import Carousel from '~/components/CardCarousel';
import * as S from './styles';

export default function Assistance() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

  const handleWindowSizeChange = () => setIsMobile(window.innerWidth <= 767);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return window.removeEventListener('resize', handleWindowSizeChange);
  }, []);

  return (
    <S.Container id="ready-page-for-screen-reader">
      <S.BannerContainer className="container-fluid">
        <Image
          src={!isMobile ? Banner : BannerMobile}
          alt="Imagem com uma mulher sorrindo olhando diretamente para a câmera."
          aria-hidden="true"
        />
        <div className="banner-title">
          <h1 className="is-primary-default bold">
            {`Cuidamos da\nsua saúde`}
          </h1>
          <p className="is-primary-default">e do seu bolso</p>
          <Button
            aria-label="Clique para acessar área do cliente"
            title="Área do cliente"
            onClick={() => {
              window.open('https://maissaudepremiavel.midway.com.br', '_blank');
            }}
          />
        </div>
      </S.BannerContainer>

      <S.Content className="container">
        <S.Session1>
          <h2>O que é o mais saúde?</h2>
          <p>
            Com o Mais Saúde, você e sua família terão acesso a uma rede com
            mais de 80 especialidades médicas em consultas presenciais, exames
            laboratoriais e de imagem por um valor justo e com máxima qualidade
            de Saúde.
          </p>
          <p className="bold">Além disso o cliente conta com:</p>
          <p>
            - O desconto de 20% em medicamentos na rede de farmácias
            credenciadas;
          </p>
          <p>
            - Pacote Cirúrgico no hospital Oswaldo Cruz - Unidade Vergueiro/São
            Paulo;
          </p>
          <p>- Nutricionista online;</p>
          <p>
            - Consultas online, por vídeo. São mais de 30 especialidades médicas
            para o cliente cuidar da saúde sem sair de casa. .
          </p>
          <p className="bold">
            Além de consultas presenciais, você e seus dependentes terão acesso
            a consultas por vídeo. São mais de 30 especialidades médicas, para
            que você não precise sair de casa pra cuidar da saúde
          </p>
        </S.Session1>
      </S.Content>

      <S.Session4 className="space-layout">
        <h2 className="is-primary-default">
          Veja todas as vantagens e benefícios que oferecemos pra vocês:
        </h2>
        <div className="container carousel-session">
          <div className="row">
            <Carousel cards={cardsHealth} isMobile={isMobile} />
          </div>
        </div>
      </S.Session4>

      <S.Session2 className="container">
        <S.Content className="row">
          <S.Col className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <h2>Detalhamento dos Serviços</h2>
          </S.Col>
          <S.Col className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <h3>Consultas e exames</h3>
            <p>
              Você terá a opção de fazer o agendamento através da Central de
              Atendimento da Vale Saúde, onde obterá apoio e orientações sobre a
              rede credenciada. Ou poderá realizar o agendamento através da área
              do Cliente, Aplicativo ou WhatsApp.
            </p>
            <p className="bold">
              Após o agendamento o cliente pagará o valor correspondente:
            </p>
            <p>
              <b>Consultas:</b> de R$ 60,00 a R$ 90,00 (no máximo);
            </p>
            <p>
              <b>Exames:</b> a partir de R$ 5,00. O custo poderá variar conforme
              o tipo de exame, localidade e laboratório escolhido pelo cliente.
            </p>
          </S.Col>
          <S.Col className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <h3>
              Pacote Cirúrgico Hospital Oswaldo Cruz – Unidade Vergueiro/ São
              Paulo
            </h3>
            <p>
              São diversos procedimentos cirúrgicos, o cliente pagará por
              valores com baixo custo. Após consulta médica no Hospital Oswaldo
              Cruz , e confirmado a necessidade de uma intervenção cirúrgica, o
              cliente poderá fazê-lo exclusivamente na Unidade Vergueiro) do
              Hospital Oswaldo Cruz.
            </p>
            <p>
              <b>
                Exemplos de procedimentos que poderão ser obtidos com desconto
                através da Assistência Mais Saúde:
              </b>{' '}
              Varizes tratamento cirúrgico, Vasectomia, Reversão da Vasectomia,
              Biópsia de Boca, Traqueostomia Cervical, Dilatação do Colo do
              Útero, Colocação de DIU, entre outros.
            </p>
          </S.Col>
          <S.Col className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <h3>Nutricionista Online</h3>
            <p>
              <b>
                Avaliação nutricional, o cliente pagará o valor com baixo custo
                de acordo com o pacote escolhido, são 03 tipos de Pacotes e são
                oferecidos serviços tais como:
              </b>
            </p>
            <p>
              {`- Avaliação da saúde geral;\n- Dicas de alimentação;\n- Sugestão de reeducação Nutricional;\n- Vídeo consulta.`}
            </p>
          </S.Col>
          <S.Col className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <h3>Desconto em medicamentos</h3>
            <p>
              Desconto de 20% em medicamentos para o titular e seus dependentes
              nas compras de medicamentos nas farmácias da rede credenciada (o
              desconto não se aplica a outros produtos vendidos nas farmácias).
              São milhares de farmácias credenciadas em todo o país.
            </p>
          </S.Col>
          <S.Col className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <h3>Consultas Online</h3>
            <p>
              SO cuidado com a Saúde sem sair de casa. Consultas Online, por
              vídeo, mais de 30 especialidades médicas. A consulta deverá ser
              agendada com os Canais de atendimento da Vale Saúde.
            </p>
          </S.Col>
          <S.Col className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <h3>Telemedicina</h3>
            <p>
              É um serviço de orientação médica online. Serviço de pronto
              atendimento médico a distância A orientação médica a distância
              ocorre por meio do celular e/ou computador Atendimento 24 horas
              por dia, 7 dias por semana, sem limite de utilização.
            </p>
            <p>
              <b>
                Serviço opcional e quando contratado (plano familiar) o cliente
                pagará um outro valor de mensalidade conforme demostraremos a
                seguir.
              </b>
            </p>
          </S.Col>
          <S.Col className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <S.MiniBanner>
              <p>
                Consulte a rede médica presencial e os laboratórios disponíveis{' '}
                <AccessibleLink
                  route="https://maissaude.valesaudesempre.com.br/rede/"
                  target="_blank"
                  text="Saiba mais."
                  data-gtm-event-category="midway:mais-saude-premiavel"
                  data-gtm-event-action="clique:link"
                  data-gtm-event-label="link:clique-aqui:consulte-a-rede-medica-presencial-e-os-laboratorios-disponiveis"
                />
              </p>
            </S.MiniBanner>
          </S.Col>
          <S.Col className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <S.MiniBanner>
              <p>
                Consulte farmácias parceiras{' '}
                <AccessibleLink
                  route="https://maissaude.valesaudesempre.com.br/farmacias/"
                  target="_blank"
                  text="Saiba mais."
                  data-gtm-event-category="midway:mais-saude-premiavel"
                  data-gtm-event-action="clique:link"
                  data-gtm-event-label="link:clique-aqui:consultar-as-farmacias-parceiras"
                />
              </p>
            </S.MiniBanner>
          </S.Col>

          <S.Col className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <h2>Escolha a opção ideal para você e sua família</h2>
            <p>
              Para acesso aos benefícios e serviços do Produto Mais Saúde o
              cliente deverá pagar a mensalidade de acordo com o plano
              contratado.
            </p>
          </S.Col>
          <S.Col className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <h3>Plano essêncial</h3>
            <S.ContainerTable>
              <ContainerTableStyle>
                <TableBoostrap bordered striped responsive>
                  <thead>
                    <tr aria-label="Tabela de Planos essênciais, seus serviços e carência">
                      <th className="text-nowrap">Serviços</th>
                      <th className="text-nowrap">Carência</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableHealthEssentialPlan.map(item => (
                      <tr
                        aria-label={`Serviço de ${item.service}, Carência, ${item.lack}.`}
                        key={item.id}
                      >
                        <td>{item.service}</td>
                        <td>{item.lack}</td>
                      </tr>
                    ))}
                  </tbody>
                </TableBoostrap>
              </ContainerTableStyle>
            </S.ContainerTable>
            <S.Price>
              <p>Por apenas</p>
              <p>
                <b>R$ 27,90/mês</b>
              </p>
            </S.Price>
          </S.Col>
          <S.Col className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <h3>Plano essêncial + Telemedicina Familiar</h3>
            <S.ContainerTable>
              <ContainerTableStyle>
                <TableBoostrap bordered striped responsive>
                  <thead>
                    <tr aria-label="Tabela de Planos essênciais + Telemedicina Familiar, seus serviços e carência">
                      <th className="text-nowrap">Serviços</th>
                      <th className="text-nowrap">Carência</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableHealthEssentialPlan.map(item => (
                      <tr
                        aria-label={`Serviço de ${item.service}, Carência, ${item.lack}.`}
                        key={item.id}
                      >
                        <td>{item.service}</td>
                        <td>{item.lack}</td>
                      </tr>
                    ))}
                  </tbody>
                </TableBoostrap>
              </ContainerTableStyle>
            </S.ContainerTable>
            <S.Price>
              <p>Por apenas</p>
              <p>
                <b>R$ 57,90/mês</b>
              </p>
            </S.Price>
          </S.Col>
          <S.Col className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <S.MonthlyFee>
              <b>Atenção:</b> Este é o valor da mensalidade para utilização dos
              benefícios/serviços, não é valor da consulta e exame.
            </S.MonthlyFee>
          </S.Col>
        </S.Content>
      </S.Session2>

      <S.Session3 className="space-layout">
        <S.Content className="p-5">
          <S.Content className="row">
            <S.Col className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <S.ImageNegative>
                <Image
                  src={InovarMidway}
                  alt="Imagem com um mulher olhando para o céu e refletindo."
                  aria-hidden="true"
                />
              </S.ImageNegative>
            </S.Col>
            <S.Col className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <S.Group>
                <div className="content-text-group">
                  <h2 className="is-primary-default">Como funciona?</h2>
                  <p className="is-primary-default">
                    Ao aderir o produto Mais Saúde você{' '}
                    <b>
                      recebe uma carteirinha virtual que possui a funcionalidade
                      de um Cartão Pré-pago
                    </b>
                    , depois é fácil!
                  </p>
                  <p className="is-primary-default">
                    - Escolha o médico ou laboratório de sua preferência;
                  </p>
                  <p className="is-primary-default">
                    - Para utilizar o cartão, você precisa recarregá-lo com o
                    valor da tarifa de recarga e o valor da consulta ou do exame
                    que deseja fazer;
                  </p>
                  <p className="is-primary-default">
                    - O valor do atendimento será debitado do Cartão Mais Saúde
                    ao digitar sua senha.
                  </p>
                  <p className="is-primary-default">
                    <b>PARA UTILIZAR OS SERVIÇOS</b>
                    <p>
                      <b>Ligue para CENTRAL DE ATENDIMENTO VALE SAÚDE</b>
                    </p>
                    3003 0258 (Fone e WhatsApp)
                    <p>
                      <b>
                        Horário de atendimento: Seg a sexta das 8h as 18h e
                        Sábado das 07h as 12h
                      </b>
                    </p>
                  </p>
                </div>
              </S.Group>
            </S.Col>
          </S.Content>
        </S.Content>
      </S.Session3>

      <BannerSection>
        <div>
          <S.BannerTitle>
            <p>Termos e condições</p>
          </S.BannerTitle>
          <S.BannerDescription>
            <p>
              O produto Mais Saúde não é um plano de saúde. É um cartão pré-pago
              que pode ser utilizado como meio de pagamento de consultas médicas
              e exames laboratoriais em locais selecionados.
            </p>
            <p>
              Acesse as condições gerais do produto.{' '}
              <AccessibleLink
                route="/contrato/Condicoes-Gerais-VSS"
                target="_blank"
                text="Saiba mais."
                data-gtm-event-category="midway:mais-saude-premiavel"
                data-gtm-event-action="clique:link"
                data-gtm-event-label="link:clique-aqui:acessar-as-condicoes-gerais-do-produto"
              />
            </p>
          </S.BannerDescription>
        </div>
      </BannerSection>

      <S.Session5>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <h2>CONTRATE JÁ:</h2>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <S.Col2>
                <Button
                  aria-label="Acesso ao site das Lojas Riachuelo"
                  title="Lojas RCHLO"
                  onClick={() =>
                    window.open(
                      'https://www.midway.com.br/buscar-lojas/',
                      '_blank'
                    )
                  }
                  data-gtm-event-category="midway:mais-saude-premiavel"
                  data-gtm-event-action="clique:botao"
                  data-gtm-event-label="lojas-rchlo"
                />
                <Button
                  aria-label="Acesso a central de relacionamento"
                  title="Central de relacionamento"
                  onClick={() => history.push('/atendimento')}
                  data-gtm-event-category="midway:mais-saude-premiavel"
                  data-gtm-event-action="clique:botao"
                  data-gtm-event-label="central-de-relacionamento"
                />
              </S.Col2>
            </div>
          </div>
        </div>
      </S.Session5>

      <S.Session6>
        <div className="container">
          Os serviços de consultas médicas e exames clínicos são prestados pela
          Vale Saúde, Soluções de Pagamento inscrita no CNPJ 14.336.330/0001-67.
        </div>
      </S.Session6>
    </S.Container>
  );
}
