import React from 'react';
import Desktop from '~/components/Responsive/Desktop';
import Mobile from '~/components/Responsive/Mobile';
import useHasMobileDownloadHeader from '~/hooks/useHasMobileDownloadHeader';
import HeaderImageDesktop from '~/assets/img/digital-account/banner-conta-digital.png';
import HeaderImageMobile from '~/assets/img/digital-account/banner-conta-digital-mobile.png';
import { HeaderImage, HeaderWrapper } from './styles';

const Header = () => {
  const hasText = useHasMobileDownloadHeader();

  return (
    <HeaderWrapper hasMobile={hasText}>
      <HeaderImage>
        <Desktop>
          <img
            src={HeaderImageDesktop}
            alt="Conta Digital Midway (Organize suas finanças com mais praticidade)"
            title="Conta digital Midway"
          />
        </Desktop>
        <Mobile>
          <img
            src={HeaderImageMobile}
            alt="Conta Digital Midway (Organize suas finanças com mais praticidade)"
            title="Conta digital Midway"
          />
        </Mobile>
      </HeaderImage>
    </HeaderWrapper>
  );
};

export default Header;
