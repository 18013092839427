import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: white;
`;

export const ContainerGlobal = styled.div`
  max-width: 2000px;
  width: 100%;
  margin: 0 auto;
`;

export const BannerContainer = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-bottom: 20px;
`;

export const BannerImageDesktop = styled.img`
  width: 100%;
  object-fit: cover;

  @media (max-width: 600px) {
    display: none;
  }
`;

export const BannerImageMobile = styled.img`
  width: 100%;
  object-fit: cover;

  @media (min-width: 600px) {
    display: none;
  }
`;

export const CentralizedContent = styled.div`
  max-width: 1900px;
  padding: 0 20px;
  margin-left: 100px;
  margin-right: 100px;

  @media only screen and (max-width: 1000px) {
    margin-left: 0;
    margin-right: 0;
  }
`;

export const CentralizedContentCards = styled.div`
  max-width: 1900px;
`;

export const CardsContainer = styled.div`
  overflow-x: auto;
  height: 380px;
  z-index: 2;
  position: relative;
  margin-bottom: -180px;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  height: 100%;
  margin-left: 120px;

  @media (max-width: 1000px) {
    margin-left: 20px;
  }
`;

export const CustomParagraph = styled.p`
  width: 100%;
  color: #252525;
  font-family: Montserrat;
  text-align: left;
  font-size: 24px;
  font-weight: bold;
  margin-top: 50px;

  @media only screen and (max-width: 1000px) {
    font-size: 16px;
    margin-top: 30px;
  }
`;

export const ColorText = styled.span`
  color: ${props => props.color || ''};
`;

export const StyledTitle = styled.p`
  font-size: 27px;
  font-family: Montserrat;
  font-weight: bold;
  padding: 60px 0 30px 0;
`;

export const MySection = styled.section`
  background-color: #242424;
  width: 100%;
  padding-top: 220px;
  padding-bottom: 100px;
`;

export const TitleSection = styled.p`
  font-family: 'Montserrat';
  color: #fff;
  font-weight: bold;
  font-size: 32px;
  padding-top: 154px;

  @media (max-width: 1000px) {
    font-size: 28px;
    padding-top: 50px;
  }
`;

export const BlockSection = styled.div``;

export const LeftSection = styled.div`
  width: 8%;
  height: 100%;

  @media (max-width: 1000px) {
    min-width: 100px;
    padding: 0 40px 0 0;
  }
`;

export const RigthSection = styled.div`
  width: 92%;
  display: block;

  @media (max-width: 1000px) {
    height: 100%;
    min-width: 90px;
  }
`;

export const ImageSection = styled.img``;

export const ParagraphSection = styled.p`
  font-family: 'Montserrat';
  font-size: 20px;
  font-weight: bold;
  color: black;
  line-height: 34px;

  @media (max-width: 1000px) {
    font-size: 14px;
    line-height: 16px;
    font-weight: normal;
  }
`;

export const Centralized = styled.div`
  margin: 0 100px;

  @media (max-width: 1000px) {
    margin: 0 16px;
  }
`;

export const BlockStyler = styled.div`
  width: 100%;
  margin-top: 80px;
  border-radius: 26px;
  border: 2px solid #1f2b2a;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const BlockParagraph = styled.p`
  font-family: Montserrat;
  color: #1f2b2a;
  font-size: 20px;

  @media (max-width: 1000px) {
    font-size: 16px;
  }
`;

export const LeftContainer = styled.div`
  width: 45%;
  display: flex;
  padding: 60px;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 1000px) {
    padding: 38px 38px 0 38px;
    width: 100%;
  }
`;

export const RigthContainer = styled.div`
  width: 55%;
  display: flex;
  align-items: center;

  @media (max-width: 1000px) {
    width: 100%;
  }
`;

const opacityAnimation = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0.8;
  }
`;

export const IframeYoutube = styled.iframe`
  width: 100%;
  height: 376px;
  padding: 30px;

  @media (max-width: 1000px) {
    height: 376px;
  }
`;

export const ButtonImage = styled.button`
  border: 1px;
  width: 100%;
  margin: 30px;
  background-color: transparent;

  :hover {
    opacity: 0.8;
    animation: ${opacityAnimation} 0.2s linear;
  }
`;

export const ImageVideo = styled.img``;

export const AccordionTitle = styled.p`
  font-family: Montserrat;
  font-size: 28px;
  font-weight: bold;
  padding-top: 120px;
  padding-bottom: 41px;

  @media (max-width: 1000px) {
    font-size: 18px;
    padding-top: 60px;
  }
`;

export const CentralizedButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SeeMore = styled.button`
  font-family: Montserrat;
  color: #221f20;
  font-size: 20px;
  border: none;
  background-color: transparent;
  font-weight: bold;

  @media (max-width: 1000px) {
  }
`;

export const ArrowIcon = styled.i`
  border: solid #000;
  border-width: 0 4px 4px 0;
  display: inline-block;
  padding: 7px;
  transform: rotate(45deg);
  margin-left: 20px;
`;

export const FinalText = styled.p`
  white-space: pre-line;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: bold;
  color: #4e4b59;
  margin-top: 30px;
`;

export const AnchorText = styled.p`
  font-family: Montserrat;
  color: #4e4b59;
  font-size: 18px;
  padding-bottom: 30px;
`;

export const HandleShow = styled.div``;
