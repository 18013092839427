import styled from 'styled-components';
import BackgroundCircles from '../../assets/img/DigitalAccount/background_table.png';

export const Container = styled.div`
  background-color: ${props => (props.bgColor ? props.bgColor : '#E8E8E8')};
  min-height: 600px;
  max-height: 600px;
  padding: 0 !important;
  @media (max-width: 767px) {
    min-height: 469px;
    max-height: 469px;
  }
  img {
    min-height: 600px;
    max-height: 600px;
    width: 100%;
    object-fit: cover;
    @media (max-width: 767px) {
      min-height: 469px;
      max-height: 469px;
    }
  }
  a.carousel-control-prev {
    display: none;
  }
  a.carousel-control-next {
    display: none;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 400px;
  @media (max-width: 960px) {
    margin-left: 0px;
  }
`;

export const CarouselContainerCard = styled.div`
  background-image: url(${props =>
    props.isMobile ? null : BackgroundCircles});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: #50ebe6;
  padding: 0;
`;

export const ContentCard = styled.div`
  padding: 15px;
  padding-top: 50px;
  @media (max-width: 900px) {
    padding-bottom: 32px;
  }
  div > p {
    font-size: 16px;
    margin-top: 0;
    color: #000;
  }
  div > p > strong {
    font-weight: bold;
  }
`;

export const ContentCarouselCard = styled.div`
  padding: 15px;
  padding-top: 50px;
  padding-bottom: 50px;
  @media (max-width: 900px) {
    padding-bottom: 32px;
  }
  .body-card {
    max-width: 250px;
  }
`;

export const TitleContainer = styled.div`
  @media (max-width: 960px) {
    margin-left: 0px;
  }
`;

export const Title = styled.div`
  font-size: ${props => (props.bold ? '64px' : '48px')};
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
  margin-left: ${props => (props.marginLeft ? '170px' : '150px')};
  color: #006766;
  @media (max-width: 960px) {
    margin-left: 0px;
    text-align: center;
    font-size: ${props => (props.bold ? '72px' : '32px')};
  }
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  align-self: ${props => (props.center ? 'center' : null)};
  p {
    font-size: 16px;
    margin-top: 10px;
    color: #006766;
  }
  p > strong {
    font-weight: bold;
  }
`;

export const DescriptionLink = styled.a`
  color: #006766;
  font-weight: bold;
  text-decoration: underline;
`;

export const TableTitle = styled.div`
  margin-top: 100px;
  margin-bottom: 60px;
  h2 {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 50px;
  }
  h3 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  span {
    padding: 2px;
    font-size: 16px;
    color: #616b6a;
  }
`;
