import styled from 'styled-components';

export const ContainerAccordions = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  @media (max-width: 1000px) {
    width: 100%;
    align-items: center;
    flex-direction: column;
  }
`;

export const ContentLeft = styled.div`
  width: 50%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin-right: 40px;
  @media (max-width: 1000px) {
    width: 100%;
    align-items: center;
    margin-right: 0px;
    margin-bottom: 0px;
  }
`;
export const ContentRight = styled.div`
  width: 50%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  @media (max-width: 1000px) {
    width: 100%;
    align-items: center;
  }
`;
export const ContentChildren = styled.div`
  width: 100%;
`;
export const TextBlock = styled.p`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #3b4443;
  padding-bottom: 16px;
`;
export const TitleBlock = styled.p`
  font-size: 18px;
  font-weight: 700;
  padding-bottom: 4px;
  font-family: Montserrat;
  line-height: 22px;
  text-align: left;
`;

export const Line = styled.div`
  width: 100%;
  border: 1px solid #221f20;
  margin-bottom: 16px;
  border-radius: 2px;
`;

export const TextBold = styled.span`
  font-size: ${props => props.fontSize || '16px'};
  font-weight: bold;
  display: contents;
`;
