import React from 'react';
import * as S from './styles';

const Card = ({ title, texts }) => {
  return (
    <S.Container>
      <S.Card>
        <S.GroupContent>{title && <S.Title>{title}</S.Title>}</S.GroupContent>
        <S.Line />
        {texts.map(item => (
          <S.TextWithIcon key={`text-icon-${item.key}`}>
            {item.icon && <S.AdditionalIcon src={item.icon} />}
            <S.Description href={item.url} target="_blank">
              {item.text}
            </S.Description>
          </S.TextWithIcon>
        ))}
      </S.Card>
    </S.Container>
  );
};
export default Card;
