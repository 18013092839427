import React from 'react';

import infoIcon from '~/assets/img/GuaranteedLimitLP/info.svg';
import womanImg from '~/assets/img/GuaranteedLimitLP/woman.svg';
import {
  Container,
  Content,
  Header,
  Title,
  Text,
  Footer,
  FooterIconContainer,
  FooterInfo,
  Main,
  EndTimelineContainer,
  EndTimelineInfo,
  EndTimelineImageContainer,
  EndTimelineImage,
} from './styles';
import { Timeline } from '../Timeline';

export const HowItWorks = ({ isMobile }) => (
  <Container id="como-funciona">
    <Content>
      <Header>
        <Title>Como funciona</Title>

        <Text>
          Saiba como garantir o limite do seu Cartão Riachuelo em apenas 4
          passos simples:
        </Text>
      </Header>

      <Main mobile={isMobile}>
        <Timeline />

        <EndTimelineContainer mobile={isMobile}>
          <EndTimelineInfo mobile={isMobile}>
            <h3>Seu saldo rende mais que a poupança</h3>
            <p>
              O melhor de tudo é que após 30 dias o depósito feito passa a
              render 100% do CDI, ou seja, muito mais que a poupança!
            </p>
          </EndTimelineInfo>

          <EndTimelineImageContainer>
            <EndTimelineImage
              mobile={isMobile}
              src={womanImg}
              alt="Na imagem, uma jovem mulher de pele clara e cabelos castanhos escuros, está sorrindo enquanto segura um celular. Ela veste uma camisa cinza e está cercada por um círculo laranja, destacando sua figura principal."
            />
          </EndTimelineImageContainer>
        </EndTimelineContainer>
      </Main>

      <Footer mobile={isMobile}>
        <FooterIconContainer mobile={isMobile}>
          <img src={infoIcon} alt="Icone com um símbolo exclamação" />
        </FooterIconContainer>

        <FooterInfo mobile={isMobile}>
          <p>
            Pague sua fatura em dia para manter os benefícios do Cartão
            Riachuelo
          </p>
          <span>
            Em caso de não pagamento da fatura, utilizaremos sem aviso prévio o
            saldo reservado e isso resultará na perda do limite e bloqueio do
            seu cartão.
          </span>
        </FooterInfo>
      </Footer>
    </Content>
  </Container>
);
