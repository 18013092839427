/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from 'react';
import Accordion from '~/components/Accordion';
import { trackingSelectContent } from '~/analytics';
import BannerDesktop from '~/assets/img/lending/Banner.png';
import BannerMobile from '~/assets/img/lending/banner-mobile.png';
import Iphone from '~/assets/img/lending/iphone.png';
import PlayStore from '~/assets/img/lending/play-store.svg';
import AppleStore from '~/assets/img/lending/apple-store.svg';
import MaisSaude from '~/assets/img/lending/mais-saude.png';
import * as S from './styles';

import Cards from './components/Cards';
import CardsButton from './components/CardsButton';
import DownloadStore from './components/DownloadStore';
import ListStepNumber from './components/ListStepNumber';

import PorcenIcon from '../../assets/img/lending/percentage.svg';
import CardsIcon from '../../assets/img/lending/cards.svg';
import CheckIcon from '../../assets/img/lending/check.svg';

const STEP_LIST = [
  {
    value: '1',
    text: 'Baixe o nosso aplicativo Midway;',
  },
  {
    value: '2',
    text: 'Encontre a opção Empréstimos e selecione Empréstimo Pessoal;',
  },
  {
    value: '3',
    text: 'Selecione valor, vencimento e parcelas desejadas;',
  },
  {
    value: '4',
    text: 'Escolha entre as opções com ou sem seguro proteção financeira;',
  },
  {
    value: '5',
    text: 'Confira o resumo do seu seguro, aceite os termos e condições e finalize a contratação.',
  },
];

const NewLending = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };
  return (
    <S.Container>
      <S.ContainerGlobal>
        <S.BannerContainer>
          <S.BannerDesktop src={BannerDesktop} />
          <S.BannerMobile src={BannerMobile} />
        </S.BannerContainer>
        <S.Main>
          <S.ContentTextCard>
            <S.ContentTop>
              <S.TitleContent>
                Seja qual for o seu sonho, com o Empréstimo Pessoal Midway você
                pode torná-lo realidade!
              </S.TitleContent>
              <S.TextContent>
                Você pode ter acesso a até R$ 17 mil para fazer o que quiser.
                Seja para realizar um projeto pessoal, quitar dívidas ou
                investir em seu sonho.{' '}
              </S.TextContent>
            </S.ContentTop>
            <S.ContentBottom>
              <S.ContainerCards>
                <Cards
                  icon={CardsIcon}
                  title="Condições feitas para você"
                  description="Limite de até R$ 17.000,00 e em até 90 dias para começar a pagar! "
                />
                <Cards
                  icon={CheckIcon}
                  title="Não precisa de garantia"
                  description="Não pedimos bens, como carros e imóveis de garantia."
                />
                <Cards
                  icon={PorcenIcon}
                  title="Com taxas de juros fixa"
                  description="Justo e transparente! Você não tem surpresas na hora de pagar a parcela."
                />
              </S.ContainerCards>
            </S.ContentBottom>
          </S.ContentTextCard>
          <S.InformationPanel>
            <S.LeftPanel>
              <S.LeftImagePanel src={Iphone} />
            </S.LeftPanel>
            <S.RightPanel>
              <S.CentralizeRightPanel>
                <S.TextPanel>
                  Para contratar o Empréstimo Pessoal é simples!{' '}
                </S.TextPanel>
                <S.GroupLeft>
                  <ListStepNumber data={STEP_LIST} />
                </S.GroupLeft>
                <S.TextMidway>Baixe o aplicativo Midway</S.TextMidway>
                <S.Agroup>
                  <div
                    onClick={() =>
                      trackingSelectContent(
                        'download:google-play',
                        'emprestimos'
                      )
                    }
                  >
                    <DownloadStore
                      iconSrc={PlayStore}
                      onClick={() =>
                        window.open(
                          'https://play.google.com/store/apps/details?id=br.com.midway&hl=en&gl=BR',
                          '_blank'
                        )
                      }
                    >
                      Download via <br />
                      <S.TextBold fontSize="18px">Google Play</S.TextBold>
                    </DownloadStore>
                  </div>
                  <div
                    onClick={() =>
                      trackingSelectContent(
                        'download:apple-store',
                        'emprestimos'
                      )
                    }
                  >
                    <DownloadStore
                      iconSrc={AppleStore}
                      onClick={() =>
                        window.open(
                          'https://apps.apple.com/br/app/midway-%C3%A9-riachuelo/id1548732480',
                          '_blank'
                        )
                      }
                    >
                      Download via <br />
                      <S.TextBold fontSize="18px">Apple Store</S.TextBold>
                    </DownloadStore>
                  </div>
                </S.Agroup>
              </S.CentralizeRightPanel>
            </S.RightPanel>
          </S.InformationPanel>
          <S.ContainerCardBlackTop>
            <S.CardLeft src={MaisSaude} />
            <S.CardRight>
              <S.CardTitle>Contrate nas Lojas Riachuelo</S.CardTitle>
              <S.CardText>
                Encontre uma de nossas lojas físicas, contrate o seu Empréstimo
                e garanta o seu dinheiro!
              </S.CardText>
              <S.ButtonCard
                onClick={() =>
                  trackingSelectContent(
                    'contrate-nas-lojas-riachuelo:encontrar-a-loja-mais-proxima',
                    'emprestimos'
                  )
                }
                href="https://www.midway.com.br/buscar-lojas"
                target="_blank"
              >
                Encontrar a loja mais próxima
              </S.ButtonCard>
              <S.Line />
              <S.CardText paddingBottom="16px">
                Se você preferir, também pode contratar pela central de
                atendimento através dos telefones:
              </S.CardText>
              <S.CardText paddingBottom="8px">
                Capitais e regiões metropolitanas <br />{' '}
                <S.TextBold>3004-5417</S.TextBold>{' '}
              </S.CardText>
              <S.CardText>
                Demais localidades <br /> <S.TextBold>0800-727-4417</S.TextBold>{' '}
              </S.CardText>
            </S.CardRight>
          </S.ContainerCardBlackTop>
          <S.CardContentButtons>
            <S.ContentCardTop>
              <S.TitleContentCard>
                Saiba como pagar as parcelas do seu empréstimo
              </S.TitleContentCard>
              <S.TextContentCard>
                Escolha a forma de pagamento que faça mais sentido para o seu
                orçamento
              </S.TextContentCard>
            </S.ContentCardTop>
            <S.ContentCardBottom>
              <CardsButton
                title="Boleto bancário"
                description="Com essa opção, você pode pagar via internet banking, em bancos, lotéricas ou correspondentes bancários, como farmácias e supermercados."
                button="Pagar por boleto"
                link="/consulta-boleto"
                contentType="pagar-por-boleto"
                flow="emprestimos"
              />
              <CardsButton
                title="Débito automático"
                description="Contratando pelo aplicativo Midway, o valor da parcela pode ser debitado automaticamente da sua Conta na data de vencimento."
                button="Baixar o aplicativo Midway"
                link="https://www.midway.com.br/app/"
                contentType="baixar-o-aplicativo"
                flow="emprestimos"
              />
              <CardsButton
                title="Lojas Riachuelo"
                description="Encontre a loja mais próxima de você, procure um de nossos atendentes e faça o pagamento."
                button="Encontrar loja mais próxima"
                link="/buscar-lojas"
                contentType="encontrar-loja-mais-proxima"
                flow="emprestimos"
              />
            </S.ContentCardBottom>
          </S.CardContentButtons>
          <S.ContentAccordion>
            <S.ContainerAccordionTitle>
              <S.AccordionTitle>Perguntas frequentes</S.AccordionTitle>
            </S.ContainerAccordionTitle>
            <S.ContainerAccordion>
              <S.SpadeAccordion>
                <S.HandleShow
                  onClick={() => {
                    trackingSelectContent(
                      isOpen
                        ? 'faq:fechar:o-limite-do-emprestimo-influencia-no-limite-do-meu-cartao-de-credito'
                        : 'faq:abrir:o-limite-do-emprestimo-influencia-no-limite-do-meu-cartao-de-credito',
                      'emprestimos'
                    );
                    toggleAccordion();
                  }}
                >
                  <Accordion
                    key="1"
                    title="O limite do Empréstimo influencia no limite do meu cartão de crédito?"
                    content="Não. Os limites são independentes e a utilização de um não influencia no limite do outro."
                  />
                </S.HandleShow>
              </S.SpadeAccordion>
              <S.HandleShow
                onClick={() => {
                  trackingSelectContent(
                    isOpen
                      ? 'faq:fechar:como-e-feita-a-analise-de-credito'
                      : 'faq:abrir:como-e-feita-a-analise-de-credito',
                    'emprestimos'
                  );
                  toggleAccordion();
                }}
              >
                <Accordion
                  key="2"
                  title="Como é feita a análise de crédito?"
                  content="Antes de definir o limite, taxa, carência e prazo ideal para você, fazemos uma avaliação da sua saúde financeira, levando em conta vários critérios, como por exemplo, seu histórico de crédito no mercado."
                />
              </S.HandleShow>
              <S.HandleShow
                onClick={() => {
                  trackingSelectContent(
                    isOpen
                      ? 'faq:fechar:preciso-de-dinheiro-mas-nao-tenho-pre-aprovador-o-que-fazer'
                      : 'faq:abrir:preciso-de-dinheiro-mas-nao-tenho-pre-aprovador-o-que-fazer',
                    'emprestimos'
                  );
                  toggleAccordion();
                }}
              >
                <Accordion
                  key="3"
                  title="Preciso de dinheiro, mas não tenho limite pré-aprovado. O que fazer?"
                  content="Sentimos por não poder ajudar nesse momento, mas a análise de crédito pode ser refeita mensalmente! Sugerimos que você tente de novamente daqui a 30 dias."
                />
              </S.HandleShow>
              <S.HandleShow
                onClick={() => {
                  trackingSelectContent(
                    isOpen
                      ? 'faq:fechar:em-quanto-tempo-tenho-um-limite-pre-aprovado-apos-a-aquisição-do-cartao-riachuelo-pl-ou-bandeira-ou-criacao-da-conta-midway'
                      : 'faq:abrir:em-quanto-tempo-tenho-um-limite-pre-aprovado-apos-a-aquisição-do-cartao-riachuelo-pl-ou-bandeira-ou-criacao-da-conta-midway',
                    'emprestimos'
                  );
                  toggleAccordion();
                }}
              >
                <Accordion
                  key="4"
                  title="Em quanto tempo tenho um limite pré-aprovado após a aquisição do cartão Riachuelo (PL ou Bandeira) ou criação da conta Midway?"
                  content="A liberação de limite pré-aprovado após a aquisição do cartão Riachuelo, seja Private Label (PL) ou Bandeira (BD), pode variar dependendo das políticas internas. Geralmente, a análise ocorre a partir de 30 dias de aquisição do cartão."
                />
              </S.HandleShow>
              <S.HandleShow
                onClick={() => {
                  trackingSelectContent(
                    isOpen
                      ? 'faq:fechar:como-realizo-o-pagamentos-das-parcelas'
                      : 'faq:abrir:como-realizo-o-pagamentos-das-parcelas',
                    'emprestimos'
                  );
                  toggleAccordion();
                }}
              >
                <Accordion
                  key="5"
                  title="Como realizo o pagamentos das parcelas?"
                  content="Você pode gerar boleto para pagamento da parcela a qualquer momento através do aplicativo Midway, Central de Atendimento, no site ou em qualquer Loja Riachuelo informando o seu CPF. Caso você tenha realizado o Empréstimo no aplicativo Midway, as parcelas são debitadas direto da sua Conta Digital conforme data de pagamento definida das parcelas."
                />
              </S.HandleShow>
            </S.ContainerAccordion>
            <S.ContentBottomAccordion>
              <S.FinalTextBold>Ainda com dúvidas?</S.FinalTextBold>
              <S.FinalTextQuestion>
                Confira mais informações em{' '}
                <S.AnchorTitle
                  onClick={() =>
                    trackingSelectContent('perguntas-frequentes', 'emprestimos')
                  }
                  href="https://www.midway.com.br/emprestimos/faq"
                  target="_blank"
                >
                  perguntas frequentes
                </S.AnchorTitle>
                .
              </S.FinalTextQuestion>
              <S.ContentHide>
                <S.TextContentBottom>
                  O cliente tem a possibilidade de transferir a dívida de um
                  empréstimo ou financiamento contratado em uma determinada
                  instituição financeira para outra. Com a transferência, a taxa
                  de juros pode sofrer alterações, mas o valor e o prazo do
                  contrato devem ser idênticos ao saldo devedor e ao prazo
                  remanescente da operação.
                </S.TextContentBottom>
                <S.TextSecondaryBottom>
                  Caso o cliente queira fazer a portabilidade de seus contratos
                  para outro banco é necessário ir até a agência onde fez o
                  empréstimo para solicitar o saldo devedor e os dados do
                  contrato, conforme estabelece a Resolução 4.292, do Bacen.
                </S.TextSecondaryBottom>
              </S.ContentHide>
            </S.ContentBottomAccordion>
          </S.ContentAccordion>
        </S.Main>
      </S.ContainerGlobal>
    </S.Container>
  );
};

export default NewLending;
