import React, { useState, useEffect } from 'react';
import { Image } from '@midway/web-ui-component';
import { keyboard } from '~/constants';
import history from '~/services/history';
import Banner from '~/assets/img/Financial/insurance/banner.png';

import cmsService from '~/services/cms/pages';

import AccessibleLink from '~/components/AccessibleLink';

import Card from '~/components/Card';
import Table from '~/components/Table';
import TableInsurance from '~/components/TableInsurance';
import Error from '~/components/Error';
import Loading from '~/components/Loading';
import InsuranceItem from './InsurenceItem';
import * as S from './styles';

const Insurance = () => {
  const [insuranceItemContaPaga, setInsuranceItemContaPaga] = useState([]);
  const [, setInsuranceItemResidencial] = useState([]);
  const [insuranceItemAcidentes, setInsuranceItemAcidentes] = useState([]);
  const [insuranceItemBolsaProtegida, setInsuranceItemBolsaProteguda] =
    useState([]);
  const [tableSeguroAcidentesPessoais, setTableSeguroAcidentesPessoais] =
    useState([]);
  const [tableSeguroContaPaga, setTableSeguroContaPaga] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const errorTitle = 'Tivemos um problema';
  const errorBody = 'Não foi possível carregar.';

  useEffect(() => {
    setIsError(false);
    setIsLoading(true);
    cmsService
      .getTableInsurance(process.env.REACT_APP_SITE_CMS_ENV)
      .then(res => {
        const x = JSON.stringify(res, null, '');
        const y = JSON.parse(x);
        const z = JSON.parse(y);

        setInsuranceItemContaPaga(z.InsuranceItemContaPaga);
        setInsuranceItemResidencial(z.InsuranceItemResidencial);
        setInsuranceItemAcidentes(z.InsuranceItemAcidentes);
        setTableSeguroAcidentesPessoais(z.tableSeguroAcidentesPessoais);
        setInsuranceItemBolsaProteguda(z.InsuranceBolsaProtegida);
        setTableSeguroContaPaga(z.tableSeguroContaPaga);
        setIsLoading(false);
        setIsError(false);
      })
      .catch(_ => {
        setIsLoading(false);
        setIsError(true);
      });
  }, []);

  const list = [
    {
      id: 1,
      plan1: 'Até R$ 150.000,00',
      value: 'Incêndio, raio, explosão e implosão*',
    },

    {
      id: 2,
      plan1: 'Até R$ 15.000,00',
      value: 'Impacto de veículos terrestres e queda de aeronaves*',
    },
    {
      id: 3,
      plan1: 'Até R$ 15.000,00',
      value: 'Perda ou pagamento de aluguel*',
    },
    {
      id: 4,
      plan1: 'Até R$ 15.000,00',
      value: 'Responsabilidade civil familiar*',
    },
    {
      id: 5,
      plan1: 'Até R$ 5.000,00',
      value: 'Danos elétricos*',
    },
    {
      id: 6,
      plan1: 'Até R$ 5.000,00',
      value: 'Roubo e bens',
    },
  ];

  const coveragePlans = [
    {
      id: 1,
      plan1: 'Até R$ 2.000,00',
      plan2: '30 dias',
      plan3: 'Não há',
      value: 'Bolsa Protegida',
    },
    {
      id: 2,
      plan1: 'Até R$ 2.000,00',
      plan2: '30 dias',
      plan3: 'Não há',
      value: 'Roubo ou furto qualificado do cartão',
    },
    {
      id: 3,
      plan1: 'Até R$ 2.000,00',
      plan2: '30 dias',
      plan3: 'Não há',
      value: 'Perda ou furto simples do cartão',
    },
  ];

  const coveragePlans2 = [
    {
      id: 1,
      plan1: 'Até R$ 1.000,00',
      plan2: 'Não há',
      plan3: 'Não há',
      value:
        'Reembolso de Transferência de Valores em Caso de Coação (Empréstimo ou Crédito Pré-Aprovado)',
    },
    {
      id: 2,
      plan1: 'Até R$ 1.000,00',
      plan2: 'Não há',
      plan3: 'Não há',
      value:
        'Reembolso de Transferência de Valores em Caso de Extorsão ou Sequestro (Empréstimo ou Crédito Pré-Aprovado)',
    },
    {
      id: 3,
      plan1: 'Até R$ 1.000,00',
      plan2: 'Não há',
      plan3: 'Não há',
      value:
        'Reembolso de Transferência de Valores em Caso de Coação (Conta Corrente e Cheque Especial)',
    },
    {
      id: 4,
      plan1: 'Até R$ 1.000,00',
      plan2: 'Não há',
      plan3: 'Não há',
      value:
        'Reembolso de Transferência de Valores em Caso de Extorsão ou Sequestro (Conta Corrente e Cheque Especial)',
    },
  ];

  return (
    <>
      <S.Container
        className="container-fluid"
        id="ready-page-for-screen-reader"
      >
        <S.BannerContainer className="container-fluid">
          <Image
            src={Banner}
            alt="Imagem com uma mulher sorrindo olhando diretamente para câmera"
            aria-hidden="true"
          />
          <div className="banner-title">
            <h1 className="is-primary-default">{`A gente protege o que é\nmais importante pra\nvocê: Sua família, suas\nconquistas e a sua saúde.`}</h1>
          </div>
        </S.BannerContainer>
        <S.Content className="container">
          {isLoading ? (
            <Loading />
          ) : isError ? (
            <Error errorTitle={errorTitle} errorBody={errorBody} refreshPage />
          ) : (
            <>
              <InsuranceItem
                icon={['mid', 'home']}
                title="Seguro Residencial"
                subTitle="Sua casa, sua conquista. Deixe ela sempre protegida."
                otherText="SEGURANÇA E PROTEÇÃO"
                text={
                  <span>
                    Cobertura em casos de Incêndio/Raio/Explosão; Impacto de
                    veículos terrestres e queda de aeronaves; Perda ou pagamento
                    de aluguel; Responsabilidade civil familiar; Danos
                    elétricos, Roubo de bens e Assistência descarte ecológico.
                  </span>
                }
              />

              <div className="row mt-4">
                <div className="col-sm-12 col-md-12 col-lg-10 col-xl-10">
                  <Table
                    columns={['Coberturas', 'Limite máximo de indenização']}
                    aria="coberturas e limite máximo de indenização"
                    data={list}
                  />
                </div>
              </div>

              <S.Info>
                <p>(*) Não se aplica a carência e franquia.</p>

                <div className="mt-4">
                  <p className="font-weight-bold ">
                    Para utilizar a assistência de descarte ecológico
                  </p>
                  <p>Ligue para: 0800-326-1001 </p>
                  <p>Horário de funcionamento:</p>
                  <p>
                    Segunda a Sexta das 08:00 às 20:00 / Sábado das 09:00 às
                    15:00
                  </p>
                </div>
                <div className="mt-4">
                  <p>
                    Retirada e Descarte Móveis, eletrodomésticos e eletrônicos.
                    Neste serviço, o segurado poderá utilizar a assitência para
                    retirar itens de sua residência, Todo material será
                    armazenado, desmontado e destinado.
                  </p>
                </div>

                <div className="mt-4">
                  <h3 className="font-weight-bold ">
                    PARA UTILIZAR O SEGURO - ABERTURA DE SINISTRO
                  </h3>

                  <p>Ligue para CENTRAL DE ATENDIMENTO ZURICH</p>
                  <a href="https://www.zurich.com.br/pt-br">
                    https://www.zurich.com.br/pt-br
                  </a>

                  <div className="mt-4">
                    <p>Você pode também conversar com a nossa assistente</p>
                    <a href="https://www.zurich.com.br/pt-br/chatonline">
                      https://www.zurich.com.br/pt-br/chatonline
                    </a>
                  </div>
                </div>
                <div className="mt-4">
                  <p className="font-weight-bold text-uppercase">
                    abertura de sinistro via central de atendimento
                  </p>
                  <p>4020 4848 (capitais e regiões metropolitanas)</p>
                  <p>0800 285 4141 (demais localidades)</p>
                  <p>
                    Horário de atendimento de Segunda à Sexta-feira 8h às 20h
                  </p>
                </div>

                <div className="mt-4">
                  <h4 className="font-weight-bold text-uppercase">
                    veja as condições gerais do produto
                  </h4>
                  <h4>comercialização a partir de 25 de Outubro de 2021</h4>
                  <br />
                  <h4 className="font-weight-bold">
                    Condições Gerais na íntegra Seguro Residencial
                  </h4>
                  <AccessibleLink
                    route="/contrato/condição-geral-Residêncial"
                    text="clique aqui"
                    hasUnderline
                    data-gtm-event-category="midway:seguros"
                    data-gtm-event-action="clique:link"
                    data-gtm-event-label="link:condicoes-gerais-integra-seguro-residencial"
                  />
                  <br />
                  <br />
                  <h4 className="font-weight-bold">
                    Resumo de Condições Gerais Seguro Residencial
                  </h4>
                  <AccessibleLink
                    route="/contrato/resumo-de-condição-geral"
                    text="clique aqui"
                    hasUnderline
                    data-gtm-event-category="midway:seguros"
                    data-gtm-event-action="clique:link"
                    data-gtm-event-label="link:resumo-condicoes-gerais-seguro-residencial-ate-2017"
                  />
                  <br />
                  <br />
                  <h4 className="font-weight-bold">
                    Condições Gerais da Assistência descarte Ecológico
                  </h4>
                  <AccessibleLink
                    route="/contrato/Condições-Gerais-da-Assistência-descarte-Ecológico"
                    text="clique aqui"
                    hasUnderline
                    data-gtm-event-category="midway:seguros"
                    data-gtm-event-action="clique:link"
                    data-gtm-event-label="link:Condições-Gerais-da-Assistência-descarte-Ecológico"
                  />
                </div>
              </S.Info>
              <InsuranceItem
                icon={['mid', 'health']}
                title="Seguro Acidentes Pessoais"
                subTitle={insuranceItemAcidentes.subTitle}
                text="Em caso de morte acidental, invalidez permanente total ou parcial por acidente e assistência funeral."
              />
              <S.Info top={52}>
                <div className="row justify-content-between">
                  <div className="col-sm-12 col-md-12 col-lg-5 col-xl-5">
                    <Card
                      headerFontSize="25px"
                      className="mt-0"
                      title="Coberturas – Plano Individual"
                    >
                      <span>
                        Morte Acidental e Invalidez Permanente Total ou Parcial
                        por Acidente até <b>R$ 56.500,00</b>
                      </span>
                    </Card>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-5 col-xl-5">
                    <Card
                      headerFontSize="25px"
                      className="mt-0"
                      title="Capitais Segurados"
                    >
                      <span>
                        Assistência Funeral até <b>R$ 2.260,00</b>
                      </span>
                    </Card>
                  </div>
                </div>
                <S.TableWrapper>
                  <TableInsurance
                    columns={[
                      'Coberturas - Plano Familiar',
                      'Titular',
                      'Capitais Segurados Cônjuge',
                      'Filho',
                    ]}
                    aria="Faixa de valor de cobertura de Seguro Acidentes Pessoais Premiáveis"
                    ariaCell="Faixa"
                    data={tableSeguroAcidentesPessoais}
                  />
                </S.TableWrapper>
                <div className="mt-4">
                  <h3 className="font-weight-bold text-uppercase">
                    PARA UTILIZAR O SEU SEGURO
                  </h3>
                  <p>
                    Ligue para central de atendimento da Mapfre Seguradora: 0800
                    722 7148 (Segunda a Sexta – 08h as 18h)
                  </p>
                </div>
                <div className="mt-4">
                  <h3 className="font-weight-bold text-uppercase">
                    PARA UTILIZAR O SERVIÇO DA ASSISTÊNCIA FUNERAL
                  </h3>
                  <p>
                    Ligue para central de atendimento da assistência: <br />
                    4002 7196 Capitais e Regiões Metropolitanas <br />
                    0800 775 7196 Demais Localidades
                  </p>
                </div>

                <div className="mt-4">
                  <h3 className="font-weight-bold text-uppercase">
                    VEJA AS CONDIÇÕES GERAIS DO PRODUTO:
                  </h3>
                  <h4 className="font-weight-bold text-uppercase">
                    Condições Gerais na Íntegra Seguro Acidentes Pessoais:
                  </h4>
                  Condições Gerais:{' '}
                  <AccessibleLink
                    route="/contrato/CONDICOES-GERAIS-NA-INTEGRA_Seguro-Acidentes-PessoaisColetivo"
                    text="saiba mais"
                    data-gtm-event-category="midway:seguros"
                    data-gtm-event-action="clique:link"
                    data-gtm-event-label="link:condicoes-gerais-integra-seguro-acidentes-pessoais"
                  />
                  <br />
                  <br />
                  <h4 className="font-weight-bold text-uppercase">
                    Resumo de Condições Gerais Seguro Acidentes Pessoais:
                  </h4>
                  Resumo de Condições Gerais Acidentes Pessoais (Comercializado
                  até outubro 2017):{' '}
                  <AccessibleLink
                    route="/contrato/CGS-RESUMO-SEGURO-ACIDENTES-PESSOAIS-ATE-2017"
                    text="saiba mais"
                    data-gtm-event-category="midway:seguros"
                    data-gtm-event-action="clique:link"
                    data-gtm-event-label="link:resumo-condicoes-gerais-seguro-acidentes-pessoais-ate-2017"
                  />
                  <br />
                  Resumo de Condições Gerais Acidentes Pessoais (Comercializado
                  até outubro 2017 - Renovações após 29/11/2022):{' '}
                  <AccessibleLink
                    route="/contrato/CGS-RESUMO-SEGURO-ACIDENTES-PESSOAIS-RENOVACOES"
                    text="saiba mais"
                    data-gtm-event-category="midway:seguros"
                    data-gtm-event-action="clique:link"
                    data-gtm-event-label="link:resumo-condicoes-gerais-seguro-acidentes-pessoais-renovacoes"
                  />
                  <br />
                  Resumo de Condições Gerais Acidentes Pessoais (Comercializado
                  a partir de novembro 2017):{' '}
                  <AccessibleLink
                    route="/contrato/CGS-RESUMO-SEGURO-ACIDENTES-PESSOAIS-APOS-OUTUBRO-2017"
                    text="saiba mais"
                    data-gtm-event-category="midway:seguros"
                    data-gtm-event-action="clique:link"
                    data-gtm-event-label="link:resumo-condicoes-gerais-seguro-acidentes-pessoais-atual"
                  />
                </div>
              </S.Info>
              <InsuranceItem
                icon={['mid', 'products']}
                title="Seguro Mais Proteção"
                subTitle={insuranceItemBolsaProtegida.subTitle}
                text="Sair de casa com a sensação de ter seus pertences protegidos agora é realidade, afinal, você agora pode contar com o Mais Proteção ."
              />

              <S.Info top={83}>
                <S.TableWrapper>
                  <TableInsurance
                    columns={[
                      'Coberturas (Planos Mega e Super)',
                      'Limite máximo de indenização',
                      'Carência',
                      'Franquia',
                    ]}
                    aria="Faixa de valor do Seguro Mais Proteção"
                    ariaCell="Faixa"
                    data={coveragePlans}
                  />
                </S.TableWrapper>
                <br />
                <br />
                <S.TableWrapper>
                  <TableInsurance
                    columns={[
                      `Coberturas contra crime por Pix (somente Plano Mega)`,
                      'Limite máximo de indenização',
                      'Carência',
                      'Franquia',
                    ]}
                    aria="Faixa de valor do Seguro Mais Proteção "
                    ariaCell="Faixa"
                    data={coveragePlans2}
                  />
                </S.TableWrapper>
                <div className="mt-4">
                  <h3 className="font-weight-bold text-uppercase">segurança</h3>
                  <p>
                    Pagamento de compras não autorizadas Feitas em seu Cartão
                    Riachuelo até R$ 2.000,00 em caso de perda, roubo ou furto
                    qualificado do cartão.
                  </p>
                </div>
                <div className="mt-4">
                  <h3 className="font-weight-bold text-uppercase">
                    para utilizar o seguro - abertura de sinistro
                  </h3>
                  <p>Ligue para Central de Atendimento Zurich</p>
                  <p>
                    Horário de Atendimento: Segunda a Sexta-feira, das 8h ás 20h
                    e aos Sábados das 8h ás 18h, exceto feriados.{' '}
                  </p>
                  <br />
                  <br />
                  <p>4020 4345 (capitais e regiões metropolitanas)</p>
                  <p>0800 285 4345 (demais localidades)</p>
                  <p>SAC: 0800 284 4848</p>
                  <p>
                    Deficiente auditivo: 0800 275 8585. Horário de atendimento:
                    24 horas, 7 dias na semana.
                  </p>
                </div>
                <br />

                <div className="mt-4">
                  <h3 className="font-weight-bold text-uppercase">
                    VEJA AS CONDIÇÕES GERAIS DO PRODUTO:
                  </h3>

                  <br />
                  <br />
                  <p>
                    Resumo de Condições Gerais Seguro Mais Proteção - Plano
                    Super - (Comercializado a partir de 21/07/2022):{' '}
                    <AccessibleLink
                      route="/contrato/Resumo-CG-Plano-Super-bolsa"
                      text="Clique aqui"
                      data-gtm-event-category="midway:seguros"
                      data-gtm-event-action="clique:link"
                      data-gtm-event-label="link:Resumo-CG-Plano-Super-bolsa"
                    />
                  </p>
                  <br />
                  <p>
                    Resumo das Condições Gerais Seguro Mais Proteção - Plano
                    Mega - (Comercializado a partir de 21/07/2022):{' '}
                    <AccessibleLink
                      route="/contrato/resumoCG-plano-mega-PIX"
                      text="Clique aqui"
                      data-gtm-event-category="midway:seguros"
                      data-gtm-event-action="clique:link"
                      data-gtm-event-label="link:resumoCG-plano-mega-PIX"
                    />
                  </p>
                  <br />
                  <p>
                    Condições Gerais na Íntegra Cobertura de Perda, Roubo e
                    Furto do Cartão, e PIX - (Comercializada a partir de
                    21/07/2022):{' '}
                    <AccessibleLink
                      route="/contrato/seguro-mais-proteção-cartão-pix"
                      text="Clique aqui"
                      data-gtm-event-category="midway:seguros"
                      data-gtm-event-action="clique:link"
                      data-gtm-event-label="link:seguro-mais-proteção--cartão-pix"
                    />
                  </p>
                  <br />
                  <p>
                    Condições Gerais na Íntegra Cobertura de Bolsa Protegida -
                    (Comercializada a partir de 21/07/2022):{' '}
                    <AccessibleLink
                      route="/contrato/seguro-mais-protecao-bolsa"
                      text="Clique aqui"
                      data-gtm-event-category="midway:seguros"
                      data-gtm-event-action="clique:link"
                      data-gtm-event-label="link:resumo-condicoes-gerais-seguro-bolsa-protegida"
                    />
                  </p>
                  <br />
                </div>
              </S.Info>
              <InsuranceItem
                icon={['mid', 'smile']}
                title={insuranceItemContaPaga.title}
                subTitle={insuranceItemContaPaga.subTitle}
                text={insuranceItemContaPaga.text}
              />
              <S.Info top={52}>
                <S.TableWrapper>
                  <TableInsurance
                    columns={[
                      'Coberturas',
                      'Valor do Seguro R$19,90',
                      'Carência',
                      'Franquia',
                    ]}
                    aria="Faixa de valor de cobertura de Seguro Conta Paga"
                    ariaCell="Faixa"
                    data={tableSeguroContaPaga}
                  />
                </S.TableWrapper>
                * Cobertura exclusiva para profissionais assalariados, regidos
                pela CLT <br />
                ** Cobertura exclusiva para profissionais autônomos
                regulamentados
                <div className="mt-4">
                  <h3 className="font-weight-bold text-uppercase">
                    PARA UTILIZAR O SEU SEGURO - ABERTURA DE SINISTRO
                  </h3>
                  <p>1. Ligue para Central de Atendimento Zurich</p>
                  <p>4020 4848 (capitais e regiões metropolitanas)</p>
                  <p>0800 285 4141 (demais localidades)</p>
                  <p>
                    Horário de atendimento de Segunda à Sexta-feira 8h às 20h
                  </p>
                </div>
                <div className="mt-4">
                  <p>2. Abertura de sinistro Via Portal da Zurich:</p>
                  <a href="https://www.zurich.com.br/pt-br">
                    https://www.zurich.com.br/pt-br
                  </a>
                </div>
                <div className="mt-4">
                  <p>3. Ou a Laiz, a assistente virtual da Zurich:</p>
                  <a href="https://www.zurich.com.br/pt-br/chatonline">
                    https://www.zurich.com.br/pt-br/chatonline
                  </a>
                </div>
                <div className="mt-4">
                  <h4 className="font-weight-bold text-uppercase">
                    VEJA AS CONDIÇÕES GERAIS DO PRODUTO
                  </h4>
                  Resumo das Condições Gerais do Seguro Conta Paga
                  (Comercializado a partir de 11.09.2023):{' '}
                  <AccessibleLink
                    route="/resumo-das-condicoes-gerais-novo-seguro-conta-paga.pdf"
                    text="condições gerais"
                    data-gtm-event-category="midway:seguros"
                    data-gtm-event-action="clique:link"
                    data-gtm-event-label="link:condicoes-gerais-integra-seguro-conta-paga"
                  />
                  <br />
                  <br />
                </div>
                <div className="mt-4">
                  <p>
                    Seguros Garantidos por Zurich Minas Brasil Seguros S/A -
                    Código SUSEP da Seguradora: 05495 CNPJ 17.197.385/0001-21.
                    As condições contratuais do Plano de Seguro encontram-se
                    registradas na SUSEP sob os números 115414.900031/2019-96
                    Prestamista Capital Fixo, 15414.901061/2014-13 Diária de
                    Internação Hospitalar, Residencial: 15414.003106/2009-17,
                    Compra Protegida Roubo ou Furto Qualificado n.º
                    15414.005122/2011-69 e Processo Susep Perda ou Roubo do
                    Cartão 15414.002552/2005-81. Fale Conosco: Ouvidoria 0800
                    770 1061 de segunda a sexta-feira, das 8h30 às 17h (exceto
                    feriados) e SAC 24 horas: 0800 284 4848 e Deficiente
                    Auditivo: 0800 275 8585. A comercialização do seguro é
                    fiscalizada pela SUSEP-Superintendência Nacional de Seguros
                    Privados Serviço de Atendimento ao Público SUSEP:
                    0800-0218484, site www.susep.gov.br . O registro deste plano
                    na SUSEP não implica, por parte da Autarquia, incentivo ou
                    recomendação a sua comercialização.
                  </p>
                  <br />
                  <br />
                </div>
              </S.Info>
            </>
          )}
          <S.FooterCard>
            <span>
              Não achou algum produto que estava procurando?
              <br />
              <u
                onClick={() =>
                  history.push('/contratos-de-produtos-descontinuados')
                }
                onKeyPress={event => {
                  event.preventDefault();
                  const { charCode } = event;
                  if (
                    charCode === keyboard.ENTER ||
                    charCode === keyboard.SPACE
                  ) {
                    history.push('/contratos-de-produtos-descontinuados');
                  }
                }}
                data-gtm-event-category="midway:seguros"
                data-gtm-event-action="clique:link"
                data-gtm-event-label="link:contratos-de-produtos-descontinuados"
              >
                <u> Acesse a página de produtos descontinuados </u>
              </u>
            </span>
          </S.FooterCard>
        </S.Content>
      </S.Container>
    </>
  );
};

export default Insurance;
