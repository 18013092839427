export const frequentlyQuestionsData = [
  {
    id: 1,
    title: 'Como eu faço para ter o limite?',
    answer:
      'O cliente terá que depositar no mínimo R$ 50,00. Não temos valor máximo para depósito. O teto do limite para esse piloto é de R$ 500,00, sendo assim, se o cliente quiser o valor máximo de limite terá que depositar R$ 333,33 que multiplicado por 1,5, temos o limite de R$ 500,00.',
  },
  {
    id: 2,
    title: 'O cliente pode depositar um valor acima de R$ 333,33?',
    answer:
      'Sim, o cliente pode depositar qualquer valor acima, contudo, o teto do limite não terá alteração e o valor que ele depositou maior que R$ 333,33 não ficará bloqueado.',
  },
  {
    id: 3,
    title: 'O cliente pode abrir a conta e depositar um valor inferior?',
    answer:
      'Sim, mas ele não poderá participar do piloto, ou seja, não terá o cartão Private Label aprovado com limite pelas regras do produto.',
  },
  {
    id: 4,
    title:
      'Se eu resgatar o valor permaneço com o limite no cartão Private Label?',
    answer:
      'Não. o limite do cartão Private Label será zerado, podendo ser cancelado de forma parcial ou total, com base na avaliação de crédito.',
  },
  {
    id: 5,
    title: 'O valor depositado tem rendimento?',
    answer:
      'Sim, o valor na conta rende automaticamente, acima do rendimento tradicional da poupança.',
  },
  {
    id: 6,
    title: 'Onde consultar o valor bloqueado?',
    answer: 'No APP Midway na aba extrato.',
  },
  {
    id: 7,
    title:
      'Toda vez que eu realizar um deposito na minha conta Midway, terei o limite de 1,5 no cartão Riachuelo?',
    answer:
      'Não, o limite de 1,5 é apenas válido para o primeiro depósito realizado em conta.',
  },
  {
    id: 8,
    title: 'Como realizo o pagamento da fatura?',
    answer:
      'No site e aplicativo Midway, informando CPF e data de nascimento, você pode gerar e copiar o código de barras para pagamento da fatura fechada. No aplicativo Riachuelo, realizando o vínculo do cartão, você pode gerar o boleto para pagamento da fatura fechada. Na loja Riachuelo mais próxima, informando o CPF ou usando o cartão, você pode pagar a fatura no caixa ou terminal de auto atendimento.',
  },
  {
    id: 9,
    title: 'Pago juros se atrasar o pagamento da fatura?',
    answer:
      'Sim, pois na aprovação do cartão Private Label deixamos claro que o valor do depósito não será utilizado para pagar a fatura e sim trata-se de um caução.',
  },
  {
    id: 10,
    title: 'Posso fazer o resgate do valor que depositei?',
    answer:
      'Sim, o resgate do saldo pode ser efetuado somente após a quitação da(s) fatura(s). Não podendo haver nenhum débito na fatura do cartão Private Label em atraso ou aberto.',
  },
  {
    id: 11,
    title: 'Como faço para resgatar o valor bloqueado?',
    answer:
      'O resgate do valor bloqueado apenas poderá ser realizado pelos canais de atendimento da conta Midway na nossa Central de Atendimento ao Cliente através do número do telefone 3003 7729. O contato tem que ser efetuado pelo cliente.',
  },
  {
    id: 12,
    title: 'Se o pagamento da fatura não ocorrer na data de vencimento?',
    answer:
      'Se o pagamento da fatura não ocorrer na data, a Midway terá o direito de utilizar automaticamente e sem aviso prévio o saldo reservado. Se o valor do saldo bloqueado não quitar a fatura, o restante entrará em parcelamento de fatura automaticamente.',
  },
  {
    id: 13,
    title: 'Posso ser negativado se não pagar a fatura?',
    answer: 'Sim, você pode ter o nome negativado.',
  },
];
