import styled from 'styled-components';

export const Container = styled.div`
  background-color: #fff;
  padding-left: 0;
  padding-right: 0;
`;

export const BannerContainer = styled.div`
  background-color: ${props => (props.bgColor ? props.bgColor : '#E8E8E8')};
  position: relative;
  padding: 0 !important;
  @media (max-width: 767px) {
    min-height: 300px;
    max-height: 300px;
  }
  img {
    max-height: 600px;
    width: 100%;
    object-fit: cover;
    @media (max-width: 767px) {
      min-height: 300px;
      max-height: 300px;
    }
  }
  div.banner-title {
    position: absolute;
    white-space: pre-wrap;
    right: 10%;
    top: 170px;
    h1.bold {
      font-weight: bold;
    }
    p {
      font-size: 25px;
    }
    max-width: 500px;
    @media (max-width: 767px) {
      left: 5%;
      top: 50px;
    }
  }
  button {
    margin-top: 10px;
  }
`;

export const Content = styled.div``;

export const Session1 = styled.section`
  h2 {
    color: #252525 !important;
    font-size: 30px !important;
    font-weight: bold !important;
    margin-top: 95px;
    margin-bottom: 45px;
    @media (max-width: 992px) {
      font-size: 28px !important;
      font-weight: bold !important;
      margin-top: 25px;
      margin-bottom: 15px;
    }
  }
  p {
    &.bold {
      margin-top: 30px;
      color: #000;
      font-weight: bold;
    }
    color: #707070;
    font-size: 16px;
    line-height: 24px;
    @media (max-width: 992px) {
      font-size: 14px;
    }
  }
`;

export const Session2 = styled.section`
  min-height: 400px;
  margin-top: 10px;
  padding-top: 50px;
  @media (max-width: 992px) {
    margin-top: 20px;
  }
`;

export const Session3 = styled.section`
  padding-top: 50px;
  margin-bottom: 50px;
  background-color: #7ee6e6;
  @media (max-width: 712px) {
    margin-top: 100px;
    margin-bottom: 100px;
  }
  h2 {
    font-size: 28px !important;
    font-weight: bold !important;
  }
`;

export const Session4 = styled.section`
  margin-top: 50px;
  padding-top: 38px;
  display: block;
  background-color: #7ee6e6;
  @media (max-width: 992px) {
    margin-top: 20px;
  }
  div.carousel-session {
    padding-bottom: 80px;
    padding-left: 30px;
    padding-right: 30px;
    align-items: center;
  }
  /*.rec.rec-dot {
    visibility: hidden;
  }
  .rec.rec-arrow:disabled {
    background-color: transparent;
  }
  .rec.rec-arrow:hover {
    background-color: #006766;
    color: #E8E8E8
}
  .rec.rec-arrow {
    background-color: #E8E8E8;
    color: #7D7D7D
}
  .rec.rec-arrow:focus {
    background-color: #E8E8E8;
    color: #7D7D7D
  }*/
  h2 {
    text-align: center;
    font-size: 28px !important;
    font-weight: bold !important;
    margin-top: 38px;
    margin-bottom: 50px;
    @media (max-width: 992px) {
      margin: auto;
      max-width: 300px;
      font-size: 18px !important;
    }
  }
`;

export const Session5 = styled.section`
  margin-top: 30px;
  background-color: ${props => (props.bgColor ? props.bgColor : '#50EBE6')};
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 992px) {
    margin-top: 30px;
    div:first-child {
      display: flex;
      justify-content: center;
      align-items: center;
      button {
        margin-top: 8px;
      }
    }
  }
  h2 {
    color: #008d8c;
    font-size: 40px !important;
    font-weight: bold !important;
    @media (max-width: 992px) {
      font-size: 24px !important;
    }
  }
`;

export const Session6 = styled.section`
  padding-top: 60px;
  background-color: ${props => (props.bgColor ? props.bgColor : '#F4F4F4')};
  padding-bottom: 30px;
  line-height: 26px;
  @media (max-width: 992px) {
    padding-top: 30px;
    padding-bottom: 15px;
  }
  p {
    color: #707070;
    font-size: 16px;
    padding-bottom: 4px;
    @media (max-width: 992px) {
      font-size: 14px;
    }
  }
`;

export const BannerTitle = styled.div`
  white-space: pre-wrap;
  max-width: 520px;
  p {
    color: #50ebe6;
    font-size: 40px;
    font-weight: bold;
    padding-bottom: 18px;
  }
  @media (max-width: 712px) {
    max-width: 520px;
    p {
      padding-top: 16px;
      font-size: 22px;
      color: #006464;
    }
  }
`;

export const BannerDescription = styled.div`
  max-width: 500px;
  p {
    color: #fff;
    font-size: 16px;
    line-height: 30px;
    u {
      cursor: pointer;
    }

    a.styleDefault {
      color: #fff !important;
    }
  }
  @media (max-width: 712px) {
    max-width: 500px;
    p {
      color: #006464;
      font-size: 14px;
      line-height: 24px;
    }
  }
`;

export const Col = styled.div`
  margin-bottom: 60px;
  & > h2 {
    font-size: 26px !important;
    font-weight: bold !important;
  }
  & > h3 {
    font-size: 20px !important;
    font-weight: bold !important;
  }
  & > p {
    color: #707070;
    margin-top: 20px;
    white-space: pre-wrap;
    &.bold {
      font-weight: bold !important;
    }
    b {
      font-weight: bold !important;
    }
  }
`;

export const ColCard = styled.div`
  background-color: transparent;
`;

export const CardCircleContainer = styled.div`
  max-width: 310px;
  margin-left: auto;
  margin-right: auto;
`;

export const Col2 = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media (max-width: 992px) {
    flex-direction: column;
  }
  button:first-child {
    width: 230px;
  }
  button:last-child {
    width: 242px;
  }
`;

export const Group = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  & > div.content-text-group {
    margin-bottom: 30px;
    & > p {
      font-size: ${props =>
        props.font ? `${props.font} !important;` : '15px !important;'};
      margin-top: 26px;
      b {
        font-weight: bold !important;
      }
    }
  }
`;

export const ImageNegative = styled.div`
  @media (max-width: 992px) {
    margin-top: -155px !important;
  }
`;

export const MiniBanner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #006464;
  padding: 20px;
  border-radius: 10px;
  min-height: 84px;

  p {
    text-align: center;
    line-height: 20px;
    font-size: ${props =>
      props.font ? `${props.font} !important;` : '18px !important;'};
    color: #fff;
    b {
      font-weight: bold !important;
    }
    u {
      font-weight: bold !important;
      cursor: pointer;
    }
  }
`;

export const MonthlyFee = styled.p`
  color: #000 !important;
  text-align: center;
  b {
    font-weight: bold !important;
    font-size: 18px !important;
  }
`;

export const Price = styled.div`
  p {
    color: #000 !important;
    text-align: center;
    b {
      font-weight: bold !important;
      font-size: 18px !important;
      @media (max-width: 992px) {
        font-size: 20px !important;
      }
    }
  }
`;

export const ContainerTable = styled.div`
  margin-top: 25px;
`;

export const breakPoints = [
  { itemsToShow: 3 },
  { itemsToShow: 3 },
  { itemsToShow: 3 },
];

export const breakPointsMobile = [{ itemsToShow: 1 }];

export const Rules = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  & a.styleDefault {
    color: #fff !important;
    font-size: 14px !important;
  }
`;
export const TitleRule = styled.p`
  font-weight: bold;
  font-size: 12px;
`;
