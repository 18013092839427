import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${props => (props.bgColor ? props.bgColor : '#FFF')};
`;

export const Subtitle = styled.h2`
  font-size: 18px !important;
  font-weight: bold;
`;

export const List = styled.ul`
  list-style-type: disc;
  margin-left: 15px;
  color: #707070;
`;

// overrinding BaseFinancialProducts style
export const Paragraph = styled.p`
  font-weight: normal !important;
  padding-top: 0px !important;
  color: #707070 !important;
`;

export const Div = styled.div`
  margin-top: 50px;
`;

export const Link = styled.a`
  color: #0071eb;
  font-weight: bold;
`;
