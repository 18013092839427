import React, { useEffect, useState } from 'react';
import { Image, CardTransparent } from '@midway/web-ui-component';
import {
  cardsAssistance,
  cardsProductsAssistance,
  keyboard,
} from '~/constants';
import format from '~/services/format';
import cmsService from '~/services/cms/pages';

import Error from '~/components/Error';
import Loading from '~/components/Loading';
import history from '~/services/history';

import CardFinancial from '~/components/CardFinancial';
import * as S from './styles';

export default function Assistance() {
  const [tableAssistanceBanner, setTableAssistanceBanner] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const errorTitle = 'Tivemos um problema';
  const errorBody = 'Não foi possível carregar o banner.';

  useEffect(() => {
    setIsError(false);
    setIsLoading(true);

    cmsService
      .getAssistance(process.env.REACT_APP_SITE_CMS_ENV)
      .then(res => {
        const x = JSON.stringify(res, null, '');
        const y = JSON.parse(x);
        const z = JSON.parse(y);
        setTableAssistanceBanner(z);

        setIsLoading(false);
        setIsError(false);
      })
      .catch(error => {
        setIsLoading(false);
        setIsError(true);
        console.log(error);
      });
  }, []);

  const renderBannerContent = () => {
    return (
      <S.BannerContainer className="container-fluid">
        <Image
          src={tableAssistanceBanner.urlBannerImage}
          alt={tableAssistanceBanner.altImage}
          aria-hidden="true"
        />
        <div className="banner-title">
          <p className="is-white-default bold">
            {tableAssistanceBanner.titleBold}
          </p>
          <p className="is-white-default">{tableAssistanceBanner.title}</p>
        </div>
      </S.BannerContainer>
    );
  };

  return (
    <S.Container className="container-fluid" id="ready-page-for-screen-reader">
      {isLoading ? (
        <S.BannerGeneric>
          <Loading />
        </S.BannerGeneric>
      ) : isError ? (
        <S.BannerGeneric>
          <Error errorTitle={errorTitle} errorBody={errorBody} refreshPage />
        </S.BannerGeneric>
      ) : (
        renderBannerContent()
      )}
      <S.Content className="container">
        <S.Session1>
          <h1>Assistências</h1>
          <p>
            Se é pra te dar assistência 24 horas por dia, 7 dias por semana, e
            por um preço que cabe no seu bolso, conte com a gente.
          </p>
        </S.Session1>
        <S.Session2>
          <div className="row">
            {cardsProductsAssistance.map(elm => (
              <S.Col className="col-sm12 col-md-12 col-lg-4 col-xl-4">
                <S.CardCircleContainer>
                  <CardFinancial
                    key={elm.id}
                    title={elm.title}
                    btnAriaLabel={elm.btnAriaLabel}
                    circle={elm.circle}
                    description={elm.text}
                    btnTitle={elm.btnTitle}
                    action={elm.action}
                    photo={elm.photo}
                    showIcon={elm.photo && false}
                    gtmCategory="midway:assistencias"
                    gtmAction="clique:botao"
                    gtmLabel={`clique-aqui:card:${format.normalizeString(
                      elm.title
                    )}`}
                  />
                </S.CardCircleContainer>
              </S.Col>
            ))}
          </div>
        </S.Session2>
      </S.Content>

      <S.Session3 className="space-layout">
        <div className="container carousel-session4">
          <S.Banner>
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6" />
              <S.ResumeComent className="col-sm-12 col-md-12 col-lg-6 col-xl-6`">
                <span>{`Amparo nos\nmomentos que\nvocê mais precisa.`}</span>
              </S.ResumeComent>
            </div>
          </S.Banner>
          <div className="row">
            {cardsAssistance.map((elm, i) => (
              <S.Col
                className={`effectCard col-sm12 col-md-12 col-lg-${
                  i === 3 ? '12' : '4'
                } col-xl-${i === 3 ? '12' : '4'}`}
              >
                <S.CardCircleContainer width="270px">
                  <CardTransparent
                    key={elm.id}
                    title={elm.title}
                    icon={elm.icon}
                    description={elm.text}
                  />
                </S.CardCircleContainer>
              </S.Col>
            ))}
          </div>
        </div>
      </S.Session3>
      <S.FooterCard>
        <span>
          Não achou algum produto que estava procurando?
          <br />
          <u
            onClick={() =>
              history.push('/contratos-de-produtos-descontinuados')
            }
            onKeyPress={event => {
              event.preventDefault();
              const { charCode } = event;
              if (charCode === keyboard.ENTER || charCode === keyboard.SPACE) {
                history.push('/contratos-de-produtos-descontinuados');
              }
            }}
            data-gtm-event-category="midway:seguros"
            data-gtm-event-action="clique:link"
            data-gtm-event-label="link:contratos-de-produtos-descontinuados"
          >
            <u> Acesse a página de produtos descontinuados </u>
          </u>
        </span>
      </S.FooterCard>
    </S.Container>
  );
}
