import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@midway/web-ui-component';
import * as S from './styles';

const InsuranceItem = ({
  top,
  icon,
  title,
  subTitle,
  text,
  otherText,
  ...props
}) => {
  return (
    <S.Insurance top={top} className="row" {...props}>
      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-1 col-xl-1">
            <S.IconCard>
              <S.ShowIcon>
                <Icon icon={icon} size="2x" color="#6ec592" />
              </S.ShowIcon>
            </S.IconCard>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-11 col-xl-11">
            <h2>{title}</h2>
            <p>{subTitle}</p>
          </div>
        </div>
        {otherText && <h3 className="font-weight-bold">{otherText}</h3>}
        <span>{text}</span>
      </div>
    </S.Insurance>
  );
};

InsuranceItem.propTypes = {
  top: PropTypes.string,
  icon: PropTypes.any,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  text: PropTypes.string,
};

InsuranceItem.defaultProps = {
  top: `70px`,
  icon: ['mid', 'personal-loans'],
  title: ``,
  subTitle: '',
  text: '',
};

export default InsuranceItem;
