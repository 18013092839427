import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { first, includes, isEmpty, kebabCase, keys, values } from 'lodash';
import useDynamics from '~/hooks/useDynamicsV2';
import { useNegotiation } from '~/context/negotiationV2';

import { tagMiAjudaView } from '~/services/tagging';
import RulesForTags from '~/pages/NewNegotiationCampaign/components/Flow/Elegibility/RulesForTags';
import Tag from '~/pages/NewNegotiationCampaign/components/Tag';
import format from '~/services/format';
import useIsMobile from '~/hooks/useIsMobile';
import { ContainerProposals, Content, Others } from './styles';
import DayOffer from '../../../DayOffer';
import ProposalFactory from './ProposalFactory';

function Proposals() {
  const { toogles } = useSelector(state => state.featuretoggle);
  const [sendDynamics] = useDynamics();
  const isMobile = useIsMobile();
  const {
    negotiationData,
    setNegotiationData,
    negotiable,
    system,
    sendedTagView,
    setSendedTagView,
    clientData,
    tokenDynamics,
    hideAgreement,
  } = useNegotiation();
  const { eligibility } = negotiationData;
  const features = negotiable[system];

  const proposals = keys(eligibility)
    .filter(key => features.includes(key) && !isEmpty(eligibility[key]))
    .reduce((obj, key) => {
      obj[key] = eligibility[key];
      return obj;
    }, {});

  let onlyInvoicement;

  const isAgreementEntryValueHigherThanTotalWithDiscount = () => {
    const agreementData =
      first(negotiationData?.eligibility?.agreement) ??
      negotiationData?.eligibility?.agreement;
    const totalDiscount = agreementData.discount?.maxTotal || 0;
    const { totalValue, minEntryValue } = agreementData;

    const totalValueWithDiscount = totalValue - totalDiscount;

    return minEntryValue > totalValueWithDiscount;
  };

  // Valida as algumas regras de ordem de exibicão das propostas de acordo com as disponíveis
  const getRulesProposals = () => {
    let hasInvoicement = false;
    let hasAcquittance = false;
    let hasDebit = false;
    let hasAgreement = false;

    const newOrder = ['invoicement', 'debit', 'acquittance', 'agreement'];
    const newOrderwithoutAcquittance = ['invoicement', 'debit', 'agreement'];
    const newOrderOnlyDebitAgreement = ['debit', 'agreement'];
    const newOrderOnlyInvoicementDebit = ['invoicement', 'debit'];
    const newOrderOnlyInvoicement = ['invoicement'];
    const newOrderOnlyAcquittanceAgreement = ['acquittance', 'agreement'];

    keys(proposals).forEach(proposal => {
      if (proposal === 'invoicement') hasInvoicement = true;
      if (proposal === 'acquittance') hasAcquittance = true;
      if (proposal === 'debit') hasDebit = true;
      if (proposal === 'agreement') {
        if (isAgreementEntryValueHigherThanTotalWithDiscount()) {
          delete proposals.agreement;
        } else {
          hasAgreement = true;
        }
      }
    });

    if (hasInvoicement && !hasAcquittance && !hasDebit && !hasAgreement) {
      onlyInvoicement = true;
    }

    if (system === 'SICC') {
      if (
        negotiationData.overDueDays > 77 &&
        negotiationData.overDueDays < 180
      ) {
        return keys(proposals).filter(proposal =>
          includes(newOrderOnlyDebitAgreement, proposal)
        );
      }
      if (negotiationData.overDueDays > 180)
        return keys(proposals).filter(proposal =>
          includes(newOrderOnlyAcquittanceAgreement, proposal)
        );
    }

    if (negotiationData?.overDueDays <= 0 && onlyInvoicement) {
      return keys(proposals).filter(proposal =>
        includes(newOrderOnlyInvoicement, proposal)
      );
    }

    if (hasInvoicement) {
      if (toogles.isEnabledOnly2Proposals) {
        if (keys(proposals).length >= 2 && system === 'SICC') {
          if (hasInvoicement) {
            return newOrderOnlyInvoicementDebit;
          }
          return newOrderOnlyDebitAgreement;
        }
      }
      return keys(proposals).sort((a, b) => {
        return newOrder.indexOf(a) - newOrder.indexOf(b);
      });
    }

    if (hasAcquittance && hasDebit) {
      return keys(proposals).filter(proposal =>
        includes(newOrderwithoutAcquittance, proposal)
      );
    }

    return keys(proposals);
  };

  const rules = getRulesProposals();

  useEffect(() => {
    if (!sendedTagView) {
      tagMiAjudaView(
        kebabCase(
          `${system} ${values(rules)} ${
            negotiationData?.overDueDays <= 0 ? 'em dia' : ''
          }`
        )
      );
      // Envia a o evento de início do fluxo de negociação para o dynamics
      sendDynamics({
        event: 'iniciar',
        client: clientData?.document,
        token: tokenDynamics,
      });
    }

    setSendedTagView(true);
  }, [
    clientData.document,
    negotiationData.overDueDays,
    rules,
    sendDynamics,
    sendedTagView,
    setSendedTagView,
    system,
    tokenDynamics,
  ]);

  useEffect(() => {
    setNegotiationData({ ...negotiationData, onlyInvoicement });
    setSendedTagView(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ContainerProposals>
        <Content>
          <span className="bold">Melhor proposta para você</span>
          <DayOffer green />
        </Content>
        {rules?.map((proposalKey, index) => {
          const proposal =
            first(proposals[proposalKey]) || proposals[proposalKey];

          if (isEmpty(proposal)) return <div key={proposalKey} />;
          return (
            <div key={proposalKey}>
              <ProposalFactory data={proposal} typeElegibily={proposalKey} />
              {index === 0 && (
                <>
                  <RulesForTags mtoplast={!!proposal.discount && isMobile}>
                    {proposal.discount && proposal?.discount?.maxTotal > 0 && (
                      <Tag
                        text={`${format.currency(
                          proposal?.discount?.maxTotal
                        )} de desconto`}
                      />
                    )}
                  </RulesForTags>
                </>
              )}
              {!(index === 0 && !hideAgreement) ||
                (index === 0 && rules?.length > 1 && (
                  <Others className="bold">Outras opções:</Others>
                ))}
            </div>
          );
        })}
      </ContainerProposals>
    </>
  );
}

export default Proposals;
