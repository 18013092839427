import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';

export default function Card({
  title,
  content,
  children,
  headerFontSize,
  contentFontSize,
  ...props
}) {
  return (
    <S.Card
      {...props}
      headerFontSize={headerFontSize}
      contentFontSize={contentFontSize}
    >
      <div className="card-header">
        <p>{title}</p>
      </div>
      <div className="card-body">{children || <span>{content}</span>}</div>
    </S.Card>
  );
}

Card.defaultProps = {
  children: undefined,
  headerFontSize: '32px',
  contentFontSize: '20px',
};

Card.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  children: PropTypes.node,
  headerFontSize: PropTypes.string,
  contentFontSize: PropTypes.string,
};
