import styled from 'styled-components';
import FemaleModel from '~/assets/img/card-credit/female-model.png';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const ContainerGlobal = styled.div`
  max-width: 2000px;
  width: 100%;
  margin: 0 auto;
`;

export const ModalStyle = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  background-image: url(${FemaleModel});
  background-position: left bottom;
  background-repeat: no-repeat;
  background-size: auto 100%;

  @media (max-width: 1000px) {
    flex-direction: column;
    overflow: scroll;
    background-image: none;
  }
`;

export const LeftModal = styled.div`
  width: 45%;
  display: flex;
  align-items: end;
  justify-content: end;

  @media (max-width: 1000px) {
    display: none;
  }
`;

export const LeftImageModal = styled.img`
  width: 100%;
`;

export const RightModal = styled.div`
  width: 55%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-right: 10px;

  @media (max-width: 1000px) {
    width: 90%;
  }
`;

export const TitleModal = styled.p`
  font-size: 30px;
  font-weight: 800;
  margin-top: 60px;
  font-family: Montserrat;

  @media (max-width: 1000px) {
    font-size: 24px;
    margin-top: 30px;
  }
`;

export const TextModal = styled.p`
  font-size: 25px;
  padding-bottom: 20px;
  font-family: Montserrat;

  @media (max-width: 1000px) {
    font-size: 15px;
  }
`;

export const AdjustamentRightModal = styled.div`
  display: flex;
  align-items: start;
  justify-content: start;
  flex-direction: column;
`;

export const CentralizeCardsModal = styled.div`
  @media (max-width: 1000px) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
`;

export const ModalOptions = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 25px;

  @media (max-width: 1000px) {
    margin-bottom: 20px;
  }
`;

export const ContainerBlockModal = styled.div`
  width: 50%;
  display: flex;
  padding-bottom: 5px;

  @media (max-width: 650px) {
    width: 100%;
  }
`;

export const CardModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  @media (max-width: 1000px) {
  }
`;

export const IconModal = styled.img`
  max-width: 50px;
  margin-right: 14px;

  @media (max-width: 800px) {
    max-width: 28px;
  }
`;

export const CentralizeTextModal = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 24px;
`;

export const DescriptionModal = styled.p`
  font-size: 13px;
  font-weight: 600;
  font-family: Montserrat;

  @media (max-width: 800px) {
    font-size: 12px;
  }
`;

export const ObservationModal = styled.p`
  font-size: 12px;
  font-weight: 500;
  font-family: Montserrat;

  @media (max-width: 800px) {
    font-size: 10px;
  }
`;

export const TextfinalModal = styled.p`
  font-size: 20px;
  font-weight: 600;

  @media (max-width: 1000px) {
    font-size: 14px;
  }
`;

export const QRCodeImage = styled.img`
  max-width: 190px;
  margin-bottom: 10px;

  @media (max-width: 1000px) {
    display: none;
  }
`;

export const ModalButton = styled.a`
  background-color: #000;
  padding: 10px;
  width: 80%;
  border-radius: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin-bottom: 20px;
  margin-top: 20px;
  text-align: center;

  @media (min-width: 1000px) {
    display: none;
  }
`;

export const BannerContainer = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 40px;
`;

export const BannerDesktop = styled.img`
  width: 100%;
  cursor: pointer;
  object-fit: cover;

  @media (max-width: 800px) {
    display: none;
  }
`;

export const BannerMobile = styled.img`
  width: 100%;
  object-fit: cover;
  cursor: pointer;

  @media (min-width: 800px) {
    display: none;
  }
`;

export const BlockCard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const CentrilizeBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  @media (max-width: 1000px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const AdjustamentRight = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
`;

export const LeftSection = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1000px) {
    width: 100%;
  }
`;

export const LeftImage = styled.img`
  width: 60%;
`;

export const RightSection = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 1000px) {
    width: 80%;
    margin-top: 50px;
    align-items: start;
  }
`;

export const RightTitle = styled.p`
  font-family: Montserrat;
  font-size: 30px;
  font-weight: bold;
  padding-bottom: 30px;
  text-align: left;
`;

export const AdjustamentRightText = styled.div`
  width: 60%;
`;

export const RightText = styled.p`
  font-family: Montserrat;
  font-size: 20px;
  padding-bottom: 16px;
  text-align: left;

  @media (max-width: 1000px) {
    width: 100%;
    font-size: 17px;
  }
`;

export const RightButton = styled.button`
  background-color: #000;
  width: 60%;
  height: 48px;
  border-radius: 120px;
  border: 0px;
  transition: backgroud-color 0.5s ease;
  cursor: pointer;
  color: #fff;
  margin-bottom: 20px;
  margin-top: 20px;

  &:hover {
    background-color: #222;
  }

  @media (max-width: 1000px) {
    width: 95%;
  }
`;

export const StyleAnchor = styled.a`
  color: #4e4b59;
  margin-top: 20px;
  text-decoration: underline;
  font-family: Montserrat;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    color: inherit;
  }
`;

export const TextBold = styled.span`
  font-size: ${props => props.fontSize || '16px'};
  font-weight: bold;
  display: contents;
`;

export const TitleTable = styled.p`
  font-size: 40px;
  font-weight: bold;
  margin-top: 100px;
  padding-bottom: 40px;

  @media (max-width: 1000px) {
    width: 90%;
    font-size: 25px;
    margin-top: 50px;
  }
`;

export const ContainerTable = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 120px;

  @media (max-width: 1000px) {
    margin-bottom: 80px;
  }
`;

export const NoCheck = styled.div`
  color: red;
  background-color: #fbeaed;
  font-size: 30px;
  width: 60px;
  height: 60px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1000px) {
    width: 40px;
    height: 40px;
  }
`;

export const Check = styled.img`
  color: red;
  background-color: #edf9ec;
  width: 60px;
  height: 60px;
  border-radius: 15px;

  @media (max-width: 1000px) {
    width: 40px;
    height: 40px;
  }
`;
export const CentralizePanel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const InformationPanel = styled.div`
  background-color: #f1f0f5;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 70px;
  width: 80%;

  @media (max-width: 1000px) {
    flex-direction: column;
    width: 90%;
  }
`;

export const LeftPanel = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1000px) {
    width: 100%;
  }
`;

export const LeftImagePanel = styled.img`
  width: 60%;
  margin-top: -30px;
  margin-bottom: -120px;
`;

export const RightPanel = styled.div`
  width: 50%;
  display: flex;
  align-items: start;
  justify-content: start;
  flex-direction: column;

  @media (max-width: 1000px) {
    width: 100%;
    margin-top: 150px;
    margin-bottom: 100px;
  }
`;

export const CentralizeRightPanel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const RchloLogo = styled.div`
  width: 110px;
  height: 60px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  color: white;
  margin-bottom: 30px;
  font-weight: bold;
  font-size: 20px;
`;

export const TextPanel = styled.p`
  font-size: ${props => props.fontSize || '16px'};
  color: #1f2b2a;
  font-family: Montserrat;
  padding-bottom: 10px;
  display: flex;
  text-align: center;
  width: 50%;

  @media (max-width: 1000px) {
  }
`;

export const AdjustamentButton = styled.div`
  margin-top: 20px;
`;
