import React, { useEffect, useState } from 'react';
import { Pagination } from 'antd';
import searchIcon from '~/assets/img/GuaranteedLimitLP/search.svg';
import { StoreDetailsModal } from '../StoreDetailsModal';

import {
  Container,
  Content,
  Header,
  Title,
  Text,
  SearchStore,
  Input,
  SearchIcon,
  HeaderContainer,
  TableContainer,
  Table,
  Row,
  Span,
  TableHead,
  TableBody,
  Head,
  PaginationContainer,
} from './styles';
import { storesData } from './storesData';

const PAGINATION_OFFSET = 1;
const PAGE_SIZE = 10;

export const Stores = ({ isMobile }) => {
  const [searchStore, setSearchStore] = useState('');
  const [filteredStores, setFilteredStores] = useState(storesData);
  const [showStoreDetailsModal, setShowStoreDetailsModal] = useState(false);
  const [selectStore, setSelectStore] = useState(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const [isInputFocused, setIsInputFocused] = useState(false);

  useEffect(() => {
    if (searchStore === '') {
      setFilteredStores(storesData);
    }
  }, [searchStore]);

  const handleInputChange = e => {
    setSearchStore(e?.target?.value);

    setFilteredStores(
      storesData.filter(
        item =>
          item?.store?.toLowerCase()?.includes(searchStore?.toLowerCase()) ||
          item?.address?.toLowerCase()?.includes(searchStore?.toLowerCase()) ||
          item?.state?.toLowerCase()?.includes(searchStore?.toLowerCase())
      )
    );
  };

  const handleSelectStore = id => {
    setShowStoreDetailsModal(true);

    const storeSelected = storesData.find(store => store.id === id);

    setSelectStore(storeSelected);
  };

  const handleCloseDetailsModal = () => {
    setShowStoreDetailsModal(false);
    setSelectStore(undefined);
  };

  useEffect(() => {
    const start = (currentPage - PAGINATION_OFFSET) * PAGE_SIZE;
    const end = start + PAGE_SIZE;
    setFilteredStores(storesData.slice(start, end));
  }, [currentPage]);

  const handleChangePageView = page => {
    setCurrentPage(page);
  };

  const handleInputFocus = () => {
    setIsInputFocused(true);
  };

  const handleInputBlur = () => {
    setIsInputFocused(false);
  };

  let placeholder = isMobile ? 'Buscar' : 'Buscar loja, cidade ou estado';
  if (isInputFocused) {
    placeholder = '';
  }

  return (
    <Container id="lojas-participantes">
      <Content>
        {showStoreDetailsModal && (
          <StoreDetailsModal
            store={selectStore}
            onClose={handleCloseDetailsModal}
          />
        )}
        <HeaderContainer>
          <Header>
            <Title>Peça já o seu Limite Garantido!</Title>

            <Text>
              Visite uma das lojas participantes e aproveite todos os benefícios
              do Cartão Riachuelo e Conta Digital Midway:
            </Text>
          </Header>

          <SearchStore focused={isInputFocused}>
            <Input
              placeholder={placeholder}
              value={searchStore}
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
            />

            <SearchIcon src={searchIcon} />
          </SearchStore>
        </HeaderContainer>
        <TableContainer>
          <Table>
            <TableHead>
              <tr>
                <Head>Loja</Head>
                <Head>Endereço</Head>
                {!isMobile && (
                  <>
                    <Head>Estado</Head>
                    <Head>Telefone</Head>
                  </>
                )}
              </tr>
            </TableHead>

            <TableBody>
              {filteredStores.map(store => (
                <Row key={store.id}>
                  <td>{store.store}</td>
                  {!isMobile && (
                    <>
                      <td>{store.address}</td>
                      <td>{store.state}</td>
                      <td>
                        <a href={`tel://${store.phone}`}>{store.phone}</a>
                      </td>
                    </>
                  )}

                  {isMobile && (
                    <td onClick={() => handleSelectStore(store.id)}>
                      <Span>Consultar</Span>
                    </td>
                  )}
                </Row>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <PaginationContainer>
          <Pagination
            pageSize={PAGE_SIZE}
            current={currentPage}
            total={storesData.length}
            onChange={handleChangePageView}
          />
        </PaginationContainer>
      </Content>
    </Container>
  );
};
