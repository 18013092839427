import React from 'react';
import { Breadcrumb } from '@midway/web-ui-component';

import history from '~/services/history';
import BaseFinancialProducts from '~/components/_layout/BaseFinancialProducts';
import * as S from './styles';

export default function AssistanceCar24h() {
  return (
    <S.Container
      id="ready-page-for-screen-reader"
      aria-label="Página de Assistência Automóvel carregada"
    >
      <Breadcrumb
        routeBack="/produtos-financeiros"
        textRouteBack="Produtos Financeiros"
        actualPageText="Assistência 24h Automóvel"
        history={history}
      />
      <BaseFinancialProducts
        pageName="Assistência 24h Automóvel"
        session1={
          <>
            <h1>Assistência 24h Automóvel</h1>
            <S.Subtitle>
              Contratando a Assistência 24h Automóvel você fica livre de
              preocupação em casos de:
            </S.Subtitle>
            <S.List>
              <li>Socorro Elétrico e/ou Mecânico;</li>
              <li>Guincho;</li>
              <li>Troca de Pneu;</li>
              <li>Auxílio na falta de combustível;</li>
              <li>Motorista Seguro;</li>
              <li>Carro Reserva;</li>
              <li>Motorista Substituto e outros 13 serviços</li>
            </S.List>
          </>
        }
        featured1={`Proteção e amparo por\napenas R$ 18,90 mensais`}
        featured2={`Um dia seu carro pode\ndeixar você na mão.\nA gente nunca.`}
        session21={
          <S.Div className="divider">
            <S.Subtitle>
              Assistência 24 horas por dia/ 7 dias por semana:
            </S.Subtitle>
            <S.Paragraph>
              Não importa quando e onde, se o seu automóvel falhar, pode contar
              com a gente pra te amparar.
            </S.Paragraph>
            <br />
            <S.Subtitle>Para utilizar os serviços:</S.Subtitle>
            <S.Paragraph>
              Fale com a CDF
              <br />
              0800 704 5025
            </S.Paragraph>
          </S.Div>
        }
        /*
         */

        session4={
          <>
            <p>
              A Assistência é prestada pela CDF Assistências Ltda inscrita no
              CNPJ: 52.570.231/0001-34.
            </p>
            <p>
              Restrições se aplicam. Mais informações consulte as condições
              gerais, o regulamento e as características essenciais no site:{' '}
              <S.Link
                href="https://www.midway.com.br"
                rel="noopener noreferrer"
                target="_blank"
              >
                https://www.midway.com.br
              </S.Link>
              . Vigência inicia-se a partir do dia seguinte a realização do
              pagamento.
            </p>
          </>
        }
        colorCircle="#f17eb1"
        links={[
          {
            link: '/contrato/CG-ASSISTENCIA-AUTO-24HS-MEGA',
            text: 'Condições Gerais da Assistência 24 HS Automóvel Mega (Comercializado a partir de 09 de agosto de 2019)',
          },
          {
            link: '/contrato/CG-ASSISTENCIA-AUTO-24HS-SUPER',
            text: 'Condições Gerais da Assistência 24 HS Automóvel  Super (Comercializado a partir de 09 de agosto de 2019)',
          },
        ]}
      />
    </S.Container>
  );
}
