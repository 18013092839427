import React from 'react';
import * as S from './styles';
import Accordion from '../AccordionServicePortal';
import TextPhoneNumber from '../TextPhoneNumber';

const GridCards = () => {
  const midwayCentral = () => (
    <>
      <S.ContentChildren>
        <S.TextBlock>De segunda a domingo, das 7h às 20h.</S.TextBlock>
        <S.Line />
        <TextPhoneNumber phoneNumber="3003 7729" />
        <S.TextBlock>Capitais e Regiões Metropolitanas</S.TextBlock>
        <S.TitleBlock>0800 664 3929</S.TitleBlock>
        <S.TextBlock>Demais localidades</S.TextBlock>
        <TextPhoneNumber phoneNumber="0800 724 3929" />
        <S.TextBlock>SAC 24h</S.TextBlock>
        <S.TextBlock>
          Para clientes no exterior, entrar em contato através do canal:{' '}
          <S.TextBold>atendimento@midway.com.br</S.TextBold>
        </S.TextBlock>
      </S.ContentChildren>
    </>
  );

  const purcheses = () => (
    <>
      <S.ContentChildren>
        <S.TextBlock>
          De segunda a sábado, das 8h às 18h (exceto feriados nacionais).
        </S.TextBlock>
        <S.Line />
        <TextPhoneNumber phoneNumber="4003 0515" />
        <S.TextBlock>Capitais e Regiões Metropolitanas</S.TextBlock>
        <TextPhoneNumber phoneNumber="0800 729 0515" />
        <S.TextBlock>Demais localidades</S.TextBlock>
        <TextPhoneNumber phoneNumber="0800 722 0626" />
        <S.TextBlock>Atendimento 24h para Deficientes Auditivos</S.TextBlock>
      </S.ContentChildren>
    </>
  );

  const physicalStores = () => (
    <>
      <S.ContentChildren>
        <S.TextBlock>De segunda a sábado, das 8h às 22h.</S.TextBlock>
        <S.Line />
        <TextPhoneNumber phoneNumber="0800 721 4033" />
        <S.TextBlock>Atendimento</S.TextBlock>
        <TextPhoneNumber phoneNumber="0800 722 0626" />
        <S.TextBlock>Atendimento 24h para Deficientes Auditivos</S.TextBlock>
      </S.ContentChildren>
    </>
  );

  const negotiateDebts = () => (
    <>
      <S.ContentChildren>
        <S.Line />
        <TextPhoneNumber phoneNumber="(11) 3003-0950" />
        <S.TextBlock>
          Adicione o número em sua lista de contatos e negocie sua dívida via
          WhatsApp
        </S.TextBlock>
      </S.ContentChildren>
    </>
  );

  const sac = () => (
    <>
      <S.ContentChildren>
        <S.TextBlock>
          {' '}
          De segunda à sexta, das 10h às 16h (exceto feriados){' '}
        </S.TextBlock>
        <S.Line />
        <TextPhoneNumber phoneNumber="3003 5417" />
        <S.TextBlock>Capitais e Regiões Metropolitanas</S.TextBlock>
        <TextPhoneNumber phoneNumber="0800 727 4417" />
        <S.TextBlock>Demais localidades</S.TextBlock>
      </S.ContentChildren>
    </>
  );

  const cardRiachuelo = () => (
    <>
      <S.ContentChildren>
        <S.TextBlock>De segunda a Domingo, das 07h às 22h.</S.TextBlock>
        <S.Line />
        <TextPhoneNumber phoneNumber="3004 5417" />
        <S.TextBlock>Capitais e Regiões Metropolitanas</S.TextBlock>
        <TextPhoneNumber phoneNumber="0800 727 4417" />
        <S.TextBlock>Demais localidades</S.TextBlock>
        <TextPhoneNumber phoneNumber="0800 721 3344" />
        <S.TextBlock>SAC 24h</S.TextBlock>
        <TextPhoneNumber phoneNumber="0800 722 0626" />
        <S.TextBlock>Atendimento 24h para Deficientes Auditivos</S.TextBlock>
      </S.ContentChildren>
    </>
  );

  return (
    <>
      <S.ContainerAccordions>
        <S.ContentLeft>
          <Accordion
            title="Atendimento Cartão Riachuelo"
            children={cardRiachuelo()}
          />
          <Accordion
            title="Whatsapp de Negociações"
            children={negotiateDebts()}
          />
          <Accordion title="Ouvidoria" children={sac()} />
        </S.ContentLeft>

        <S.ContentRight>
          <Accordion title="Atendimento Loja Online" children={purcheses()} />
          <Accordion
            title="Atendimento Conta Digital"
            children={midwayCentral()}
          />
          <Accordion title="Lojas físicas" children={physicalStores()} />
        </S.ContentRight>
      </S.ContainerAccordions>
    </>
  );
};
export default GridCards;
