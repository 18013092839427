import React, { useEffect, useRef } from 'react';
import { lowerCase } from 'lodash';
import Success from '~/assets/img/NegotiationCampaign/success.png';
import { useNegotiation } from '~/context/negotiationV2';
import { gtagViewContent } from '~/utils/TaggingGA4';
import DirectDebit from './DirectDebit';
import Billet from './Billet';
import Pix from './Pix';
import { Container, ContainerTitle } from './styles';
import ContainerFadeIn from '../../ContainerFadeIn';

const GTAG_CONTENT_PAGE = 'tela-de-comprovante';
const GTAG_FLOW = 'miajuda';

function Registered() {
  const myRef = useRef(null);
  const {
    system,
    typeNegotiationRegistered,
    paymentMethodSelected,
    negotiationData,
    type,
  } = useNegotiation();

  const negotiable = negotiationData?.eligibility[typeNegotiationRegistered];

  const installment = negotiable?.simulation?.selectedInstallmentData;

  const isDebit =
    typeNegotiationRegistered === 'debit' ||
    typeNegotiationRegistered === 'acquittance';

  const getAgreementType = () => {
    if (system === 'TOPAZ')
      return `Acordo ${negotiationData?.registered?.agreementNumber}`;
    if (
      typeNegotiationRegistered === 'debit' ||
      typeNegotiationRegistered === 'acquittance'
    )
      return 'Boleto';
    if (typeNegotiationRegistered === 'agreement') return 'Boleto';
    if (typeNegotiationRegistered === 'invoicement') return 'Parcelamento';
  };

  const ga4Params = () => {
    if (isDebit) {
      return {
        valor: negotiationData?.registered?.amount,
        tipo_proposta: typeNegotiationRegistered,
        tipo_cartao: type,
        forma_pagamento: lowerCase(paymentMethodSelected),
        sistema: lowerCase(system),
        proposta: negotiationData?.gaParamProposta,
        contrato: negotiationData?.selectedContract?.contract,
      };
    }
    return {
      valor_das_parcelas: installment?.installmentAmount,
      numero_de_parcelas: installment?.installmentQuantity,
      valor_de_entrada: negotiationData?.registered?.amount,
      tipo_proposta: typeNegotiationRegistered,
      tipo_cartao: type,
      forma_pagamento: lowerCase(paymentMethodSelected),
      sistema: lowerCase(system),
      proposta: negotiationData?.gaParamProposta,
      contrato: negotiationData?.selectedContract?.contract,
    };
  };

  useEffect(() => {
    gtagViewContent(GTAG_CONTENT_PAGE, GTAG_FLOW, ga4Params());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ContainerFadeIn>
      <Container ref={myRef}>
        <ContainerTitle
          style={{
            display: `${paymentMethodSelected === 'PIX' ? 'none' : ''}`,
          }}
        >
          <img src={Success} alt="Imagem de sucesso" />
          <p>
            <strong>{getAgreementType()} gerado </strong>com sucesso!
          </p>
        </ContainerTitle>
        {paymentMethodSelected === 'DEBIT_ACCOUNT' ? (
          <DirectDebit />
        ) : paymentMethodSelected === 'PIX' ? (
          <Pix />
        ) : (
          <Billet />
        )}
      </Container>
    </ContainerFadeIn>
  );
}

export default Registered;
