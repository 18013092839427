import styled from 'styled-components';

export const Container = styled.div`
max-width: 1260px;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;

@media screen and (max-width: 1000px) {
  width: 100% !important;
  max-width: 100%;
`;

export const Content = styled.div`
  display: flex;
  align-items: left-start;
  justify-content: left-start;
  flex-direction: column;
  margin-top: 64px;
  width: 100%;
  max-width: 1276px;
`;
export const TitleContainer = styled.h1`
  font-family: Montserrat !important;
  font-size: 24px !important;
  font-weight: 700;
  line-height: 28px;
  text-align: left;
  color: #1f2b2a;
`;
export const Line = styled.div`
  width: 247px;
  border: 2px solid #a06d2c;
  margin-top: 10px;
`;
export const ContentCards = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: left-start;
  justify-content: left-start;
  flex-direction: row;
  margin-top: 48px;
`;
export const Cards = styled.div`
  max-width: 376px;
  min-width: 328px;
  min-height: 414px;
  margin: 0 24px 32px 0;
  border-radius: 16px;
  background-color: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

  @media (max-width: 1000px) {
    width: 100%;
    max-width: 100%;
  }
`;

export const Img = styled.img`
  width: 100%;
`;
export const GroupsContent = styled.div`
  padding: 24px;

  @media (max-width: 1000px) {
    padding: 16px;
  }
`;
export const Title = styled.p`
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  text-align: left;
  color: #221f20;
  @media (max-width: 1000px) {
    font-size: 18px;
  }
`;

export const SubTitle = styled.p`
  margin: 8px 0px 16px 0px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #221f20;
  @media (max-width: 1000px) {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  }
`;
export const LineCards = styled.div`
  width: 37px;
  border: 1px solid #a06d2c;
  margin-top: 16px;
`;

export const Description = styled.p`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  margin-top: 24px;
`;

export const Button = styled.a`
  width: 100%;
  padding: 16px;
  border-radius: 50px;
  font-family: Montserrat;
  font-size: 14px !important;
  font-weight: 500;
  background-color: #000;
  border: 0px;
  transition: backgroud-color 0.5s ease;
  cursor: pointer;
  color: #fff !important;
  margin-bottom: 20px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  &:hover {
    background-color: #222;
  }
`;

export const Teste = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;
