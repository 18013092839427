import React from 'react';
import PropTypes from 'prop-types';
import { generate } from 'shortid';
import TableBoostrap from 'react-bootstrap/Table';
import * as S from './styles';

export default function TableInsurance({ columns, aria, ariaCell, data }) {
  return (
    <S.Container>
      <TableBoostrap bordered responsive striped>
        <thead>
          <tr aria-label={aria}>
            {columns.map(column => (
              <th key={generate()} className="text-wrap">
                {column}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map(item => (
            <tr
              aria-label={`${ariaCell} ${item.value},
              ${columns[1]}, ${item.plan1}.
              ${columns[2]}, ${item.plan2}.
              ${columns[3]}, ${item.plan3}`}
              key={item.id}
            >
              <td>{item.value}</td>
              <td>{item.plan1}</td>
              <td>{item.plan2}</td>
              <td>{item.plan3}</td>
            </tr>
          ))}
        </tbody>
      </TableBoostrap>
    </S.Container>
  );
}

TableInsurance.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.any).isRequired,
  aria: PropTypes.string.isRequired,
  ariaCell: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
};
