import React from 'react';
import history from '~/services/history';
import UserBannerBandeira1 from '~/assets/img/cartoes-bandeira/user-banner-1.png';
import UserBannerBandeira2 from '~/assets/img/cartoes-bandeira/user-banner-2.png';
import UserBannerBandeira4 from '~/assets/img/cartoes-bandeira/user-banner-4.png';
import UserBannerBandeira5 from '~/assets/img/cartoes-bandeira/user-banner-5.png';
import UserBannerBandeira6 from '~/assets/img/cartoes-bandeira/user-banner-6.png';
import UserBannerBandeira7 from '~/assets/img/cartoes-bandeira/user-banner-7.png';

import circleEmprestimos from '~/assets/img/Financial/lending/icone-emprestimos.png';
import circleGarantia from '~/assets/img/Financial/lending/icone-garantia.png';
import circleTaxas from '~/assets/img/Financial/lending/icone-taxas.png';
import FotoContrato1 from '~/assets/img/Financial/lending/foto-contrato1.png';
import FotoContrato2 from '~/assets/img/Financial/lending/foto-contrato2.png';
import FotoContrato3 from '~/assets/img/Financial/lending/foto-contrato3.png';
import appleStoreIcon from '~/assets/img/apple-store.png';
import googlePlayIcon from '~/assets/img/google-play.png';

import Condicoes from '~/assets/img/NegotiationCampaign/landing-page/icon-condicoes.png';
import Incomodo from '~/assets/img/NegotiationCampaign/landing-page/icon-incomodo.png';
import SobMedida from '~/assets/img/NegotiationCampaign/landing-page/icon-medida.png';
import Burocracia from '~/assets/img/NegotiationCampaign/landing-page/icon-burocracia.png';
import Step1 from '~/assets/img/NegotiationCampaign/Home/step1.png';
import Step2 from '~/assets/img/NegotiationCampaign/Home/step2.png';
import Step3 from '~/assets/img/NegotiationCampaign/Home/step3.png';
import Step4 from '~/assets/img/NegotiationCampaign/Home/step4.png';
import HIW1 from '~/assets/img/NegotiationCampaign/Home/hiw1.png';
import HIW2 from '~/assets/img/NegotiationCampaign/Home/hiw2.png';
import HIW3 from '~/assets/img/NegotiationCampaign/Home/hiw3.png';

export * from './contractsPath';

export const colors = {
  primary: '#006464',
  secondary: '#50EBE6',
  tertiary: '#9BFFFF',
  info: '#103260',
  success: '#005F2D',
  light_green: '#A5DF9F',
  dark_green: '#053D00',
  warning: '#FFDD57',
  danger: '#FF001A',
  error: '#9C162D',
  dark: '#212121',
  black: '#000',
  breadcrumb_dark: '#111111',
  white: '#FFFFFF',
  light_gray: '#f7f7f7',
  dark_gray: '#959D9D',
  dark_gray_2: '#CACECD',
  dark_gray_3: '#616B6A',
  gray: '#e6e6e6',
  darken: '#252525',
  light: '#FFFFFF',
  gradient: 'linear-gradient(65deg, #006464 0%, #50EBE6 100%)',
  light_brown: '#FFE7A8',
  dark_brown: '#704810',
  cian: '#9EFAF6',
  chumbo: '#221F20',
  offWhite: '#f1ebdf',
  orangeRoof: '#E4602F',
  gray800: '#1f2b2a',
};

export const tableAppDifferences = [
  {
    title: '',
    summary: '',
    obs: '',
    table: [
      {
        th: ['Informações', 'App Midway', `App Riachuelo`],
        td: [
          ['Consulta/Pagamento da fatura do cartão riachuelo', 'sim', 'sim'],
          ['Consulta/Contratação/Pagamento de Empréstimo', 'sim', 'não'],
          ['Conta Digital grátis com rendimento CDI', 'sim', 'não'],
          ['Transferência Doc/Ted e Pix (em breve)', 'sim', 'não'],
          ['Consulta/Contratação de Seguros e Assistências', 'sim', 'não'],
          ['Compra online e acompanhar pedidos', 'sim', 'não'],
        ],
      },
    ],
  },
];

export const keyboard = {
  ENTER: 13,
  SPACE: 32,
  TAB: 9,
};

export const menuItems = [
  {
    title: 'Midway',
    action: () => history.push('/midway'),
    href: '#elm-home',
    tabIndex: 2,
    subTitles: [
      {
        title: 'Nossa história',
        action: () => {
          history.push('/midway#history');
        },
        href: '#elm-history',
        gtmCategory: 'midway:geral',
        gtmAction: 'clique:menu-e-sub-menu',
        gtmLabel: 'Nossa história',
      },
    ],
    gtmCategory: 'midway:geral',
    gtmAction: 'clique:menu-e-sub-menu',
    gtmLabel: 'Midway',
  },
  {
    title: 'Cartões',
    action: () => history.push('/cartoes'),
    href: '#elm-cartoes',
    tabIndex: 3,
    subTitles: [
      {
        title: 'RCHLO',
        ariaLabel: 'Riachuelo',
        action: () => {
          history.push('/cartoes#rchlo-pl');
        },
        href: '#elm-rchlo-pl',
        gtmCategory: 'midway:geral',
        gtmAction: 'clique:menu-e-sub-menu',
        gtmLabel: 'Riachuelo',
      },
      {
        title: 'Mastercard',
        action: () => {
          history.push('/cartoes#rchlo-credit');
        },
        href: '#elm-rchlo-credit',
        gtmCategory: 'midway:geral',
        gtmAction: 'clique:menu-e-sub-menu',
        gtmLabel: 'Mastercard',
      },
      {
        title: 'O Aplicativo',
        action: () => {
          history.push('/cartoes#midway-app');
        },
        href: '#elm-midway-app',
        gtmCategory: 'midway:geral',
        gtmAction: 'clique:menu-e-sub-menu',
        gtmLabel: 'O Aplicativo',
      },
      {
        title: 'Parcerias',
        action: () => {
          history.push('/parceiros-cartao-rchlo');
        },
        href: '#elm-parceiros-cartao-rchlo',
        gtmCategory: 'midway:geral',
        gtmAction: 'clique:menu-e-sub-menu',
        gtmLabel: 'Parcerias',
      },
    ],
    gtmCategory: 'midway:geral',
    gtmAction: 'clique:menu-e-sub-menu',
    gtmLabel: 'Cartões',
  },
  {
    title: 'Produtos Financeiros',
    action: () => history.push('/produtos-financeiros'),
    href: '#elm-produtos-financeiros',
    tabIndex: 4,
    subTitles: [
      {
        title: 'Nossos Produtos',
        action: () => {
          history.push('/produtos-financeiros');
        },
        href: '#elm-produtos-financeiros',
        onlyMobile: true,
        gtmCategory: 'midway:geral',
        gtmAction: 'clique:menu-e-sub-menu',
        gtmLabel: 'Nossos Produtos',
      },
      {
        title: 'Empréstimo Pessoal',
        action: () => {
          history.push('/emprestimos');
        },
        href: '#elm-emprestimos',
        gtmCategory: 'midway:geral',
        gtmAction: 'clique:menu-e-sub-menu',
        gtmLabel: 'Empréstimo Pessoal',
      },
      {
        title: 'Antecipação Saque Aniversário FGTS',
        action: () => {
          history.push('/antecipacao-saque-fgts');
        },
        href: '#elm-antecipacao-saque-fgts',
        gtmCategory: 'midway:geral',
        gtmAction: 'clique:menu-e-sub-menu',
        gtmLabel: 'Antecipação Saque Aniversário FGTS',
      },
      {
        title: 'Seguros',
        action: () => {
          history.push('/seguros');
        },
        href: '#elm-seguros',
        gtmCategory: 'midway:geral',
        gtmAction: 'clique:menu-e-sub-menu',
        gtmLabel: 'Seguros',
      },
      {
        title: 'Assistências',
        action: () => {
          history.push('/assistencias');
        },
        href: '#elm-assistencias',
        gtmCategory: 'midway:geral',
        gtmAction: 'clique:menu-e-sub-menu',
        gtmLabel: 'Assistências',
      },
      {
        title: 'Serviços para saúde',
        action: () => {
          history.push('/saude');
        },
        href: '#elm-saude',
        gtmCategory: 'midway:geral',
        gtmAction: 'clique:menu-e-sub-menu',
        gtmLabel: 'Serviços para saúde',
      },
      {
        title: 'Plano Odontológico',
        action: () => {
          history.push('/plano-odontologico');
        },
        href: '#elm-plano-odontologico',
        gtmCategory: 'midway:geral',
        gtmAction: 'clique:menu-e-sub-menu',
        gtmLabel: 'Plano Odontológico',
      },
    ],
    gtmCategory: 'midway:geral',
    gtmAction: 'clique:menu-e-sub-menu',
    gtmLabel: 'Produtos Financeiros',
  },
  {
    title: 'Atendimento',
    action: () => history.push('/atendimento'),
    href: '#elm-atendimento',
    tabIndex: 5,
    subTitles: [
      {
        // title: 'Central de\n atendimento',
        title: 'Central',
        action: () => {
          history.push('/atendimento');
        },
        href: '#elm-atendimento',
        gtmCategory: 'midway:geral',
        gtmAction: 'clique:menu-e-sub-menu',
        gtmLabel: 'Central',
      },
      {
        title: 'Negocie sua dívida',
        action: () => {
          history.push(
            '/campanha-quitacao?utm_source=MiAjuda&utm_medium=Header&utm_campaign=quitacao-de-divida&utm_term=Header&utm_content=midwayheader'
          );
        },
        href: '#elm-campanha-quitacao',
        gtmCategory: 'midway:geral',
        gtmAction: 'clique:menu-e-sub-menu',
        gtmLabel: 'Negocie sua dívida',
      },
    ],
    gtmCategory: 'midway:geral',
    gtmAction: 'clique:menu-e-sub-menu',
    gtmLabel: 'Atendimento',
  },
  {
    title: 'Faça parte',
    action: () => history.push('/faca-parte'),
    href: '#elm-faca-parte',
    tabIndex: 6,
    subTitles: [
      {
        title: 'Trabalhe com a gente',
        action: () => {
          history.push('/faca-parte#work-with-us');
        },
        href: '#elm-work-with-us',
        gtmCategory: 'midway:geral',
        gtmAction: 'clique:menu-e-sub-menu',
        gtmLabel: 'Trabalhe com a gente',
      },
      {
        title: 'Cadastro de Startups',
        action: () => {
          history.push('/faca-parte#startup-partner');
        },
        href: '#elm-startup-partner',
        gtmCategory: 'midway:geral',
        gtmAction: 'clique:menu-e-sub-menu',
        gtmLabel: 'Cadastro de Startups',
      },
    ],
    gtmCategory: 'midway:geral',
    gtmAction: 'clique:menu-e-sub-menu',
    gtmLabel: 'Faça parte',
  },
];

export const cardsHome = [
  {
    id: 1,
    circle: {
      color: '#6EC592',
      icon: ['mid', 'shield'],
      iconSize: '2x',
    },
    btnAriaLabel: 'Conheça nossos seguros',
    title: 'Seguros',
    text: 'Pode confiar. Nós cuidamos bem do que é seu.',
    btnTitle: 'Conheça',
    action: () => {
      history.push('/seguros');
    },
  },
  {
    id: 2,
    circle: {
      color: '#F17EB1',
      icon: ['mid', 'tools'],
      iconSize: '2x',
    },
    btnAriaLabel: 'Conheça nossas assistências',
    title: 'Assistências',
    text: 'Estamos com você em todos os momentos, até nos imprevistos.',
    btnTitle: 'Conheça',
    action: () => {
      history.push('/assistencias');
    },
  },
  {
    id: 3,
    circle: {
      color: '#FFD25E',
      icon: ['mid', 'personal-loans'],
      iconSize: '2x',
    },
    btnAriaLabel: 'Conheça nossas formas de empréstimos',
    title: 'Empréstimos',
    text: 'Precisa de dinheiro? A gente te ajuda!',
    btnTitle: 'Conheça',
    action: () => {
      history.push('/emprestimos');
    },
  },
  {
    id: 4,
    circle: {
      color: '#FF5E5E',
      icon: ['mid', 'heart'],
      iconSize: '4x',
    },
    btnAriaLabel: 'Conheça nossos serviços para saúde',
    title: 'Serviços para Saúde',
    text: 'Estamos com você na saúde e na doença.',
    btnTitle: 'Conheça',
    action: () => {
      history.push('/saude');
    },
  },
  {
    id: 5,
    circle: {
      color: '#6ec592',
      icon: ['mid', 'health'],
      iconSize: '2x',
    },
    btnAriaLabel: 'Conheça nossos planos odontológicos',
    title: 'Plano Odontológico',
    text: 'Muito mais saúde para o seu sorriso.',
    btnTitle: 'Conheça',
    action: () => {
      history.push('/plano-odontologico');
    },
  },
  {
    id: 6,
    circle: {
      color: '#009EE3',
      icon: ['fa', 'smile'],
      iconSize: '2x',
    },
    btnAriaLabel: 'Negocie suas dívidas',
    title: 'Negocie sua Dívida',
    text: 'Renegocie suas dívidas e planeje sua vida financeira.',
    btnTitle: 'Conheça',
    action: () => {
      history.push('/campanha-quitacao');
    },
  },
];

export const cardsCredit = [
  {
    id: 1,
    icon: ['mid', 'credit-card'],
    title: 'Compras mais seguras',
    text: 'Seu cartão vem com chip e senha de 4 digitos, enviada para o seu endereço pelos correios. Se quiser mudar a sua senha, é possível, mas nunca divida a sua senha com ninguem.',
    btnTitle: '',
    photo: UserBannerBandeira1,
  },
  {
    id: 2,
    icon: ['mid', 'credit-card'],
    title: 'Bem-vindo ao mundo digital',
    text: 'O aplicativo Cartão Rchlo tem funções exclusivas e está sempre atualizado. Você consulta faturas, limite, melhor data para compras e até simular empréstimos. Baixe gratuitamente! Saiba mais aqui.',
    btnTitle: '',
    photo: UserBannerBandeira2,
  },
  {
    id: 3,
    icon: ['mid', 'credit-card'],
    title: 'Flexível, fácil e aceito em todo lugar',
    text: 'Seu limite é único e não compromete outros cartões. Você pode parcelar suas compras em até 5x sem juros ou começar a pagar em até 120 dias. Também é possível parcelar em até 8x, sujeito à juros.',
    btnTitle: '',
    photo: UserBannerBandeira4,
  },
  {
    id: 4,
    icon: ['mid', 'credit-card'],
    title: 'Anuidade? Que tal a menor do mercado?',
    text: 'Garantimos a menor anuidade do mercado, seja em nossas lojas ou mesmo se quiser usar em outros lugares. R$ 151,80 parcelado em 12x de R$12,65',
    btnTitle: '',
    photo: UserBannerBandeira5,
  },
  {
    id: 5,
    icon: ['mid', 'credit-card'],
    title: 'Saque emergencial',
    text: 'Você pode fazer saques emergenciais em qualquer caixa eletrônico de bancos conveniados à rede Visa ou Mastercard.',
    btnTitle: '',
    photo: UserBannerBandeira6,
  },
  {
    id: 6,
    icon: ['mid', 'credit-card'],
    title: 'Cartão Adicional',
    text: 'Você pode compartilhar os nossos benefícios com até 5 pessoas de sua confiança. Só é preciso que elas tenham no mínimo 14 anos, RG e CPF.',
    btnTitle: '',
    photo: UserBannerBandeira7,
  },
];

export const cardsFinancialProductsDetail = [
  {
    id: 1,
    icon: ['mid', 'credit-card'],
    title: 'Assistências 24 horas por dia, 7 dias por semana.',
    text: 'Em qualquer momento que você precisar, é só acionar a Assistência contratada e em pouco tempo, estaremos com você.',
    btnTitle: '',
  },
  {
    id: 2,
    icon: ['mid', 'credit-card'],
    title: 'Proteção com parcelas que cabem no seu bolso.',
    text: 'Tenha amparo em todos os momentos, pagando pouco.',
    btnTitle: '',
  },
  {
    id: 3,
    icon: ['mid', 'credit-card'],
    title: 'Concorra a prêmios em dinheiro.',
    text: 'São prêmios de R$ 20 mil R$ 50 mil e R$ 100 mil reais todos os meses, sorteador pela Loteria Federal.',
    btnTitle: '',
  },
  {
    id: 4,
    icon: ['mid', 'credit-card'],
    title: 'Sorrindo atoa.',
    text: 'Um plano pensado pra caber na sua rotina e orçamento, com sorteio de prêmios pra fazer você sorrir mais ainda.',
    btnTitle: '',
  },
];

export const cardsComment = [
  {
    id: 1,
    title: 'Daniela Moura da silva',
    text:
      'Amei esse cartão da Riachuelo, o melhor que eu tenho é ele 👏👏👏. ' +
      'Amei também o aplicativo dele vcs tão de parabéns',
    date: '27 de abril de 2020',
  },
  {
    id: 1,
    title: 'Maria Lima',
    text:
      'Olá! O aplicativo é ótimo, ❤️❤️ assim que a fatura fecha, ' +
      'já consigo imprimir e fazer o pagamento, tanto da fatura ' +
      'como de boleto de empréstimo️. ' +
      'Vcs tão de parabéns',
    date: '26 de maio de 2020',
  },
];

export const starupsCategories = [
  {
    categorie: 'Comunicação e moda',
    subCategories: [
      {
        title: 'Contact center',
      },
      {
        title: 'Design',
      },
      {
        title: 'Indústria têxtil',
      },
      {
        title: 'Marketing',
      },
      {
        title: 'Moda',
      },
      {
        title: 'Visual merchandising',
      },
    ],
  },
  {
    categorie: 'Financeiro',
    subCategories: [
      {
        title: 'Cartão de crédito',
      },
      {
        title: 'Cartão private label',
      },
      {
        title: 'Contabilidade',
      },
      {
        title: 'Controladoria',
      },
      {
        title: 'Crédito pessoal',
      },
      {
        title: 'Empréstimo PJ',
      },
      {
        title: 'Empréstimo consignado público e privado',
      },
      {
        title: 'Financiamento de motos e veículos',
      },
      {
        title: 'Prevenção a fraude',
      },
      {
        title: 'Seguros e assistências',
      },
    ],
  },
  {
    categorie: 'Operações de varejo',
    subCategories: [
      {
        title: 'Comercial',
      },
      {
        title: 'Logística',
      },
      {
        title: 'Lojas físicas',
      },
      {
        title: 'Omnistore',
      },
      {
        title: 'Prevenção de perdas',
      },
      {
        title: 'Shopping center',
      },
    ],
  },
  {
    categorie: 'Digital',
    subCategories: [
      {
        title: 'E-commerce',
      },
      {
        title: 'Inteligência artificial',
      },
      {
        title: 'Machine learning',
      },
      {
        title: 'Marketplace',
      },
    ],
  },
  {
    categorie: 'Outros',
    subCategories: [
      {
        title: 'Arquitetura',
      },
      {
        title: 'Backoffice',
      },
      {
        title: 'Engenharia',
      },
      {
        title: 'Jurídico',
      },
      {
        title: 'RH',
      },
      {
        title: 'Segurança da informação',
      },
    ],
  },
];

export const tableInsurance = [
  {
    id: 1,
    value: 'Até R$ 1.000',
    plan1: 'R$ 19,99',
    plan2: 'R$ 15,99',
  },
  {
    id: 2,
    value: 'De R$ 1.000,01 a R$1.500',
    plan1: 'R$ 34,99',
    plan2: 'R$ 23,99',
  },
  {
    id: 3,
    value: 'De R$ 1.500,01 a R$2.000',
    plan1: 'R$ 49,99',
    plan2: 'R$ 32,99',
  },
  {
    id: 4,
    value: 'De R$ 2.000,01 a R$3.000',
    plan1: 'R$ 64,99',
    plan2: 'R$ 45,99',
  },
  {
    id: 5,
    value: 'De R$ 3.000,01 a R$4.000',
    plan1: 'R$ 84,99',
    plan2: 'R$ 64,99',
  },
  {
    id: 6,
    value: 'De R$ 4.000,01 a R$5.000',
    plan1: 'R$ 105,99',
    plan2: 'R$ 82,99',
  },
];

export const cardsLending = [
  {
    id: 1,
    icon: ['mid', 'payment'],
    title: 'Até R$ 6.000,00* na hora!',
    text: 'Se você precisa de dinheiro rápido pra complementar sua renda, solicite o saque fácil nas lojas Riachuelo e saia da loja com o dinheiro na mão. É rápido e muito simples!',
    btnTitle: '',
  },
  {
    id: 2,
    icon: ['mid', 'calendar'],
    title: 'Prazo de verdade.',
    text: 'Além de facilitar o saque do dinheiro, também caprichamos no prazo, pra dar mais tempo pra você se organizar. Por isso, tem até 60 dias pra começar a pagar as parcelas.',
    btnTitle: '',
  },
  {
    id: 3,
    icon: ['mid', 'personal-loans'],
    title: 'Parcelas que cabem no seu bolso',
    text: 'Pra que você consiga pagar tudo sem perder a tranquilidade, você pode parcelar em até 21x fixas. Assim você organiza suas finanças e consegue conciliar suas despesas mensais.',
    btnTitle: '',
  },
];

export const cardsProductsLending = [
  {
    id: 1,
    icon: circleEmprestimos,
    title: 'Condições feitas para você',
    text: 'Limite de até R$ 10.000,00 em até 90 dias para começar a pagar.',
  },

  {
    id: 2,
    icon: circleGarantia,
    title: 'Não precisa de garantia',
    text: 'A sua tranquilidade é da nossa conta. Não pedimos bens, como carros e imóveis de garantia.',
  },

  {
    id: 3,
    icon: circleTaxas,
    title: 'Taxas de juros fixa',
    text: 'Justo e transparente. Com taxas fixas você não vai ter uma surpresa na hora de pagar a parcela.',
  },
];

export const cardsAssistance = [
  {
    id: 1,
    icon: ['mid', 'calendar'],
    title: `Assistências 24 horas\npor dia, 7 dias por semana.`,
    text: `Em qualquer momento que você\nprecisar, é só acionar a Assistência\ncontratada e em pouco tempo,\nestaremos com você.`,
    btnTitle: '',
  },
  {
    id: 2,
    icon: ['mid', 'personal-loans'],
    title: `Proteção com parcelas\nque cabem no seu bolso.`,
    text: `Tenha amparo em todos os\nmomentos, pagando pouco.`,
    btnTitle: '',
  },

  {
    id: 4,
    icon: ['mid', 'smile'],
    title: 'Sorrindo atoa.',
    text: `Um plano pensado pra caber na\nsua rotina e orçamento.`,
    btnTitle: '',
  },
];

export const cardsHealth = [
  {
    id: 1,
    icon: ['mid', 'calendar-date'],
    size: '4x',
    text: `Serviço gratuito de\nagendamento de consultas\ne exames.`,
    btnTitle: '',
  },
  {
    id: 2,
    icon: ['mid', 'stethoscope'],
    size: '4x',
    text: `Rede de Atendimento de\nQualidade`,
    btnTitle: '',
  },
  {
    id: 3,
    icon: ['mid', 'medicine'],
    size: '4x',
    text: `Desconto de 20% em\nmedicamentos nas\nfarmácias credenciadas.`,
    btnTitle: '',
  },
  {
    id: 4,
    icon: ['mid', 'heart'],
    size: '4x',
    text: `Sem carência para\nutilização.`,
    btnTitle: '',
  },
  {
    id: 5,
    icon: ['mid', 'baby'],
    size: '4x',
    text: `Sem restrições para\ndoenças preexistentes,\nidade e gravidez. Todos\npodem ter.`,
    btnTitle: '',
  },
  {
    id: 6,
    icon: ['mid', 'calendar-plus'],
    size: '4x',
    text: `Disponível para outros\ntratamentos como de\nFisioterapia, RPG,\nAcupuntura, Fonoaudiologia,\nNutricionista e Psicólogos.`,
    btnTitle: '',
  },
  {
    id: 7,
    icon: ['mid', 'money'],
    size: '4x',
    text: `Preços muito baixos em\nconsultas médicas e\nexames.`,
    btnTitle: '',
  },
  {
    id: 8,
    icon: ['mid', 'stethoscope'],
    size: '4x',
    text: `Mais de 3000 tipos de\nexames laboratoriais e\nde imagem por preços\nmuito baixos.`,
    btnTitle: '',
  },
  {
    id: 9,
    icon: ['mid', 'family'],
    size: '4x',
    text: `Titular e dependentes\npodem usar o mesmo\ncartão sem custos\nadicionais (Cartão familiar).`,
    btnTitle: '',
  },
];

export const cardsOdontological = [
  {
    id: 1,
    icon: ['mid', 'smartphone'],
    size: '2x',
    text: `Acesso a informações do \n plano direto pelo APP e \n Portal do beneficiário.`,
    btnTitle: '',
  },
  {
    id: 2,
    icon: ['mid', 'stethoscope'],
    size: '4x',
    text: `Consulta Online`,
    btnTitle: '',
  },

  {
    id: 4,
    icon: ['mid', 'money'],
    size: '4x',
    text: `Desconto de 5% até 75% \n em farmácias parceiras.`,
    btnTitle: '',
  },
  {
    id: 5,
    icon: ['mid', 'home'],
    size: '2x',
    text: `Rede credenciada em \n todo país.`,
    btnTitle: '',
  },
  {
    id: 6,
    icon: ['mid', 'smile'],
    size: '3x',
    text: `Atendimento via chatbots \n e chat online.`,
    btnTitle: '',
  },
];

export const cardsProductsAssistance = [
  {
    id: 1,
    circle: {
      color: '#F17EB1',
      icon: ['mid', 'car'],
    },
    btnAriaLabel: 'Saiba mais sobre a Assistência 24h Automóvel',
    title: 'Assistência 24h Automóvel',
    text: 'Seu carro quebrou na estrada, precisa de serviço de guincho ou motorista seguro pra dirigir o carro por você depois de uma festa? A Assistências 24h Automóvel faz isso e muito mais.',
    btnTitle: 'Saiba mais',
    action: () => {
      history.push('/assistencia-automovel');
    },
  },
  {
    id: 2,
    circle: {
      color: '#F17EB1',
      icon: ['mid', 'motorcycle'],
    },
    btnAriaLabel: 'Saiba mais sobre a Assistência 24h Moto',
    title: 'Assistência 24h Moto.',
    text: 'Precisou de guincho, taxi ou ficou parado na estrada?O amparo da Assistência 24h Moto é tão rápido quanto uma moto e atende você 24h por dia, 7 dias por semana.',
    btnTitle: 'Saiba mais',
    action: () => {
      history.push('/assistencia-moto');
    },
  },
  {
    id: 3,
    circle: {
      color: '#F17EB1',
      icon: ['mid', 'home'],
    },
    btnAriaLabel: 'Saiba mais sobre a Assistências 24h Residencial ',
    title: 'Assistências 24h Residencial .',
    text: 'Com a correria do dia a dia, não paramos pra pensar que em algum momento precisaremos de ajuda, descubra como a Assistências 24h Residencial pode te ajudar.',
    btnTitle: 'Saiba mais',
    action: () => {
      history.push('/assistencia-residencial');
    },
  },
];

export const dueDateCreditCard = [
  {
    id: 1,
    dayOfMonth: '03',
  },
  {
    id: 2,
    dayOfMonth: '05',
  },
  {
    id: 3,
    dayOfMonth: '08',
  },
  {
    id: 4,
    dayOfMonth: '10',
  },
  {
    id: 5,
    dayOfMonth: '13',
  },
  {
    id: 6,
    dayOfMonth: '15',
  },
  {
    id: 7,
    dayOfMonth: '18',
  },
  {
    id: 8,
    dayOfMonth: '20',
  },
  {
    id: 9,
    dayOfMonth: '23',
  },
  {
    id: 10,
    dayOfMonth: '25',
  },
  {
    id: 11,
    dayOfMonth: '27',
  },
  {
    id: 12,
    dayOfMonth: '30',
  },
];

export const bestDayCreditCard = [
  {
    id: 1,
    dayOfMonth: '23',
  },
  {
    id: 2,
    dayOfMonth: '25',
  },
  {
    id: 3,
    dayOfMonth: '28',
  },
  {
    id: 4,
    dayOfMonth: '30',
  },
  {
    id: 5,
    dayOfMonth: '02',
  },
  {
    id: 6,
    dayOfMonth: '04',
  },
  {
    id: 7,
    dayOfMonth: '07',
  },
  {
    id: 8,
    dayOfMonth: '09',
  },
  {
    id: 9,
    dayOfMonth: '12',
  },
  {
    id: 10,
    dayOfMonth: '14',
  },
  {
    id: 11,
    dayOfMonth: '16',
  },
  {
    id: 12,
    dayOfMonth: '19',
  },
];

export const tableCardPL = [
  {
    title:
      '*Tabela 1 – Padronização dos Serviços Prioritários – Pessoa Natural (Resolução 3.919/2010 do Banco Central do Brasil)',
    summary:
      'Padronização dos Serviços Prioritários – Pessoa Natural (Resolução 3.919/2010 do Banco Central do Brasil)',
    obs: 'Obs.: demais serviços apresentados na tabela 1 da resolução do Banco Central não foram mencionadas nesta tabela pois não são aplicáveis ao cartão riachuelo Private Label. Os valores das tarifas são estabelecidos pela administradora do cartão.',
    table: [
      {
        th: [
          'Lista de Serviços',
          `Canais de Entrega/\nFormas de Entrega`,
          'Sigla no Extrato',
          `Unidade\nde Cobrança`,
          'Fator Gerador da\nCobrança',
          'Valor da Tarifa',
        ],
        td: [
          [
            '1.1 Confecção de cadastro para início de relacionamento',
            '-',
            'CADASTRO',
            '-',
            'Realização de pesquisa em serviços de proteção ao crédito, base de dados e informações cadastrais, e tratamento de dados e informações necessários ao início de relacionamento decorrente da abertura de conta de depósitos à vista ou de poupança ou contratação de operação de crédito ou de arrendamento mercantil, não podendo ser cobrada cumulativamente.',
            'ISENTO',
          ],
          [
            '5.1 Anuidade – cartão básico',
            'Nacional',
            'ANUIDADE Nacional',
            'Por ano',
            'Disponibilização de rede de estabelecimentos filiados, instalada no País, para pagamentos de bens e serviços, cobrada no máximo uma vez a cada doze meses, admitido o parcelamento da cobrança.',
            'ISENTO',
          ],
          [
            '5.2 Fornecimento de 2ª via de cartão com função crédito',
            '-',
            '2ª via – CARTÃO CRÉDITO',
            'Por evento',
            'Confecção e emissão de novo cartão com função crédito, restrito a casos de pedidos de reposição formulados pelo detentor do cartão, decorrente de perda, roubo, furto, danificação e outros motivos não imputáveis à instituição emitente.',
            'R$ 8,00',
          ],
        ],
      },
    ],
  },
  {
    title: 'Tabela 2 - TAXAS – CARTÃO DE CRÉDITO RIACHUELO',
    summary: 'TAXAS – CARTÃO DE CRÉDITO RIACHUELO',
    table: [
      {
        th: ['Tipo de taxa', 'Descrição', 'Taxa Mês (Até)', 'Taxa Ano'],
        td: [
          [
            'Por atraso',
            'Juros Remuneratórios por atraso',
            '13,9% a.m.',
            '376,74% a.a.',
          ],
          ['Por atraso', 'Juros de Mora por atraso', '1,0% a.m.', '-'],
          [
            'Por refinanciamento',
            `Encargos Financeiros por ${'\n'}refinanciamento de Saldo`,
            '13,90% a.m',
            '376,74% a.a',
          ],
          [
            'Por parcelamento',
            `Encargo máximo de ${'\n'}parcelamento da fatura`,
            '13,90% a.m',
            '376,74% a.a',
          ],
        ],
        obs: `${'\n'}Obs.: Atraso - Multa de 2% sobre o valor devido a partir do 1º dia de atraso.${'\n'}Refinanciamento: Multa de 2% sobre o valor devido a partir do 1º dia de atraso.${'\n'}As taxas apresentadas relativas ao produto cartão de crédito, já se referem à CET.`,
      },
    ],
  },
  {
    title: 'Tabela 3 - FORMAS DE PAGAMENTO – CARTÕES RIACHUELO',
    summary: 'FORMAS DE PAGAMENTO – CARTÕES RIACHUELO',
    table: [
      {
        th: ['Opções de Parcelamento', 'Taxa Mês (Até)', 'Taxa Ano'],
        td: [
          [
            'Lojas Riachuelo: 0 + 1 sem juros\n\nAté 40 dias pra pagar sem juros e sem entrada,\ncondicionado à data de compra\ne à data de vencimento da fatura',
            `-`,
            '-',
          ],
          ['Lojas Riachuelo: 0 + 5 sem juros', `-`, '-'],
          [
            'Lojas Riachuelo: 0 + 10 sem juros\n\nCondição de pagamento exclusiva para\ncompras com os Cartões Riachuelo para o\ndepartamento Casa RCHLO\n(Nas compras acima de R$ 250,00),\nPerfumaria (Nas compras acima de R$ 150,00),\nRelógios e Celulares',
            `-`,
            '-',
          ],
          [
            'Lojas Riachuelo: 0 + 10 sem juros\n\nCondição de pagamento exclusiva para\ncompras realizadas no site\nda Riachuelo - <a href="https://riachuelo.com.br" target="_blank" style="color:#0071EB !important">www.riachuelo.com.br</a>',
            `-`,
            '-',
          ],
          [
            'Lojas Riachuelo: 0 + 8 com juros',
            `7,90% a.m. (+IOF)`,
            `149,03% a.a. (+IOF)`,
          ],
          [
            'Lojas Riachuelo: 12x e 13x com juros\n\nNo departamento de eletrônicos',
            `1,49% a.m. (+IOF)`,
            `19,42% a.a. (+IOF)`,
          ],
          [
            'Em demais estabelecimentos até 12x com juros',
            `6,90% a.m. (+IOF)`,
            `122,7% a.a. (+IOF)`,
          ],
          [
            'Lojas Riachuelo: 14x com juros\n\nNo departamento de eletrônicos',
            `2,20% a.m. (+IOF)`,
            `29,84% a.a. (+IOF)`,
          ],
          [
            'Lojas Riachuelo: 15x com juros\n\nNo departamento de eletrônicos',
            `2,90% a.m. (+IOF)`,
            `40,92% a.a. (+IOF)`,
          ],
          [
            'Lojas Riachuelo: 16x, 17x e 18x com juros\n\nNo departamento de eletrônicos',
            `3,40% a.m. (+IOF)`,
            `49,36% a.a. (+IOF)`,
          ],
        ],
      },
    ],
  },
];

export const tableCreditCard = [
  {
    title:
      '*Tabela 1 – Padronização dos Serviços Prioritários – Pessoa Natural (Resolução 3.919/2010 do Banco Central do Brasil)',
    summary:
      'Padronização dos Serviços Prioritários – Pessoa Natural (Resolução 3.919/2010 do Banco Central do Brasil)',
    obs: 'Obs.: demais serviços apresentados na tabela 1 da resolução do Banco Central não foram mencionadas nesta tabela pois não são aplicáveis ao cartão riachuelo Mastercard. Os valores das tarifas são estabelecidos pela administradora do cartão.',
    table: [
      {
        th: [
          'Lista de Serviços',
          `Canais de Entrega/\nFormas de Entrega`,
          'Sigla no Extrato',
          `Unidade\nde Cobrança`,
          'Fator Gerador\nda Cobrança',
          'Valor da Tarifa',
        ],
        td: [
          [
            '1.1 Confecção de cadastro para início de relacionamento',
            '-',
            'CADASTRO',
            '-',
            'Realização de pesquisa em serviços de proteção ao crédito, base de dados e informações cadastrais, e tratamento de dados e informações necessários ao início de relacionamento decorrente da abertura de conta de depósitos à vista ou de poupança ou contratação de operação de crédito ou de arrendamento mercantil, não podendo ser cobrada cumulativamente.',
            'ISENTO',
          ],
          [
            '5.1 Anuidade – cartão básico',
            'Nacional',
            'ANUIDADE Nacional',
            'Por ano',
            'Disponibilização de rede de estabelecimentos filiados, instalada no País, para pagamentos de bens e serviços, cobrada no máximo uma vez a cada doze meses, admitido o parcelamento da cobrança.',
            'R$118,80',
          ],
          [
            '5.1 Anuidade – cartão básico (adicional)',
            'Nacional',
            'ANUIDADE Adic. Nacional',
            'Por ano',
            'Disponibilização de rede de estabelecimentos filiados, instalada no País, para pagamentos de bens e serviços, cobrada no máximo uma vez a cada doze meses, admitido o parcelamento da cobrança.',
            'R$118,80',
          ],
          [
            '5.2 Fornecimento de 2ª via de cartão com função crédito',
            '-',
            '2ª via – CARTÃO CRÉDITO',
            'Por evento',
            'Confecção e emissão de novo cartão com função crédito, restrito a casos de pedidos de reposição formulados pelo detentor do cartão, decorrente de perda, roubo, furto, danificação e outros motivos não imputáveis à instituição emitente.',
            'R$ 8,00',
          ],
          [
            '5.3 Utilização de canais de atendimento para retirada em espécie.',
            'No País',
            'RETIRADA – País',
            'Por retirada',
            'Disponibilização e utilização pelo cliente de canais de atendimento disponíveis no País para retirada em espécie na função crédito.',
            'ISENTO',
          ],
        ],
      },
    ],
  },
  {
    title: 'Tabela 2 - TAXAS – CARTÃO DE CRÉDITO RIACHUELO MASTERCARD E VISA',
    summary: 'Taxas de cartão de crédito Riachuelo Mastercard e Visa',
    table: [
      {
        th: ['Tipo de taxa', 'Descrição', 'Taxa Mês (Até)', 'Taxa Ano'],
        td: [
          [
            'Por atraso',
            'Juros Remuneratórios por atraso',
            '13,9% a.m.',
            '376,74% a.a.',
          ],
          ['Por atraso', 'Juros de Mora por atraso', '1,0% a.m.', '-'],
          [
            'Por refinanciamento',
            `Encargos Financeiros por ${'\n'}refinanciamento de Saldo`,
            '13,90% a.m',
            '376,74% a.a',
          ],
          [
            'Por parcelamento',
            `Encargo máximo de ${'\n'}parcelamento da fatura`,
            '13,90% a.m',
            '376,74% a.a',
          ],
        ],
        obs: `${'\n'}Obs.: Atraso - Multa de 2% sobre o valor devido a partir do 1º dia de atraso.${'\n'}Refinanciamento: Multa de 2% sobre o valor devido a partir do 1º dia de atraso.${'\n'}As taxas apresentadas relativas ao produto cartão de crédito, já se referem à CET.`,
      },
    ],
  },
  {
    title: 'Tabela 3 - FORMAS DE PAGAMENTO – CARTÕES RIACHUELO',
    summary: 'Formas de pagamento cartões Riachuelo',
    table: [
      {
        th: [
          'Tipo de parcelamento',
          'Opções de Parcelamento',
          'Taxa Mês (Até)',
          'Taxa Ano',
        ],
        td: [
          [
            'Opção de parcelamento',
            '0+1 sem juros',
            `Até 40 dias para pagar sem juros${'\n'} e sem entrada, condicionado à data${'\n'} de compra e à data de vencimento${'\n'} da fatura.`,
            ' ',
          ],
          ['Opção de parcelamento', '0+5 prestações sem juros', '-', '-'],
          [
            'Opção de parcelamento',
            '0+8 prestações com juros',
            '6,90% a.m. (+IOF)',
            '122,7% a.a. (+IOF)',
          ],
          [
            'Parcelamento especial',
            '0+10 prestações sem juros',
            `Condição de pagamento exclusiva${'\n'} para compras com os Cartões${'\n'} Riachuelo para${'\n'} o departamento Moda Casa${'\n'} (Nas compras acima de R$ 250,00),${'\n'} relógios e celulares.`,
            '',
          ],
          [
            'Parcelamento especial',
            '12x e 13x com juros',
            '0,99% a.m.',
            '12,55% a.a.',
          ],
          [
            'Parcelamento especial',
            '14x com juros',
            '1,70% a.m.',
            '22,42% a.a.',
          ],
          [
            'Parcelamento especial',
            '15x com juros',
            '2,40% a.m.',
            '32,92% a.a.',
          ],
          [
            'Parcelamento especial',
            '16x, 17x e 18x com juros',
            '2,90% a.m.',
            '40,92% a.a.',
          ],
        ],
      },
    ],
  },
];

export const tableHealthEssentialPlan = [
  {
    id: 1,
    service: 'Consultas',
    lack: 'Não há',
  },
  {
    id: 2,
    service: 'Exames',
    lack: 'Não há',
  },
  {
    id: 3,
    service: 'Pacote Cirúrgico Hospital Oswaldo Cruz',
    lack: 'Não há',
  },
  {
    id: 4,
    service: 'Nutricionista Online',
    lack: 'Não há',
  },
  {
    id: 5,
    service: 'Descontos em Farmácias',
    lack: 'Não há',
  },
];

export const tableOdontologicalPlanTopazioG2 = [
  {
    id: 1,
    service: 'Urgências e emergências',
    lack: '24 horas',
  },
  {
    id: 2,
    service: 'Consultas',
    lack: '90 dias',
  },
  {
    id: 3,
    service: 'Limpeza',
    lack: '90 dias',
  },
  {
    id: 4,
    service: 'Restauração',
    lack: '90 dias',
  },
  {
    id: 5,
    service: 'Tratamento de Canal',
    lack: '90 dias',
  },
  {
    id: 5,
    service: 'Tratamento de crianças',
    lack: '90 dias',
  },
  {
    id: 6,
    service: 'Cirurgias',
    lack: '90 dias',
  },
  {
    id: 7,
    service: 'Raio X',
    lack: '90 dias',
  },
  {
    id: 8,
    service: 'Tratamento de gengiva',
    lack: '90 dias',
  },
];

export const tableOdontologicalPlanDiamondG2 = [
  {
    id: 1,
    service: 'Urgências e emergências',
    lack: '24 horas',
  },
  {
    id: 2,
    service: 'Consultas',
    lack: '90 dias',
  },
  {
    id: 3,
    service: 'Limpeza',
    lack: '90 dias',
  },
  {
    id: 4,
    service: 'Restauração',
    lack: '90 dias',
  },
  {
    id: 5,
    service: 'Tratamento de Canal',
    lack: '90 dias',
  },
  {
    id: 5,
    service: 'Tratamento de crianças',
    lack: '90 dias',
  },
  {
    id: 6,
    service: 'Cirurgias',
    lack: '90 dias',
  },
  {
    id: 7,
    service: 'Raio X',
    lack: '90 dias',
  },
  {
    id: 8,
    service: 'Tratamento de gengiva',
    lack: '90 dias',
  },
  {
    id: 9,
    service: 'Documentação ortodôntica',
    lack: '180 dias',
  },
];

export const tableHealthTelPlan = [
  {
    id: 1,
    service: 'Consultas',
    lack: 'Não há',
  },
  {
    id: 2,
    service: 'Exames',
    lack: 'Não há',
  },
  {
    id: 3,
    service: 'Pacote Cirúrgico Hospital Oswaldo Cruz',
    lack: 'Não há',
  },
  {
    id: 4,
    service: 'Nutricionista Online',
    lack: 'Não há',
  },
  {
    id: 5,
    service: 'Descontos em Farmácias',
    lack: 'Não há',
  },
  {
    id: 5,
    service: 'Telemedicina Familiar',
    lack: 'Sim',
  },
];

export const digitalAccountServicesAndFaresTable1 = {
  header: [
    'Lista de serviços',
    'Canal de entrega / Forma de entrega',
    'Valor da tarifa',
  ],
  data: [
    {
      category: 'Cadastro',
      services: [
        {
          description: 'Confecção de cadastro para início do relacionamento',
          options: [
            {
              deliveryMethods: ['Não incluso'],
              fares: ['ZERO'],
            },
          ],
        },
      ],
    },
    {
      category: 'Saque',
      services: [
        {
          description: (
            <>Saque - cartão com função débito (conta de pagamento pré paga)</>
          ),
          options: [
            {
              deliveryMethods: [
                'Terminal de auto atendimento',
                'Correspondente no País - Lojas Riachuelo',
              ],
              fares: [
                'R$ 6,90 por saque',
                '2 saques grátis no mês, a partir do terceiro R$ 4,00',
              ],
            },
          ],
        },
      ],
    },
    {
      category: 'Consulta',
      services: [
        {
          description: <>Extrato - últimos 30 dias</>,
          options: [
            {
              deliveryMethods: [
                'Presencial ou pessoal (central de atendimento)',
                'Digital (aplicativo)',
                'Terminal de autoatendimento',
              ],
              fares: ['ZERO', 'ZERO', 'R$3,50 por extrato'],
            },
          ],
        },
        {
          description: 'Extrato - demais períodos',
          options: [
            {
              deliveryMethods: [
                'Presencial ou pessoal (central de atendimento)',
                'Digital (aplicativo)',
              ],
              fares: ['ZERO', 'ZERO'],
            },
          ],
        },
        {
          description: 'Consulta Saldo',
          options: [
            {
              deliveryMethods: ['Terminal de auto atendimento'],
              fares: ['Tarifa R$ 3,50'],
            },
          ],
        },
      ],
    },
    {
      category: 'Transferência de Recurso',
      services: [
        {
          description: 'Transferência por meio de TED',
          options: [
            {
              deliveryMethods: [
                'Presencial ou pessoal (central de atendimento)',
                'Digital (aplicativo)',
              ],
              fares: ['ZERO', 'ZERO'],
            },
          ],
        },
        {
          description: 'Transferência agendada por meio de TED',
          options: [
            {
              deliveryMethods: [
                'Presencial ou pessoal (central de atendimento)',
                'Digital (aplicativo)',
              ],
              fares: ['ZERO', 'ZERO'],
            },
          ],
        },
        {
          description: 'Transferência entre contas na própria instituição',
          options: [
            {
              deliveryMethods: [
                'Presencial ou pessoal (central de atendimento)',
                'Digital (aplicativo)',
              ],
              fares: ['ZERO', 'ZERO'],
            },
          ],
        },
      ],
    },
    {
      category: 'Cartão de crédito básico',
      services: [
        {
          description: 'Anuidade cartão básico - internacional',
          options: [
            {
              deliveryMethods: ['Fatura do cartão'],
              fares: ['ZERO'],
            },
          ],
        },
        {
          description: 'Fornecimento de 2ª via de cartão com função débito',
          options: [
            {
              deliveryMethods: ['Extrato'],
              fares: ['R$9,90'],
            },
          ],
        },
        {
          description: 'Fornecimento de 2ª via de cartão com função crédito',
          options: [
            {
              deliveryMethods: ['Fatura do cartão'],
              fares: ['R$9,90'],
            },
          ],
        },
        {
          description:
            'Utilização de canais de atendimento para retirada em espécie',
          options: [
            {
              deliveryMethods: ['No pais', 'No exterior'],
              fares: ['R$9,90 + Encargos', 'R$ 14,90 + Encargos + IOF'],
            },
          ],
        },
        {
          description: (
            <>
              Pagamento de contas utilizando a função crédito
              <span>(Indisponível)</span>
            </>
          ),
          options: [
            {
              deliveryMethods: ['N/A'],
              fares: ['N/A'],
            },
          ],
        },
        {
          description: 'Avaliação emergencial de crédito',
          options: [
            {
              deliveryMethods: ['Fatura do cartão'],
              fares: ['R$17,90'],
            },
          ],
        },
      ],
    },
    {
      category: 'Depósito',
      services: [
        {
          description: 'Depósito por boleto',
          options: [
            {
              deliveryMethods: ['Por emissão a partir do 11º boleto'],
              fares: ['R$ 1,50'],
            },
          ],
        },
      ],
    },
  ],
};

export const digitalAccountServicesAndFaresTable2 = {
  header: ['Lista de serviços', 'Sigla do Extrato', 'Fato Gerador'],
  data: [
    {
      category: 'Cadastro',
      services: [
        {
          description: 'Confecção de cadastro para início do relacionamento',
          options: [
            {
              statementAcronym: ['Cadastro'],
              generatorFact: [
                'Realização de pesquisa em serviços de proteção ao crédito, base de dados e informações cadastrais, e tratamento de dados e informações necessários ao início de relacionamento decorrente da abertura de conta de depósitos à vista ou de poupança ou contratação de operação de crédito ou de arrendamento mercantil, não podendo ser cobrada cumulativamente.',
              ],
            },
          ],
        },
      ],
    },
    {
      category: 'Saque',
      services: [
        {
          description: (
            <>Saque - cartão com função débito (conta de pagamento pré paga) </>
          ),
          options: [
            {
              statementAcronym: ['Saque Banco 24h'],
              generatorFact: [
                'Saque em terminal de autoatendimento além do número de saques permitidos gratuitamente por mês. Nas contas de depósitos cujos contratos prevejam utilizar exclusivamente meios eletrônicos (“contas eletrônicas”) não pode ser cobrada tarifa para este canal de entrega.',
              ],
            },
            {
              statementAcronym: [<>Saque - Lojas RCHLO</>],
              generatorFact: [
                'Saque em empresa que atua como correspondente no País de instituição financeira',
              ],
            },
          ],
        },
      ],
    },
    {
      category: 'Depósito',
      services: [
        {
          description: <>Depósito</>,
          options: [
            {
              statementAcronym: [
                'Via boleto',
                <>Correspondente do País – Lojas RCHLO</>,
              ],
              generatorFact: [
                'Depósito com registro de informações necessárias à identificação, a qualquer tempo, da operação e/ou do depositante, por este solicitado',
              ],
            },
          ],
        },
      ],
    },
    {
      category: 'Consulta',
      services: [
        {
          description: <>Extrato - últimos 30 dias</>,
          options: [
            {
              statementAcronym: ['Consulta de Extrato CCR'],
              generatorFact: [
                'Fornecimento de extrato com a movimentação dos últimos trinta dias em guichê de caixa ou por outras formas de atendimento pessoal, tais como atendimento telefônico realizado por atendente, além do número permitido gratuitamente por mês. Nas “contas eletrônicas” não há gratuidade para este canal de entrega.',
              ],
            },
            {
              statementAcronym: ['Extrato Banco 24 Horas'],
              generatorFact: [
                'Fornecimento de extrato com a movimentação dos últimos trinta dias em terminal de autoatendimento ou por outras formas de atendimento eletrônico automatizado, sem intervenção humana, além do número permitido gratuitamente por mês. Nas “contas eletrônicas” não pode ser cobrada tarifa para este canal de entrega.',
              ],
            },
          ],
        },
        {
          description: 'Extrato - demais período',
          options: [
            {
              statementAcronym: ['Consulta de Extrato CCR'],
              generatorFact: [
                'Fornecimento de extrato com a movimentação de um período em guichê de caixa ou por outras formas de atendimento pessoal, tal como atendimento telefônico realizado por atendente.',
              ],
            },
            {
              statementAcronym: ['Consulta de Extrato APP'],
              generatorFact: [
                'Fornecimento de extrato com a movimentação de um período em terminal de autoatendimento ou por outras formas de atendimento eletrônico automatizado, sem intervenção humana. Nas “contas eletrônicas” não pode ser cobrada tarifa para este canal de entrega.',
              ],
            },
          ],
        },
      ],
    },
    {
      category: 'Transferência de Recurso',
      services: [
        {
          description: 'Transferência por meio de TED',
          options: [
            {
              statementAcronym: [
                'Presencial ou pessoal (central de atendimento) TED',
              ],
              generatorFact: [
                'Realização de transferência de recursos por meio de Transferência Eletrônica Disponível (TED) em guichê de caixa ou mediante outras formas de atendimento pessoal, incluindo o atendimento telefônico realizado por atendente.',
              ],
            },
            {
              statementAcronym: [
                'Terminal de autoatendimento e outros meios eletrônicos TED',
              ],
              generatorFact: [
                'Realização de transferência de recursos por meio de TED em terminal de autoatendimento ou mediante outras formas de atendimento eletrônico automatizado, sem intervenção humana. Nas “contas eletrônicas” não pode ser cobrada tarifa para este canal de entrega.',
              ],
            },
          ],
        },
        {
          description: 'Transferência agendada por meio de TED',
          options: [
            {
              statementAcronym: [
                'Presencial ou pessoal (central de atendimento) TED',
              ],
              generatorFact: [
                'Realização de transferência agendada de recursos por meio de TED em guichê de caixa ou mediante outras formas de atendimento pessoal, tais como atendimento telefônico realizado por atendente.',
              ],
            },
            {
              statementAcronym: [
                'Terminal de autoatendimento e outros meios eletrônicos TED',
              ],
              generatorFact: [
                'Realização de transferência agendada de recursos por meio de TED em terminal de autoatendimento ou mediante outras formas de atendimento eletrônico automatizado, sem intervenção humana. Nas “contas eletrônicas” não pode ser cobrada tarifa para este canal de entrega.',
              ],
            },
          ],
        },
        {
          description: 'Transferência entre contas na própria instituição',
          options: [
            {
              statementAcronym: ['Transferência'],
              generatorFact: [
                'Realização de transferência de recursos entre contas na própria instituição em guichê de caixa ou mediante outras formas de atendimento pessoal, tais como atendimento telefônico realizado por atendente, além do número permitido gratuitamente por mês. Nas “contas eletrônicas” não há gratuidade para este canal de entrega.',
              ],
            },
            {
              statementAcronym: ['Transferência'],
              generatorFact: [
                'Realização de transferência de recursos entre contas na própria instituição em terminal de autoatendimento ou mediante outras formas de atendimento eletrônico automatizado, sem intervenção humana, inclusive internet, além do número permitido gratuitamente por mês. Nas “contas eletrônicas” não pode ser cobrada tarifa para este canal de entrega.',
              ],
            },
          ],
        },
      ],
    },
    {
      category: 'Cartão de crédito básico',
      services: [
        {
          description: 'Anuidade cartão básico - internacional',
          options: [
            {
              statementAcronym: ['Tarifa de Anuidade'],
              generatorFact: [
                'Disponibilização de rede de estabelecimentos afiliados, instalada no País e no exterior, para pagamentos de bens e serviços, cobrada no máximo uma vez a cada doze meses, admitido o parcelamento da cobrança.',
              ],
            },
          ],
        },
        {
          description: 'Fornecimento de 2ª via de cartão com função débito',
          options: [
            {
              statementAcronym: ['2ª via do cartão de débito'],
              generatorFact: [
                'Confecção e emissão de novo cartão com função débito, restrito a casos de pedidos de reposição formulados pelo detentor da conta, decorrente de perda, roubo, furto, danificação e outros motivos não imputáveis à instituição emitente',
              ],
            },
          ],
        },
        {
          description: 'Fornecimento de 2ª via de cartão com função crédito',
          options: [
            {
              statementAcronym: ['2ª via do cartão de crédito'],
              generatorFact: [
                'Confecção e emissão de novo cartão com função crédito, restrito a casos de pedidos de reposição formulados pelo detentor do cartão, decorrente de perda, roubo, furto, danificação e outros motivos não imputáveis à instituição emitente',
              ],
            },
          ],
        },
        {
          description:
            'Utilização de canais de atendimento para retirada em espécie',
          options: [
            {
              statementAcronym: ['Retirada-País'],
              generatorFact: [
                'Disponibilização e utilização pelo cliente de canais de atendimento disponíveis no País para retirada em espécie na função crédito.',
              ],
            },
            {
              statementAcronym: ['Retirada-Exterior'],
              generatorFact: [
                'Disponibilização e utilização pelo cliente de canais de atendimento disponíveis no exterior para retirada em espécie na função crédito ou débito.',
              ],
            },
          ],
        },
        {
          description: (
            <>
              Pagamento de Contas utilizando a função crédito
              <span>(indisponível)</span>
            </>
          ),
          options: [
            {
              statementAcronym: ['N/A'],
              generatorFact: [
                'Realização de procedimentos operacionais para o pagamento de contas (água, luz, telefone, gás, tributos, boletos de cobrança, etc.), utilizando a função crédito do cartão.',
              ],
            },
          ],
        },
        {
          description: 'Avaliação emergencial de crédito',
          options: [
            {
              statementAcronym: ['AVAL. EMERG. CRÉDITO'],
              generatorFact: [
                'Avaliação de viabilidade e de riscos para a concessão de crédito em caráter emergencial, a pedido do cliente, por meio de atendimento pessoal, para realização de despesa acima do limite do cartão, cobrada no máximo uma vez nos últimos trinta dias.',
              ],
            },
          ],
        },
      ],
    },
  ],
};

export const cardsContractLoan = [
  {
    id: 1,
    icon: FotoContrato1,
    title: 'Aplicativo Midway',
    text: 'Abra sua conta sem custo e tenha condições especiais. ',
    paragraph: 'Contrate direto pelo app e receba o dinheiro na hora!',
    buttonApple: appleStoreIcon,
    buttonGoogle: googlePlayIcon,
  },
  {
    id: 2,
    icon: FotoContrato2,
    title: 'Lojas Riachuelo',
    text: `Vá até uma de nossas lojas físicas, contrate o empréstimo e saia com o dinheiro em mãos. `,
    paragraph: `Encontre a loja mais próxima de você `,
  },
  {
    id: 3,
    icon: FotoContrato3,
    title: 'Central de Atendimento',
    text: 'Contrate através da Central de Atendimento. Dinheiro na conta em até 1 dia útil.',
    subtitleOne: 'Capitais e Regiões Metropolitanas',
    telefoneOne: '3004 5417',
    subtitleTwo: 'Demais Localidades',
    telefoneTwo: '0800 727 4417',
  },
];

export const conditionsNegotiationCampaign = [
  {
    id: 1,
    icon: Condicoes,
    title: 'Condições especiais',
    text: 'Cartão Riachuelo e Empréstimo Pessoal com descontos de até 68% e em 24x',
  },

  {
    id: 2,
    icon: Incomodo,
    title: 'Sem incômodo',
    text: 'Tudo on-line, sem ligações  ou SMS',
  },

  {
    id: 3,
    icon: SobMedida,
    title: 'Sob medida',
    text: 'Faturas de acordo com o seu orçamento',
  },

  {
    id: 4,
    icon: Burocracia,
    title: 'Sem burocracia',
    text: 'O fim das filas e da espera',
  },
];

export const benefitsNegotiationCampaign = [
  {
    id: 1,
    icon: Step1,
    title: 'Descontos exclusivos',
    text: 'Aqui a negociação é direto com a gente, com as melhores taxas e condições de pagamento.',
  },

  {
    id: 2,
    icon: Step2,
    title: 'Parcele em até 24x',
    text: 'Você é quem escolhe o plano de parcelamento que cabe no seu bolso.',
  },

  {
    id: 3,
    icon: Step3,
    title: 'Totalmente on-line',
    text: 'Faça tudo por aqui, sem precisar gastar tempo com ligações telefônicas.',
  },
  {
    id: 4,
    icon: Step4,
    title: 'Seguro e sem burocracia',
    text: 'Todos os seus dados protegidos e seu nome limpo em até 5 dias úteis.',
  },
];

export const howItWorks = [
  {
    id: 1,
    icon: HIW1,
    title: 'Consulte suas pendências',
    text: 'Informe seus dados e clique em consultar pendências',
  },

  {
    id: 2,
    icon: HIW2,
    title: 'Escolha como quer pagar',
    text: 'Escolha entre as ofertas qual faz mais sentido para você',
  },

  {
    id: 3,
    icon: HIW3,
    title: 'Realize o pagamento',
    text: 'Pronto! Agora é só pagar o boleto e aproveitar',
  },
];

export const downloadApp = {
  googlePlayStoreUrl:
    'https://play.google.com/store/apps/details?id=br.com.midway',
  appleAppStoreUrl: 'https://apps.apple.com/br/app/midway-app/id1548732480',
};
