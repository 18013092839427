import React from 'react';
import PropTypes from 'prop-types';
import { Carousel as CarouselBootstrap } from 'react-bootstrap';

import * as S from './styles';

export default function Carousel({ images }) {
  return (
    <CarouselBootstrap
      indicators={false}
      pauseOnHover
      prevLabel="Slide anterior"
      nextLabel="Próximo slide"
      prevIcon={
        <S.NavigationContainer>
          <S.PrevIcon />
        </S.NavigationContainer>
      }
      nextIcon={
        <S.NavigationContainer>
          <S.NextIcon />
        </S.NavigationContainer>
      }
    >
      {images.map((elem, i) => (
        <CarouselBootstrap.Item key={`key-carousel${i}`}>
          <img
            className="d-block"
            src={elem.img || 'https://via.placeholder.com/1920x600.png'}
            alt={elem.alt}
          />
          <S.Caption colorText={elem.colorText}>
            <CarouselBootstrap.Caption>
              <p aria-label={elem.textAriaLabel}>{elem.textImg}</p>
            </CarouselBootstrap.Caption>
          </S.Caption>
        </CarouselBootstrap.Item>
      ))}
    </CarouselBootstrap>
  );
}

Carousel.propTypes = {
  images: PropTypes.any,
};

Carousel.defaultProps = {
  images: [],
};
