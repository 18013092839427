import styled from 'styled-components';

export const ContentPhone = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`;
export const Text = styled.p`
  font-size: 18px;
  font-weight: 700;
  padding-bottom: 4px;
  font-family: Montserrat;
  line-height: 22px;
  text-align: left;
  text-decoration: none;
`;
