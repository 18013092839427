import React, { useEffect } from 'react';
import { Button } from '@midway/web-ui-component';
import { first, isEmpty, keys, lowerCase } from 'lodash';
import { useSelector } from 'react-redux';
import { useNegotiation } from '~/context/negotiationV2';
import { tagMiAjudaButton } from '~/services/tagging';
import { gtagSelectContent, gtagViewContent } from '~/utils/TaggingGA4';
import ContainerFadeIn from '../../ContainerFadeIn';
import Help from '../../Help';
import {
  Container,
  ContainerContent,
  DescriptionText,
  Title,
  ContainerNegotiation,
  Text,
  TypeableLine,
  TitlePix,
  ContainerImportant,
} from './styles';
import ButtonCopy from '../../ButtonCopy';
import QRCode from '../../QRCode';

const GTAG_CONTENT_PAGE = 'pegue-aqui-a-segunda-via-do-boleto-do-seu-acordo';
const GTAG_FLOW = 'miajuda';

const tagGa4Button = (content_type, params) => {
  gtagSelectContent(GTAG_CONTENT_PAGE, GTAG_FLOW, content_type, params);
};

function Duplicate() {
  const {
    system,
    duplicateData,
    negotiationData,
    setHistoryPages,
    negotiable,
  } = useNegotiation();
  const { toogles } = useSelector(state => state.featuretoggle);
  const features = negotiable[system];
  const isTopaz = system === 'TOPAZ';
  const duplicate =
    (!isTopaz &&
      first(
        duplicateData?.filter(
          d => d.contract === negotiationData?.selectedContract?.contract
        )
      )) ||
    first(duplicateData);

  const hasProposals =
    !isTopaz &&
    keys(negotiationData?.eligibility)
      .filter(key => features.includes(key))
      .reduce((obj, key) => {
        obj[key] = negotiationData?.eligibility[key];
        return obj;
      }, {});

  const gtagParams = {
    sistema: lowerCase(system),
    proposta: negotiationData?.gaParamProposta,
    contrato: duplicate?.contract,
  };

  useEffect(() => {
    gtagViewContent(GTAG_CONTENT_PAGE, GTAG_FLOW, gtagParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderRedirectWhatsTopaz = () => (
    <ContainerContent>
      <Text>Precisa de ajuda?</Text>
      <span>
        Você pode acionar a nossa Central de Atendimento através dos números
        3004 5417 (capitais e regiões metropolitanas) e 0800 727 4417 (demais
        localidades)
      </span>
      <Button
        title="WhatsApp Midway"
        type="submit"
        onClick={() => {
          tagGa4Button('possui-acordo-whatsapp', gtagParams);
          tagMiAjudaButton('possui-acordo-whatsapp');
          window.open(
            'https://api.whatsapp.com/send/?phone=551130030950&text=Ol%C3%A1!%20Quero%20renegociar%20minha%20d%C3%ADvida%20de%20empr%C3%A9stimo',
            '_blank'
          );
        }}
      />
    </ContainerContent>
  );

  const renderImportantTopaz = () => (
    <ContainerImportant>
      <strong>Importante:</strong>
      <span>
        1. O prazo para compensação de boletos é de até três dias úteis
      </span>
      <span>
        2. Caso não seja possível pagar o boleto até o vencimento, ligue para 11
        3004-5417 ou 0800 727 4417
      </span>
      <span>
        3. Os boletos das demais parcelas do acordo serão enviados por e-mail
      </span>
    </ContainerImportant>
  );

  return (
    <ContainerFadeIn>
      <Container>
        {system === 'TOPAZ' &&
        !duplicate?.typeableLine &&
        (!toogles.isEnabledPaymentMethodTopaz || !duplicate?.typeableLine) ? (
          <>
            {system === 'TOPAZ' ? (
              <Text>O acordo nº {duplicate?.agreementNumber} está vigente</Text>
            ) : (
              <Text>Você já possui um acordo vigente</Text>
            )}

            <DescriptionText>
              Acordos realizados com opção de pagamento por débito em conta: o
              pagamento só será debitado se o valor total da entrada do seu
              acordo estiver disponível como saldo na sua conta. Por isso,
              recomendamos que você sempre transfira o valor da fatura para sua
              conta até às 23h59 do dia anterior ao débito automático.
            </DescriptionText>
            {renderRedirectWhatsTopaz()}
          </>
        ) : (
          <>
            {!isEmpty(duplicate?.pixCopyAndPaste) && (
              <>
                <TitlePix>Você já possui um acordo vigente</TitlePix>
                <ContainerContent>
                  <DescriptionText>
                    Você selecionou o pagamento via PIX, copie ou compartilhe
                    sua chave para finalizar o pagamento da sua negociação.
                  </DescriptionText>
                  <QRCode value={duplicate?.pixCopyAndPaste} />
                  <ButtonCopy
                    type="pix"
                    title="Copiar código Pix"
                    toCopy={duplicate?.pixCopyAndPaste}
                  />
                </ContainerContent>
              </>
            )}
            {isEmpty(duplicate?.pixCopyAndPaste) && (
              <>
                {system === 'TOPAZ' && (
                  <Text>
                    O acordo nº {duplicate?.agreementNumber} está vigente
                  </Text>
                )}
                <Title>Pegue aqui a segunda via do boleto do seu acordo</Title>
                <ContainerContent>
                  <TypeableLine>{duplicate?.formatTypeableLine}</TypeableLine>
                  <ButtonCopy
                    type="billet"
                    title="Copiar código do boleto"
                    toCopy={duplicate?.typeableLine}
                  />
                </ContainerContent>
              </>
            )}
            {system === 'TOPAZ' && (
              <>
                {renderImportantTopaz()}
                {renderRedirectWhatsTopaz()}
              </>
            )}

            {!isEmpty(hasProposals) && (
              <ContainerNegotiation>
                <Text>Deseja simular um novo acordo?</Text>
                <span>
                  Lembre-se que as condições negociadas anteriormente não são
                  garantidas em uma nova negociação
                </span>
                <Button
                  title="Simular novo acordo"
                  type="submit"
                  onClick={() => {
                    tagGa4Button('novo-acordo', gtagParams);
                    tagMiAjudaButton('novo-acordo');
                    setHistoryPages(prevHistory => [
                      ...prevHistory,
                      'eligibility',
                    ]);
                  }}
                />
              </ContainerNegotiation>
            )}
            {!isEmpty(hasProposals) && <Help />}
          </>
        )}
      </Container>
    </ContainerFadeIn>
  );
}

export default Duplicate;
