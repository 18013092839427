import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';

export default function DigitalAccountServicesTable({
  tableData,
  servicesObjectMap,
  columnsWidth,
}) {
  return (
    <div className="container table-responsive-xl">
      <table className="table table-borderless">
        <thead>
          <tr>
            <S.TableHeaderText scope="col-3" style={{ width: columnsWidth[0] }}>
              {tableData.header[0]}
            </S.TableHeaderText>
            <S.TableHeaderText scope="col-5" style={{ width: columnsWidth[1] }}>
              {tableData.header[1]}
            </S.TableHeaderText>
            <S.TableHeaderText scope="col-3" style={{ width: columnsWidth[2] }}>
              {tableData.header[2]}
            </S.TableHeaderText>
          </tr>
        </thead>

        <tbody>
          {tableData.data.map(item => {
            return (
              <>
                <S.Service>
                  <span>{item.category}</span>
                </S.Service>
                {item.services.map((s, index) => {
                  const { description, options } = s;

                  const rows = options.map((opt, optIndex) => {
                    const columnTwoArray =
                      opt[servicesObjectMap.columnTwoArray];
                    const columnThreeArray =
                      opt[servicesObjectMap.columnThreeArray];
                    return (
                      <tr
                        style={{
                          borderTop:
                            index === 0 && optIndex === 0
                              ? null
                              : '1px solid #ADADAD',
                        }}
                      >
                        {options.length > 1 && optIndex === 0 && (
                          <td className="align-middle" rowSpan={options.length}>
                            <S.ServiceDescription scope="row">
                              {description}
                            </S.ServiceDescription>
                          </td>
                        )}

                        {options.length === 1 && (
                          <td className="align-middle">
                            <S.ServiceDescription scope="row">
                              {description}
                            </S.ServiceDescription>
                          </td>
                        )}

                        <td
                          className="align-middle"
                          style={{
                            borderLeft: '1px solid #ADADAD',
                            borderRight: '1px solid #ADADAD',
                          }}
                        >
                          {columnTwoArray.map(dm => (
                            <S.TdCard>{dm}</S.TdCard>
                          ))}
                        </td>
                        <td>
                          {columnThreeArray.map(fr => (
                            <S.TdCard>{fr}</S.TdCard>
                          ))}
                        </td>
                      </tr>
                    );
                  });

                  return rows;
                })}
              </>
            );
          })}
          <S.Service />
        </tbody>
      </table>
    </div>
  );
}

DigitalAccountServicesTable.propTypes = {
  tableData: PropTypes.object.isRequired,
  servicesObjectMap: PropTypes.object.isRequired,
  columnsWidth: PropTypes.array,
};

DigitalAccountServicesTable.defaultProps = {
  columnsWidth: ['30%', '40%', '30%'],
};
