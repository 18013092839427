import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
`;

export const ContainerGlobal = styled.div`
  max-width: 1900px;
  width: 100%;
  margin: 0 auto;
`;

export const Main = styled.main``;

export const BannerContainer = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 40px;
`;

export const BannerDesktop = styled.img`
  width: 100%;
  object-fit: cover;
  cursor: pointer;

  @media (max-width: 800px) {
    display: none;
  }
`;

export const CentralizeContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 1000px) {
  }
`;

export const ContentCardLinkBlock = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 90%;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const ContentTop = styled.div`
  display: flex;
  align-items: start;
  justify-content: left;
  flex-direction: column;
  text-align: left;
  width: 100%;
  max-width: 1180px;
  @media (max-width: 1000px) {
  }
`;

export const TitleContent = styled.h1`
  font-size: 32px !important;
  font-family: Montserrat;
  font-weight: 500;
  margin-bottom: 16px;

  @media (max-width: 1000px) {
    font-size: 18px !important;
    font-weight: bold;
    padding-bottom: 16px;
  }
`;

export const TextContent = styled.p`
  font-size: 20px;
  font-family: Montserrat;
  font-weight: 500;

  @media (max-width: 1000px) {
    font-size: 16px;
  }
`;

export const ContentBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-top: 40px;
  width: 100%;
  max-width: 1180px;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const ContentAccordions = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-top: 80px;
  @media (max-width: 1000px) {
    width: 90%;
  }
`;

export const ContentAccordionsTop = styled.div`
  display: flex;
  align-items: start;
  justify-content: left;
  flex-direction: column;
  text-align: left;
  width: 90%;
  max-width: 1180px;
  @media (max-width: 1000px) {
    width: 100%;
  }
`;

export const ContentAccordionsBottom = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 40px;
  width: 90%;
  max-width: 1180px;
  @media (max-width: 1000px) {
    width: 100%;
    flex-direction: column-reverse;
  }
`;

export const ContentCardsBlock = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-top: 80px;
  max-width: 1440px;
  background-color: #f7f7f7;

  @media (max-width: 1000px) {
    height: 100%;
    margin-top: 64px;
  }
`;

export const CentralizeContentBlock = styled.div`
  width: 90%;
  max-width: 1180px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 1000px) {
  }
`;

export const ContentCardsBlockTop = styled.div`
  display: flex;
  align-items: start;
  justify-content: left;
  flex-direction: column;
  margin-top: 50px;
  width: 100%;
  margin-bottom: 40px;

  @media (max-width: 1000px) {
    width: 100%;
  }
`;

export const ContentCardsBlockBotton = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;
