import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  button {
    width: 90%;
  }
`;

export const ContainerContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 1px solid #cacecd;
  border-radius: 8px;
  padding: 10px;
  margin: 16px 0 32px 0;
  line-height: 25px;

  button {
    margin-top: 10px;
  }
`;

export const TypeableLine = styled.span`
  color: #606060;
  font-size: 20px;
`;

export const DescriptionText = styled.div`
  background: #f7f7f7;
  padding: 10px;
  border-radius: 8px;
  margin: 16px 0;
  font-size: 14px;
  text-align: center;
`;

export const ContainerNegotiation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  span {
    margin-bottom: 16px;
  }
`;

export const Title = styled.div`
  color: #00726d;
  font-weight: bold;
`;

export const TitlePix = styled.h4`
  font-weight: bold;
`;

export const Text = styled.span`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 16px;
`;

export const ContainerImportant = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  color: #606060;
  strong {
    font-size: 14px;
    margin: 16px 0 10px 0;
  }
  span {
    margin-bottom: 4px;
  }
`;
