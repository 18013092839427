import styled from 'styled-components';

export const Container = styled.button`
  cursor: pointer;
  border: none;
  background: transparent;
  margin-bottom: 24px;

  .arrow-back {
    box-sizing: border-box;
    color: #004d49;

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px;
    gap: 8px;

    width: 36px;
    height: 36px;
    background: #e6fffe;
    border-radius: 100%;
  }
`;
