import React, { useCallback, useEffect, useState } from 'react';
import Accordion from '~/components/Accordion';
import cmsService from '~/services/cms/pages';
import * as S from './styles';

import { mockData } from './data';

const InfoAccordion = () => {
  const [activeAccordion, setActiveAccordion] = useState(0);

  const handleButtonClick = accordionIndex => {
    setActiveAccordion(accordionIndex);
  };

  const [accordionData, setAccordionData] = useState([]);
  const [selectAccordion, setSelectAccordion] = useState([]);

  useEffect(() => {
    if (accordionData.length > 0) {
      setSelectAccordion(accordionData[activeAccordion].children);
    }
  }, [activeAccordion, accordionData]);

  const handleGetAccordionPortalService = useCallback(async () => {
    try {
      const response = await cmsService.getAccordionPortalService(
        process.env.REACT_APP_SITE_CMS_ENV
      );

      if (response) {
        setAccordionData(response);
        return;
      }
      setAccordionData(mockData);
    } catch (error) {
      console.log(error);
      setAccordionData(mockData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cmsService]);

  useEffect(() => {
    handleGetAccordionPortalService();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <S.AccordionLeft>
        {accordionData.map((item, index) => (
          <S.ButtonAccordion
            key={item.id}
            active={activeAccordion === index}
            onClick={() => handleButtonClick(index)}
          >
            {item.name}
          </S.ButtonAccordion>
        ))}
      </S.AccordionLeft>
      <S.AccordionRight>
        <S.AccordionContainer active>
          {selectAccordion.map((item, index) => (
            <Accordion key={index} title={item.name} content={item.answer} />
          ))}
        </S.AccordionContainer>
      </S.AccordionRight>
    </>
  );
};

export default InfoAccordion;
