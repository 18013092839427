import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@midway/web-ui-component';
import { IconAlertCircle } from '@tabler/icons-react';
import useIsMobile from '~/hooks/useIsMobile';
import { useNegotiation } from '~/context/negotiationV2';
import { tagMiAjudaButton } from '~/services/tagging';
import {
  MaskedInput,
  MASK_TYPES,
} from '~/components/Common/Inputs/MaskedInput';
import Accordion from '~/components/Accordion';
import { validarCPF, validarDataNascimento } from '~/utils/utils';
import { benefitsNegotiationCampaign, howItWorks } from '~/constants';
import { faqNegotiationCampaing } from '~/constants/faq';
import cartaoHero from '~/assets/img/NegotiationCampaign/Home/cartaoHero.png';
import feirao from '~/assets/img/NegotiationCampaign/Home/feirao/mulher.png';
import RCHLO from '~/assets/img/NegotiationCampaign/Home/RCHLO.png';
import device from '~/assets/img/NegotiationCampaign/Home/device.png';
import { gtagSelectContent, gtagViewContent } from '~/utils/TaggingGA4';
import ContactItem from '~/pages/NewNegotiationCampaign/components/ContactItem';
import ContactWP from '~/assets/img/NegotiationCampaign/Home/contactWP.png';
import ContactCentral from '~/assets/img/NegotiationCampaign/Home/contactCentral.png';
import ContactRCHLO from '~/assets/img/NegotiationCampaign/Home/contactRCHLO.png';
import {
  Container,
  HeroContainer,
  Decoration,
  ContainerBenefits,
  ContainerHIW,
  ContainerFaq,
  ContainerContact,
  TitleSection,
  TitleHero,
  MaskedInputLabel,
  SubtitleForm,
} from './styles';
import ContainerFadeIn from '../components/ContainerFadeIn';

const GTAG_CONTENT_PAGE = 'home-mi-ajuda';
const GTAG_FLOW = 'miajuda';
const tagGa4Button = (content_type, params) => {
  gtagSelectContent(GTAG_CONTENT_PAGE, GTAG_FLOW, content_type, params);
};

const Home = ({ fetchData }) => {
  const { toogles } = useSelector(state => state.featuretoggle);
  const { sendedTagView, setSendedTagView } = useNegotiation();
  const isMobile = useIsMobile();
  const isRef = useRef();
  const isInputBithDateRef = useRef();
  const isInputDocumentRef = useRef();

  const handleScrollToRef = (button, ga4ContentName) => {
    tagGa4Button(ga4ContentName);
    tagMiAjudaButton(button);
    isRef.current.scrollIntoView();
  };

  useEffect(() => {
    if (!sendedTagView) {
      setSendedTagView(true);
      gtagViewContent(GTAG_CONTENT_PAGE, GTAG_FLOW);
    }
  }, [sendedTagView, setSendedTagView]);

  const Hero = () => {
    const { loadingNegotiation, clientData } = useNegotiation();
    const [document, setDocument] = useState(clientData?.document);
    const [birthDate, setBirthDate] = useState(clientData?.birthDate);

    const handleConfirm = () => {
      tagGa4Button('consultar');
      tagMiAjudaButton('consultar-cta1');
      fetchData(document, birthDate);
    };
    const disableButton =
      !validarDataNascimento(birthDate) ||
      !validarCPF(document) ||
      loadingNegotiation;

    function useWindowSize() {
      const [size, setSize] = useState([0, 0]);
      useLayoutEffect(() => {
        function updateSize() {
          setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
      }, []);
      return size;
    }

    useLayoutEffect(() => {
      isInputDocumentRef.current.focus();
    }, []);

    const [width] = useWindowSize();

    const renderForm = () => (
      <div className="container-form">
        {toogles.isEnabledMiajudaBannerDesenrola ? (
          !isMobile && (
            <TitleHero color="#fff">
              Negocie seus débitos com até{' '}
              <Decoration>90% de desconto</Decoration>
            </TitleHero>
          )
        ) : (
          <TitleHero color="#fff">
            Negocie seus débitos com até{' '}
            <Decoration>90% de desconto</Decoration>
          </TitleHero>
        )}

        <div className="form">
          <h2>
            Aqui você pode negociar ou gerar a 2ª via da fatura do{' '}
            <strong>Cartão Riachuelo</strong> ou parcela do{' '}
            <strong>Empréstimo Pessoal</strong>
          </h2>

          <MaskedInput
            ref={isInputDocumentRef}
            id="input-cpf"
            maskType={MASK_TYPES.CPF}
            maxLength="14"
            pressOnKeyDown={() => isInputBithDateRef.current.focus()}
            value={document}
            setValue={setDocument}
            name="cpf"
            placeholder="Informe o seu CPF"
            aria-label="Informe o seu CPF"
          />
          <MaskedInputLabel
            show={document?.length >= 11 && !validarCPF(document)}
            htmlFor="input-cpf"
          >
            Insira um CPF válido.
          </MaskedInputLabel>

          <MaskedInput
            ref={isInputBithDateRef}
            id="input-birth-date"
            maskType={MASK_TYPES.BIRTHDATE}
            maxLength="10"
            pressOnKeyDown={() => !disableButton && handleConfirm()}
            value={birthDate}
            setValue={setBirthDate}
            name="data-nascimento"
            placeholder="Data de Nascimento"
            aria-label="Informe sua data de nascimento"
          />
          <MaskedInputLabel
            show={birthDate?.length >= 10 && !validarDataNascimento(birthDate)}
            htmlFor="input-birth-date"
            className="text-error"
          >
            Insira uma data válida.
          </MaskedInputLabel>

          <Button
            onClick={() => handleConfirm()}
            disabled={disableButton}
            loading={loadingNegotiation}
            type="submit"
            title="Consultar"
          />

          {/* <MaskedInputLabel show={!loading && !isEmpty(error)}>
              {error}
            </MaskedInputLabel> */}

          <div className="channel-rchlo">
            <span>um canal</span>
            <img src={RCHLO} className="riachuelo-image" alt="Riachuelo" />
          </div>
        </div>
      </div>
    );

    const renderRightSide = () => (
      <>
        {toogles.isEnabledBannerFeiraoMiAjuda ? (
          <div className="container-feirao">
            <img src={feirao} alt="Mulher segurando cartao riachuelo" />
            <div className="container-text">
              <h2 className="bold">
                O MELHOR MOMENTO PARA RENEGOCIAR SUA DÍVIDA É AGORA.
              </h2>
              <span>• Condições especiais</span>
              <span>• Parcele em até 24x</span>
              <span>• Descontos exclusivos</span>
            </div>
          </div>
        ) : (
          <div className="rightSide">
            <div className="container-message">
              <div className="alert-icon-message">
                <IconAlertCircle />
                Dica da Midway
              </div>
              <span>
                Estamos aqui para ajudá-lo a regularizar seus débitos e alcançar
                uma maior estabilidade financeira.
              </span>
            </div>
            <img src={cartaoHero} alt="Imagem de cartoes RCHLO" />
          </div>
        )}
      </>
    );

    return (
      <HeroContainer
        feirao={toogles.isEnabledBannerFeiraoMiAjuda}
        isMobile={isMobile}
        width={width}
      >
        {toogles.isEnabledBannerFeiraoMiAjuda && (
          <>
            <div className="elipse left" />
            <div className="elipse right" />
          </>
        )}
        <div className="container-content-hero">
          {renderForm()}
          {renderRightSide()}
        </div>
        {isMobile && (
          <SubtitleForm>
            Resolva seus débitos aqui: Cartões Riachuelo e Empréstimo Pessoal
          </SubtitleForm>
        )}
      </HeroContainer>
    );
  };

  const Benefits = () => (
    <ContainerBenefits isMobile={isMobile}>
      <TitleSection>
        Por que negociar suas dívidas com a Riachuelo?
      </TitleSection>
      <div className="container-list-benefits">
        {benefitsNegotiationCampaign.map(benefit => (
          <div key={benefit.id} className="benefit">
            <img src={benefit.icon} alt="Icone de benefícios" />
            <strong>{benefit.title}</strong>
            <span>{benefit.text}</span>
          </div>
        ))}
      </div>
      <Button
        onClick={() => handleScrollToRef('consultar-cta2', 'consultar-debitos')}
        type="submit"
        title="Consultar débitos"
      />
    </ContainerBenefits>
  );

  const HowItWorks = () => (
    <ContainerHIW isMobile={isMobile}>
      <div className="container-image">
        <img src={device} alt="celular com imagem da negociaçao" />
      </div>
      <div className="content">
        <TitleSection>Como funciona</TitleSection>
        {howItWorks.map(hiw => (
          <div key={hiw.id} className="hiw">
            <img src={hiw.icon} alt="Número do item" />
            <div>
              <span className="title">{hiw.title}</span>
              <span>{hiw.text}</span>
            </div>
          </div>
        ))}
        <Button
          onClick={() =>
            handleScrollToRef('consultar-cta3', 'conhecer-opcoes-de-acordo')
          }
          type="submit"
          title="Conhecer opções de acordo"
        />
      </div>
    </ContainerHIW>
  );

  const Faq = () => (
    <ContainerFaq isMobile={isMobile}>
      <TitleSection>Perguntas frequentes</TitleSection>
      {faqNegotiationCampaing.map(item => (
        <Accordion
          key={item.id}
          title={item.title}
          content={item.description}
          onToggle={active =>
            tagGa4Button(
              active ? `expand:${item.ga4TagName}` : `shrink:${item.ga4TagName}`
            )
          }
        />
      ))}
      <Button
        onClick={() =>
          handleScrollToRef('consultar-cta4', 'consultar-cpf-gratuitamente')
        }
        type="submit"
        title="Consultar CPF gratuitamente"
      />
    </ContainerFaq>
  );

  const Contact = () => (
    <ContainerContact isMobile={isMobile}>
      <TitleSection>Outros canais de negociação</TitleSection>
      <div className="content">
        <ContactItem icon={ContactWP} title="WhatsApp Riachuelo">
          <>
            Clique{' '}
            <a
              href="https://www.midway.com.br/campanha-quitacao/whatsapp?utm_source=MiAjuda&utm_medium=whatsapp&utm_campaign=quitacao-de-divida&utm_term=HMA&utm_content=whatshome"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                tagMiAjudaButton('whatsapp-home');
                tagGa4Button(
                  'clique-aqui-e-negocie-direto-pelo-nosso-whatsapp'
                );
              }}
            >
              aqui
            </a>{' '}
            e negocie direto pelo nosso WhatsApp.
          </>
        </ContactItem>
        <ContactItem icon={ContactCentral} title="Central de Negociação">
          <a
            href="tel:+3004-5417"
            onClick={() => {
              tagMiAjudaButton('telefone');
              tagGa4Button('3004-5417');
            }}
          >
            3004-5417
          </a>{' '}
          capitais e regiões metropolitanas{' '}
          <a
            href="tel:+0800-727-4417"
            onClick={() => {
              tagMiAjudaButton('telefone');
              tagGa4Button('0800-727-4417');
            }}
          >
            0800-727-4417
          </a>{' '}
          demais localidades
        </ContactItem>
        <ContactItem icon={ContactRCHLO} title="Lojas Riachuelo">
          Você também pode negociar presencialmente em qualquer loja Riachuelo.
        </ContactItem>
        <ContactItem icon={ContactCentral} title="Assessorias de cobrança">
          Clique{' '}
          <a
            target="_blank"
            href="https://blog.riachuelo.com.br/promocoes-e-regulamentos"
            onClick={() => {
              tagGa4Button('blog');
            }}
            rel="noreferrer"
          >
            aqui
          </a>{' '}
          e conheça nossas assessorias parceiras
        </ContactItem>
      </div>
    </ContainerContact>
  );

  return (
    <Container isMobile={isMobile} ref={isRef}>
      <ContainerFadeIn>
        <Hero />
        <Benefits />
        <HowItWorks />
        <Faq />
        <Contact />
      </ContainerFadeIn>
    </Container>
  );
};

export default Home;
