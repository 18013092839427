import React, { useCallback, useEffect, useState } from 'react';
import cmsService from '~/services/cms/pages';
import ExternalLink from '~/assets/img/service-portal/external-links.svg';
import * as S from './styles';

import Card from './components/Card';
import GridCards from './components/GridCards';
import InfoAccordion from './components/InfoAccordion';
import RenderBannerImage from './components/RenderBannerImage';

import { Banner } from './data';

const firstCard = [
  {
    key: 1,
    icon: ExternalLink,
    url: 'https://www.midway.com.br/consulta-boleto',
    text: 'Pagar fatura (linha digitável)',
  },
  {
    key: 2,
    icon: ExternalLink,
    url: 'https://www.midway.com.br/campanha-quitacao?utm_source=pagina_ajuda_rchlo&utm_medium=FAQ_rchlo&utm_campaign=quitacao-de-divida',
    text: 'Negociar dívidas',
  },
  {
    key: 3,
    icon: ExternalLink,
    url: 'https://www.midway.com.br/cartao-de-credito',
    text: 'Pedir Cartão RCHLO Mastercard',
  },
];

const secondCard = [
  {
    key: 1,
    icon: ExternalLink,
    url: 'https://riachuelo.com.br/cliente/esqueceu-senha',
    text: 'Redefinir senha',
  },
  {
    key: 2,
    icon: ExternalLink,
    url: 'https://www.riachuelo.com.br/cliente/criar-conta',
    text: 'Criar a minha conta',
  },
  {
    key: 3,
    icon: ExternalLink,
    url: 'https://www.riachuelo.com.br/cliente',
    text: 'Alterar dados cadastrais',
  },
  {
    key: 4,
    icon: ExternalLink,
    url: 'https://www.riachuelo.com.br/minha-conta/meus-pedidos',
    text: 'Pedidos',
  },
];

const thirdCard = [
  {
    key: 1,
    icon: ExternalLink,
    url: 'https://riachuelo.com.br/cliente/esqueceu-senha',
    text: 'Antecipar FGTS',
  },
  {
    key: 2,
    icon: ExternalLink,
    url: 'https://www.riachuelo.com.br/cliente/criar-conta',
    text: 'Parcerias exclusivas',
  },
  {
    key: 3,
    icon: ExternalLink,
    url: 'https://www.midway.com.br/produtos-financeiros',
    text: 'Produtos e serviços financeiros',
  },
];

const ServicePortal = () => {
  const [bannerData, setBannerData] = useState([]);
  const handleGetBannerData = useCallback(async () => {
    try {
      const response = await cmsService.getBannerPortalService(
        process.env.REACT_APP_SITE_CMS_ENV
      );

      if (response) {
        setBannerData(response);
        return;
      }
      setBannerData(Banner);
    } catch (error) {
      console.log(error);
      setBannerData(Banner);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cmsService]);

  useEffect(() => {
    handleGetBannerData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const banner = bannerData[0] || Banner;

  return (
    <S.Container>
      <S.ContainerGlobal>
        <S.Main>
          <S.BannerContainer>
            <RenderBannerImage {...banner} />
          </S.BannerContainer>
          <S.CentralizeContent>
            <S.ContentCardLinkBlock>
              <S.ContentTop>
                <S.TitleContent>Como podemos te ajudar?</S.TitleContent>
                <S.TextContent>
                  Você pode acessar as páginas a seguir e encontrar mais
                  informações sobre os produtos e serviços.
                </S.TextContent>
              </S.ContentTop>
              <S.ContentBottom>
                <Card title="Cartões e Empréstimos" texts={firstCard} />
                <Card title="Meu perfil Riachuelo" texts={secondCard} />
                <Card title="Benefícios" texts={thirdCard} />
              </S.ContentBottom>
            </S.ContentCardLinkBlock>
            <S.ContentAccordions>
              <S.ContentAccordionsTop>
                <S.TitleContent>Perguntas frequentes</S.TitleContent>
                <S.TextContent>
                  Consulte algumas perguntas que podem ajudar a solucionar sua
                  dúvida.
                </S.TextContent>
              </S.ContentAccordionsTop>
              <S.ContentAccordionsBottom>
                <InfoAccordion />
              </S.ContentAccordionsBottom>
            </S.ContentAccordions>
          </S.CentralizeContent>
          <S.CentralizeContent>
            <S.ContentCardsBlock>
              <S.CentralizeContentBlock>
                <S.ContentCardsBlockTop>
                  <S.TitleContent>Como nos encontrar</S.TitleContent>
                </S.ContentCardsBlockTop>
                <S.ContentCardsBlockBotton>
                  <GridCards />
                </S.ContentCardsBlockBotton>
              </S.CentralizeContentBlock>
            </S.ContentCardsBlock>
          </S.CentralizeContent>
        </S.Main>
      </S.ContainerGlobal>
    </S.Container>
  );
};

export default ServicePortal;
