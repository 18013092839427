import styled from 'styled-components';

export const HeaderWrapper = styled.div`
  margin-top: ${pros => (pros.hasMobile ? '116px' : '0')};
`;
export const HeaderImage = styled.div`
  img {
    object-fit: contain;
  }
`;
