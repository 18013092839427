import styled from 'styled-components';

export const Container = styled.main`
  background-color: #fff;
  padding: 0 !important;
  u {
    color: #0076ff;
    cursor: pointer;
  }
  .insurance-mobile {
    margin-top: 10px;
    @media (max-width: 992px) {
      margin-top: 32px;
    }
  }
  .after-table {
    margin-top: 60px;
    @media (max-width: 992px) {
      margin-top: 30px;
      margin-bottom: 20px;
    }
  }
`;
export const FooterCard = styled.div`
  background-color: #1b7071;
  padding: 35px 0;
  text-align: center;
  border-radius: 15px;
  span {
    color: white !important;
    line-height: 35px !important;
    font-size: 22px !important;
  }
  u {
    color: white !important;
    font-size: 22px !important;
  }
`;

export const BannerContainer = styled.div`
  background-color: ${props => (props.bgColor ? props.bgColor : '#E8E8E8')};
  position: relative;
  min-height: 400px;
  max-height: 400px;
  padding: 0 !important;
  @media (max-width: 767px) {
    min-height: 300px;
    max-height: 300px;
  }
  img {
    min-height: 400px;
    max-height: 400px;
    width: 100%;
    object-fit: cover;
    @media (max-width: 767px) {
      min-height: 300px;
      max-height: 300px;
    }
  }
  div.banner-title {
    position: absolute;
    white-space: pre-wrap;
    right: 10%;
    font-weight: bold;
    top: 100px;
    max-width: 500px;
    h1 {
      font-size: 30px !important;
    }
    @media (max-width: 767px) {
      display: none;
    }
  }
`;

export const Content = styled.div`
  h2 {
    color: #252525 !important;
    font-size: 34px !important;
    font-weight: bold !important;
    @media (max-width: 992px) {
      margin-top: 24px;
      font-size: 26px !important;
      font-weight: bold !important;
    }
  }
  p {
    color: #707070;
    font-size: 22px;
    padding-bottom: 60px;
    @media (max-width: 992px) {
      font-size: 18px;
      padding-bottom: 20px;
    }
  }
  span {
    color: #252525;
    font-size: 16px;
    line-height: 24px;
    @media (max-width: 992px) {
      font-size: 14px;
    }
  }
  b {
    font-weight: bold;
    font-size: 16px;
    color: #252525;
    @media (max-width: 992px) {
      font-size: 14px;
    }
  }
`;

export const Info = styled.div`
  padding-top: ${props => (props.top ? `${props.top}px` : '0px')};
  p {
    padding-bottom: 0;
    font-size: 16px;
    color: #252525;
    line-height: 24px;
    @media (max-width: 992px) {
      font-size: 14px;
    }
  }
`;

export const Insurance = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: ${props => (props.top ? props.top : '90px')};
  @media (max-width: 992px) {
    padding-top: 24px;
  }
  p {
    padding-bottom: 30px;
    @media (max-width: 992px) {
      padding-bottom: 15px;
    }
  }
  img {
    @media (max-width: 992px) {
      padding-top: 20px;
    }
  }
`;

export const InfoDetail = styled.div`
  h2 {
    font-size: 32px !important;
    color: #252525;
    margin-top: 65px;
  }
  p {
    font-size: 18px;
    font-weight: bold;
    color: #252525;
    margin-top: 22px;
    padding-bottom: 0;
  }
  span {
    font-size: 14px;
    font-weight: normal;
    color: #252525;
    padding-bottom: 0;
  }
`;

export const TextDetail = styled.div`
  margin-top: 22px;
  p {
    font-size: 16px;
    font-weight: normal;
    color: #252525;
    margin-top: 0;
    padding-bottom: 0;
  }
`;

export const IconCard = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: solid #6ec592;
  margin: 0 auto 0 auto;
  position: relative;
`;

export const ShowIcon = styled.div`
  margin: 0;
  display: table;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;

export const TableWrapper = styled.div`
  thead {
    background-color: #006464;
    align-items: center;
    th {
      color: #fff;
      font-size: 20px;
      @media (max-width: 992px) {
        font-size: 16px;
      }
      &:first-child {
        border-radius: 12px 0 0 0;
      }
      &:last-child {
        border-radius: 0 12px 0 0;
      }
    }
  }
  tbody {
    background-color: #efefef;
    font-size: 16px;
    tr {
      padding: 0 5px 0 5px;
      &:last-child {
        td {
          &:first-child {
            border-radius: 0 0 0 12px;
          }
          &:last-child {
            border-radius: 0 0 12px 0;
          }
        }
      }
    }
  }
`;

export const TdWithDelimitator = styled.td`
  border-right: 1px solid #006464;
  border-top: none;
  white-space: break-spaces;
`;

export const Paragraph = styled.p`
  white-space: pre-line;
`;

export const RulesTitle = styled.h4`
  @media (max-width: 767px) {
    text-align: center;
    font-size: 1rem !important;
    margin-bottom: 10px;
  }
`;
